<template>
  <select
    class="form-select"
    :value="modelValue"
    @input="update($event.target.value)"
  >
    <option value="weekly">Weekly</option>
    <option value="biweekly">Biweekly</option>
    <option value="monthly">Monthly</option>
    <option value="quarterly">Quarterly</option>
    <option value="yearly">Yearly</option>
  </select>
</template>

<script>
export default {
  props: {
    modelValue: Object
  },
  emits: ['change', 'update:modelValue'],
  setup(_, { emit }) {
    const update = (value) => {
      emit('update:modelValue', value)
      emit('change', value)
    }

    return {
      update
    }
  },
}
</script>