import Benchmark from "../models/Benchmark";

class BenchmarkFactory {
  get (key, data) {
    return new Benchmark({
      key: key,
      name: data.name,
      format: data.format,
      value: data.value === 'undefined' ? 'N/A' : data.value
    })
  }
}

export default new BenchmarkFactory()
