<template>
  <select
    class="form-select w-auto h-8 py-1"
    :value="serie"
    @change="$emit('update:serie', $event.target.value)"
  >
    <option value="return">Cumulative Return</option>
    <option value="drawdown">Drawdown</option>
    <option value="daily">Daily Return</option>
  </select>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['update:serie'],
  props: {
    serie: String,
  },
});
</script>
