<template>
  <form @submit="onPortfolioSubmit">
    <div class="mb-4">
      <div class="font-bold mb-2">
        Name
      </div>
      <Field
        name="name"
        :value="portfolioForm.name"
        label="Portfolio name"
        rules="required"
        v-slot="{ field, meta }"
      >
        <PortfolioInputSelector
          v-if="updatedOptions && portfolioForm.name.length"
          :options="updatedOptions"
          :class="{ 'has-error': meta.validated && !meta.valid }"
          class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
          :value="field.value"
          placeholder="Portfolio name"
          @change="change"
          @updateName="updateName"
        />
        <ErrorMessage name="name" />
      </Field>
    </div>
    <div class="mb-4">
      <div class="font-bold mb-2">
        Description
      </div>
      <Field
        name="description"
        label="Portfolio description"
        rules="required"
        v-slot="{ field, meta }"
      >
        <input
          type="text"
          :class="{ 'has-error': meta.validated && !meta.valid }"
          class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
          placeholder="Portfolio description"
          v-model="portfolioForm.description"
          :name="field.name"
          @blur="field.onBlur"
          @input="field.onInput"
          @change="field.onChange"
        />
        <ErrorMessage name="description" />
      </Field>
    </div>
    <div
      class="mb-4 flex items-start gap-2"
      v-for="(position, index) in portfolioForm.composition"
      :key="position.id"
    >
      <div class="flex-grow flex-shrink-0 w-40 sm:w-96">
        <div class="font-bold mb-2">
          Position
        </div>
        <Field
          :name="'position' + index"
          label="Position"
          rules="required"
          v-slot="{ field, meta }"
          v-model="position.ticker"
        >
          <TomSelect
            v-model="position.ticker"
            :name="field.name"
            :class="{ 'has-error': meta.validated && !meta.valid }"
            :options="{
              maxItems: 1,
              loadThrottle: 250,
              placeholder: 'Type to get a list of positions',
              valueField: 'ticker',
              labelField: 'description',
              searchField: ['description', 'ticker'],
              load: (str, callback) => {
                onSearchPosition(str)
                  .then((res) => {
                    res.map((elem) =>
                      PortfolioFormTransformer.parseTicker(elem)
                    );
                    callback(res);
                  })
                  .catch(() => callback());
              },
              shouldLoad: (str) => str.length > 0,
              render: {
                item: function(data, escape) {
                  return (
                    '<div>' +
                    '<strong>' +
                    PortfolioFormTransformer.parseGetTicker(data.ticker) +
                    '</strong> ' +
                    data.description +
                    '</div>'
                  );
                },
                option: function(data, escape) {
                  return (
                    '<div>' +
                    '<strong>' +
                    PortfolioFormTransformer.parseGetTicker(data.ticker) +
                    '</strong> ' +
                    data.description +
                    '</div>'
                  );
                },
              },
            }"
          >
            <option
              v-if="selectedOptions[index]"
              :value="selectedOptions[index].ticker"
            >
              {{ selectedOptions[index].description }}
            </option>
          </TomSelect>
          <ErrorMessage :name="'position' + index" />
        </Field>
      </div>
      <div class="flex-shrink">
        <div class="font-bold mb-2">
          Weight
        </div>
        <Field
          :name="'weight' + index"
          label="Weight"
          rules="required|min_value:0|max_value:100"
          v-slot="{ field, meta }"
        >
          <div class="input-group">
            <input
              type="text"
              :class="{ 'has-error': meta.validated && !meta.valid }"
              class="form-control py-2 px-1 border-gray-300 block sm:px-4"
              placeholder="weight"
              v-model="position.weight"
              :name="field.name"
              @blur="field.onBlur"
              @input="field.onInput"
              @change="field.onChange"
            />
            <div class="input-group-text">%</div>
          </div>
          <ErrorMessage :name="'weight' + index" />
        </Field>
      </div>
      <button
        type="button"
        class="btn btn-outline-dark mt-7 flex-shrink-0"
        :disabled="portfolioForm.composition.length < 2"
        @click="onDeletePosition(index)"
      >
        <Trash2Icon class="w-5 h-5 text-theme-24" />
      </button>
    </div>
    <div>
      <button
        type="button"
        class="btn btn-outline-dark mt-5"
        @click="onAddPosition()"
      >
        <PlusIcon class="w-5 h-5" /> Add Position
      </button>
    </div>
    <div class="mt-10 flex justify-end gap-4">
      <button
        type="button"
        @click="onPortfolioClose"
        class="btn btn-outline-dark"
      >
        Cancel
      </button>
      <button class="btn btn-primary">Save</button>
    </div>
  </form>
</template>

<script>
import { defineComponent, reactive, ref, unref } from 'vue';
import { useForm, Field, ErrorMessage, defineRule } from 'vee-validate';
import { useStore } from 'vuex';
import { required, min_value, max_value } from '@vee-validate/rules';
import {
  createId,
  getOwnerInData,
  mergeString,
  getNameInData,
} from '@/domain/services/stringHelper';
import PortfolioFormTransformer from '@/domain/services/PortfolioFormTransformer';
import PortfolioInputSelector from '@/components/portfolio-input-selector/Main.vue';
import {
  wasPortfolioPreviouslySelected,
  compositionHasDuplicates,
  checkForDuplicates,
  checkIfPortfolioIsFromStrategy,
  filterOutPortfolio
} from '@/domain/services/componentHelper';
import {
  notTotalWeightOneHundred,
  zeroWeightPositions,
} from '@/domain/services/weightHelper';

export default defineComponent({
  emits: ['modified', 'closed'],
  components: { Field, ErrorMessage, PortfolioInputSelector },
  props: {
    portfolio: Object,
    options: Array,
    strategyPortfolios: { type: Array, default: () => [] },
  },
  setup(props, { emit }) {
    const store = useStore();
    const positions = ref([]);
    const portfolioForm = reactive({
      name: '',
      description: '',
      composition: [],
    });
    const selectedOptions = ref([]);
    const formLoaded = ref(false);
    const CREATION = 'creation';
    const updatedOptions = ref([]);
    const owner = store.getters.getLoggedUser;
    const selectedPortfolio = ref(props.portfolio);

    const { handleSubmit, resetForm, setValues, setFieldError } = useForm();

    const setValuesInForm = () => {
      const values = {};
      values.name = portfolioForm.name;
      values.description = portfolioForm.description;
      portfolioForm.composition.forEach((elem, index) => {
        values['position' + index] = elem.ticker;
        values['weight' + index] = elem.weight;
      });
      setValues(values);
    };

    const onPortfolioClose = () => {
      emit('closed');
    };

    const onPortfolioSubmit = handleSubmit((_, actions) => {
      portfolioForm.name = getNameInData(portfolioForm.name);

      if (notTotalWeightOneHundred(portfolioForm.composition)) {
        actions.setFieldError('weight0', "The weights don't sum up 100%");
        return;
      }

      const zeroWeightValues = zeroWeightPositions(portfolioForm.composition);
      if (zeroWeightValues.length !== 0) {
        zeroWeightValues.forEach((position) => {
          actions.setFieldError(
            'weight' + position.index,
            'The weight can not be 0'
          );
        });
        return;
      }

      const portfolioOptions = filterOutPortfolio(props.strategyPortfolios, selectedPortfolio.value)
      if (
        checkIfPortfolioIsFromStrategy(portfolioOptions, portfolioForm.name, owner)
      ) {
        actions.setFieldError(
          'name',
          'This portfolio name is already in the strategy'
        );
        return;
      }

      if (compositionHasDuplicates(portfolioForm.composition)) {
        const duplicateIndexes = [];
        const portfolioChecklist = checkForDuplicates(
          portfolioForm.composition
        );

        portfolioChecklist.filter((portfolio, index) => {
          if (portfolio === false) {
            duplicateIndexes.push(index);
          }
        });

        duplicateIndexes.forEach((el) => {
          actions.setFieldError(
            'position' + el,
            'Not duplicated positions allowed'
          );
        });
        return;
      }

      emit(
        'modified',
        PortfolioFormTransformer.toPortfolio(portfolioForm, owner)
      );
    });

    const onAddPosition = () => {
      portfolioForm.composition.push({
        description: '',
        ticker: '',
        weight: null,
        id: createId(),
      });
      resetForm();
      setValuesInForm();
    };

    const onDeletePosition = (index) => {
      portfolioForm.composition.splice(index, 1);
      selectedOptions.value.splice(index, 1)
      resetForm();
      setValuesInForm();
    };

    const onSearchPosition = (search) => {
      return store
        .dispatch('searchAssets', search)
        .then((searchResult) => searchResult.getData);
    };

    defineRule('required', required);
    defineRule('min_value', min_value);
    defineRule('max_value', max_value);

    if (props.portfolio) {
      portfolioForm.name = unref(
        mergeString(props.portfolio.owner, props.portfolio.name)
      );

      portfolioForm.description = unref(props.portfolio.description);
      props.portfolio.getAssets.forEach((elem) => {
        const e = { ...elem, id: createId() };
        selectedOptions.value.push(PortfolioFormTransformer.parseTicker(e));
        portfolioForm.composition.push(e);
      });

      updatedOptions.value = [...props.strategyPortfolios, ...props.options];
      
      setValuesInForm();
    }

    const updatePortfolioInformation = (portfolio) => {
      portfolioForm.name = mergeString(portfolio.owner, portfolio.name);
      portfolioForm.description = portfolio.description;
      portfolio.assets.forEach((elem) => {
        const e = { ...elem, id: createId() };
        selectedOptions.value.push(PortfolioFormTransformer.parseTicker(e));
        portfolioForm.composition.push(e);
      });
      setValuesInForm();
      formLoaded.value = true;
    };

    const change = (value) => {
      setFieldError('name', null);

      portfolioForm.composition = [];
      selectedOptions.value = [];
      portfolioForm.name = getNameInData(value);

      const portfolioFromStrategy = checkIfPortfolioIsFromStrategy(
        props.strategyPortfolios,
        value,
        owner
      );

      if (
        typeof portfolioFromStrategy === 'undefined' ||
        !portfolioFromStrategy
      ) {
        const portfolioData = {
          name: getNameInData(value),
          owner: getOwnerInData(value),
        };
        store
          .dispatch('getPortfolio', portfolioData)
          .then((retrievedPortfolio) => {
            portfolioForm.name = mergeString(
              retrievedPortfolio.owner,
              retrievedPortfolio.name
            );
            portfolioForm.description = retrievedPortfolio.description;

            retrievedPortfolio.assets.forEach((elem) => {
              const e = { ...elem, id: createId() };
              selectedOptions.value.push(
                PortfolioFormTransformer.parseTicker(e)
              );
              portfolioForm.composition.push(e);
            });
            setValuesInForm();
            formLoaded.value = true;
          })
          .catch((error) => {
            console.log('error', error);
          });

        return;
      }

      updatePortfolioInformation(portfolioFromStrategy);
    };

    const updateName = (value) => {
      setFieldError('name', null);

      portfolioForm.name = value;
      if (!wasPortfolioPreviouslySelected(portfolioForm.composition)) {
        portfolioForm.composition = [];
        onAddPosition();
      }
    };

    return {
      portfolioForm,
      positions,
      selectedOptions,
      PortfolioFormTransformer,
      formLoaded,
      CREATION,
      updatedOptions,

      onPortfolioSubmit,
      onPortfolioClose,
      onAddPosition,
      onDeletePosition,
      onSearchPosition,
      change,
      updateName,
    };
  },
});
</script>
