export default class Metadata {
  constructor (data) {
    this.category = data.category
    this.description = data.description
    this.frequency = data.frequency
    this.issuer = data.issuer
    this.since = data.since
    this.ticker = data.ticker
  }

  get getDescription () {
    return this.description
  }

  get getIssuer () {
    return this.issuer
  }

  get getCategory () {
    return this.category
  }

  get getFrequency () {
    return this.frequency
  }

  get getSince () {
    return this.since
  }

  get getTicker () {
    return this.ticker
  }
}
