export default class TradingSearchItem {
  constructor (data) {
    this.description = data.description,
    this.isin = data.isin
  }

  get getDescription () {
    return this.description;
  }

  get getIsin () {
    return this.isin;
  }
}
