<template>
  <div class="flex gap-4">
    <div>
      <div class="text-black mb-1">Quantity</div>
      <input
        type="text"
        :class="{ 'has-error': vQuantity.meta.validated && !vQuantity.meta.valid }"
        class="form-control py-2 px-4 border-gray-300 block"
        placeholder="100,000"
        :value="modelValue.quantity"
        @blur="vQuantity.handleBlur"
        @input="update('quantity', $event.target.value)"
        autocomplete="off"
      />
      <ErrorMessage :name="id + 'Quantity'" />
    </div>
    <div>
      <div class="text-black mb-1">Frequency</div>
      <Field
        :name="id + 'frequency'"
        label="Frequency"
        v-slot="{ field, meta }"
      >
        <Frequency
          class="form-control"
          :class="{ 'has-error': meta.validated && !meta.valid }"
          :value="modelValue.frequency"
          @change="field.onChange"
          @input="updateFreq('frequency', $event.target.value)"
        />
        <ErrorMessage :name="id + 'frequency'" />
      </Field>
    </div>
  </div>
</template>

<script>
import { defineComponent, unref } from 'vue'
import { defineRule, ErrorMessage, Field, useField } from "vee-validate"
import { numeric } from '@vee-validate/rules'
import { required } from '@vee-validate/rules'
import { format as formatNumber } from "@/domain/services/numberHelper"
import Frequency from "@/components/frequency/Main"

export default defineComponent({
  components: { ErrorMessage, Field, Frequency },
  props: {
    modelValue: Object
  },
  setup(props, { emit }) {
    const id = unref(props.modelValue.getId)

    defineRule('required', required)
    defineRule('numeric', numeric)
    const vQuantity = useField(id + 'Quantity', 'required', {
      label: 'Quantity',
      initialValue: props.modelValue.getQuantity,
    })

    const update = (key, value) => {
      value = value.replace(/[^0-9]/g, '')
      value = formatNumber(value)
      vQuantity.value.value = value
      emit('update:modelValue', {...props.modelValue, [key]: value})
    }

    const updateFreq = (key, value) => {
      emit('update:modelValue', {...props.modelValue, [key]: value})
    }

    return {
      id,
      vQuantity,

      update,
      updateFreq,
    }
  },
})
</script>