export default class RiskIndicatorCurrent {
  constructor (data) {
    this.ind = data.ind,
    this.date = data.date,
    this.diff = data.diff,
    this.message = data.message,
    this.subIndicators = data.subIndicators
  }

  get getSubIndicators () {
    return this.subIndicators
  }
}
