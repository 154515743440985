import PortfolioTrading from '@/domain/models/PortfolioTrading';

class PortfolioTradingFactory {
  get(data) {
    const portfolio = {
      assets: data.assets,
      buy: data.buy,
      name: data.name,
      owner: data.owner,
      portfolioValue: data.portfolio_value,
      sell: data.sell,
      tradeValue: data.trade_value,
      weights: data.weights,
    };

    return new PortfolioTrading(portfolio);
  }
}

export default new PortfolioTradingFactory();
