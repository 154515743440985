<template>
  <div class="py-6">
    <Alert v-if="saved" message="Preferences were saved correctly!" @dismiss="saved = false" />
    <Error v-if="store.getters.hasErrors" :errors="store.getters.getErrors" @dismiss="saved = false"/>

    <div>
      <div class="text-3xl font-bold">
        Settings
      </div>
      <div class="main-container">
        <Alert v-if="modified" message="Click 'save' to save the changes" @dismiss="modified = false" />
        <div class="main-container__line">
          Email
          <div class="mt-2 max-w-xs">
            <input type="text" disabled class="form-control" :value="email" />
          </div>
        </div>
        <div class="main-container__line">
          <UpdatePasswordModal
          @update="updatePassword"
        />
        </div>
        <div class="main-container__line">
          Notifications
          <Switch v-model="emailNotifications" @changeSwitch="onChangeFields">Email</Switch>
        </div>
        <div class="main-container__line">
          Theme color
          <ThemeColors v-model="themeColor" @changeColor="onChangeFields" />
        </div>
        <div class="main-container__line">
          Logo
          <LogoUpdate v-model="logo" @change="onChangeFields" @changeLogo="onChangeFields"/>
        </div>
        <div class="main-container__line button-list">
          <button class="btn btn-primary" @click="save">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import UpdatePasswordModal from "../users/Modal.vue";
import Switch from "@/components/switch/Main.vue";
import ThemeColors from "@/components/theme-colors/Main.vue";
import LogoUpdate from "@/components/logo-upload/Main.vue";
import Error from "@/components/error/Main.vue";
import Alert from "@/components/alert/Main.vue";

export default defineComponent({
  components: {
    UpdatePasswordModal,
    Switch,
    ThemeColors,
    LogoUpdate,
    Error,
    Alert
},
  setup() {
    const store = useStore();
    const email = ref();
    const emailNotifications = ref(false);
    const themeColor = ref("");
    const logo = ref("");
    const username = ref();

    const saved = ref(false)
    const modified = ref(false)

    store.dispatch("whoAmI")
      .then((userData) => {
        email.value = userData.email;
        username.value = userData.username;

        store.dispatch("getEmailNotificationsPreference", username.value)
          .then(enabled => {
            emailNotifications.value = enabled;
          })
        store.dispatch("getThemeColorPreference", username.value)
          .then(currentThemeColor => {
            themeColor.value = currentThemeColor;
          })
        store.dispatch("getLogoPreference", username.value)
          .then(currentLogo => {
            logo.value = currentLogo;
          })
      });

    const updatePassword = (newPassword) => {
      const userForm = {
        username: username.value,
        password: newPassword,
      };

      store
        .dispatch('updatePassword', userForm)
        .then()
        .catch((error) => console.log('error', error));
    };

    const save = () => {
      const emailNotificationsPreferenceData = {
        username: username.value,
        isEmailNotificationEnabled: emailNotifications.value,
      }
      const themeColorPreferenceData = {
        username: username.value,
        themeColor: themeColor.value,
      }
      const logoPreferenceData = {
        username: username.value,
        logo: logo.value,
      }
      Promise.all([
        store.dispatch("setEmailNotificationsPreference", emailNotificationsPreferenceData),
        store.dispatch("setThemeColorPreference", themeColorPreferenceData),
        store.dispatch("setLogoPreference", logoPreferenceData),
      ])
        .then(() => {
          store.commit("setLogo", logo.value);
          saved.value = true;
        })
        .catch(err => {
          console.log(err.response.data)
          store.commit('setErrors', err.response.data)
        })
    };


    const onChangeFields = () => {
      modified.value = true
    }

    return {
      email,
      emailNotifications,
      themeColor,
      logo,
      saved,
      modified,
      store,

      updatePassword,
      onChangeFields,
      save,
    }
  }
})
</script>

<style lang="scss" scoped>
  .main-container {
    @apply mt-10 bg-white p-4 rounded-md;
    &__line {
      @apply pt-6;
    }
    .button-list {
      @apply flex gap-3 justify-end
    }
  }
</style>
