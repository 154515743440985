import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/views/login/Main.vue';
import Logout from '@/views/logout/Main.vue';
import ResetPassword from '@/views/reset-password/Main.vue';
import SetPassword from '@/views/set-password/Main.vue';
import Dashboard from '@/views/dashboard/Main.vue';
import WealthSimulator from '@/views/wealth-simulator/Main.vue';
import RiskIndicators from '@/views/risk-indicators/Main.vue';
import Data from '@/views/data/Main.vue';
import Strategies from '@/views/strategies/Main.vue';
import NewStrategy from '@/views/new-strategy/Main.vue';
import ModifyStrategy from '@/views/modify-strategy/Main.vue';
import Portfolios from '@/views/portfolios/Main.vue';
import NewPortfolio from '@/views/new-portfolio/Main.vue';
import ModifyPortfolio from '@/views/modify-portfolio/Main.vue';
import Team from '@/views/team/Main.vue';
import PortfolioTrading from '@/views/portfolio-trading/Main.vue';
import StrategyTrading from '@/views/strategy-trading/Main.vue';
import Users from '@/views/users/Main.vue';
import NewUser from '@/views/new-user/Main.vue';
import Settings from '@/views/settings/Main.vue';
import { store } from '@/store';

const routes = [
  {
    path: '/',
    redirect: '/login',
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/set-password/:token([a-zA-Z0-9_-]+)',
    name: 'setPassword',
    component: SetPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    redirect: { name: 'risk-indicators' },
    component: Dashboard,
    children: [
      {
        path: 'wealth-simulator',
        name: 'wealth-simulator',
        component: WealthSimulator,
        meta: {
          title: ['Wealth Simulator'],
        },
      },
      {
        path: 'risk-indicators',
        name: 'risk-indicators',
        component: RiskIndicators,
        meta: {
          title: ['Risk indicators'],
        },
      },
      {
        path: 'data',
        name: 'data',
        component: Data,
        props: true,
        meta: {
          title: ['Data'],
        },
      },
      {
        path: 'strategies',
        name: 'strategies',
        component: Strategies,
        meta: {
          title: ['Strategies'],
        },
      },
      {
        path: 'strategies/new',
        name: 'new-strategy',
        component: NewStrategy,
        meta: {
          title: ['Strategy', 'New Strategy'],
        },
      },
      {
        path: 'strategies/modify',
        name: 'modify-strategy',
        component: ModifyStrategy,
        props: true,
        meta: {
          title: ['Strategy', 'Modify Strategy'],
        },
      },
      {
        path: 'strategies/trading',
        name: 'strategy-trading',
        component: StrategyTrading,
        props: true,
        meta: {
          title: ['Strategy', 'Trading'],
        },
      },
      {
        path: 'portfolios',
        name: 'portfolios',
        component: Portfolios,
        meta: {
          title: ['Portfolios'],
        },
      },
      {
        path: 'portfolios/new',
        name: 'new-portfolio',
        component: NewPortfolio,
        meta: {
          title: ['Portfolios', 'New Portfolio'],
        },
      },
      {
        path: 'portfolios/modify',
        name: 'modify-portfolio',
        component: ModifyPortfolio,
        meta: {
          title: ['Portfolio', 'Modify Portfolio'],
        },
      },
      {
        path: 'portfolios/trading',
        name: 'portfolio-trading',
        component: PortfolioTrading,
        meta: {
          title: ['Portfolio', 'Trading'],
        },
      },
      {
        path: '/team',
        name: 'team',
        component: Team,
        meta: {
          title: ['Team'],
        },
      },
      {
        path: '/users',
        name: 'users',
        component: Users,
        meta: {
          title: ['Users'],
        },
      },
      {
        path: '/users/new',
        name: 'new-user',
        component: NewUser,
        meta: {
          title: ['Users', 'New User'],
        },
      },
      {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
          title: ['Settings'],
        },
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(_, __, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

router.beforeEach(async (to, _, next) => {
  const hasUserLoggedIn = store.getters.hasUserLoggedIn;

  if (to.meta.requiresAuth && !hasUserLoggedIn) {
    next({ name: 'login' });
    return;
  }
  if (to.meta.requiresAuth) {
    store.dispatch('whoAmI').catch(() => {
      next({ name: 'login' });
      return;
    });
  }
  if (!store.getters.isUserAdmin && to.name === 'users') {
    next({ name: 'dashboard' });
    return;
  }

  next();
});

export default router;
