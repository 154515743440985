export const getDefaultState = () => ({
  auth: {
    user: localStorage.getItem(process.env.VUE_APP_ACCESS_USER) || null,
    token: localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN) || null
  },
  loggedUser: null,
  logo: "",
  errors: null,
  menu: [{
    icon: 'HomeIcon',
    pageName: 'risk-indicators',
    title: 'Risk indicators',
  },{
    icon: 'BoxIcon',
    pageName: 'data',
    title: 'Data'
  },{
    icon: 'SlidersIcon',
    pageName: 'strategies',
    title: 'Strategies'
  },{
    icon: 'BriefcaseIcon',
    pageName: 'portfolios',
    title: 'Portfolios'
  },{
    icon: 'YoutubeIcon',
    pageName: 'wealth-simulator',
    title: 'Wealth Simulator'
  },{
    icon: 'MapIcon',
    pageName: 'team',
    title: 'Team'
  },{
    icon: 'UsersIcon',
    pageName: 'users',
    title: 'Users'
  },{
    icon: 'SettingsIcon',
    pageName: 'settings',
    title: 'Settings'
  },{
    icon: 'LogOutIcon',
    pageName: 'logout',
    title: 'Logout'
  }],
  nonAdminMenu: [{
    icon: 'HomeIcon',
    pageName: 'risk-indicators',
    title: 'Risk indicators',
  },{
    icon: 'BoxIcon',
    pageName: 'data',
    title: 'Data'
  },{
    icon: 'SlidersIcon',
    pageName: 'strategies',
    title: 'Strategies'
  },{
    icon: 'BriefcaseIcon',
    pageName: 'portfolios',
    title: 'Portfolios'
  },{
    icon: 'YoutubeIcon',
    pageName: 'wealth-simulator',
    title: 'Wealth Simulator'
  },{
    icon: 'MapIcon',
    pageName: 'team',
    title: 'Team'
  },{
    icon: 'SettingsIcon',
    pageName: 'settings',
    title: 'Settings'
  },{
    icon: 'LogOutIcon',
    pageName: 'logout',
    title: 'Logout'
  }]
})

export default getDefaultState()
