export default class PortfolioStats {
  constructor (data) {
    this.benchmark = data.benchmark
    this.benchmarkDescription = data.benchmarkDescription
    this.benchmarkTicker = data.benchmarkTicker
    this.market = data.market
    this.name = data.name
    this.owner = data.owner
    this.portfolio = data.portfolio,
    this.from = data.from,
    this.to = data.to
  }

  get getBenchmark () {
    return this.benchmark
  }

  get getBenchmarkDescription () {
    return this.benchmarkDescription
  }

  get getBenchmarkTicker () {
    return this.benchmarkTicker
  }

  get getMarket () {
    return this.market
  }

  get getName () {
    return this.name
  }

  get getOwner () {
    return this.owner
  }

  get getPortfolio () {
    return this.portfolio
  }

  get getFrom () {
    return this.from
  }

  get getTo () {
    return this.to
  }
}
