export default class RiskIndicator {
  constructor (data) {
    this.name = data.name,
    this.index_name = data.index_name,
    this.ticker = data.ticker,
    this.riskIndicators = data.riskIndicators,
    this.children = []
  }

  get getName () {
    return this.name
  }

  get getIndexName () {
    return this.index_name
  }

  get getTicker () {
    return this.ticker
  }

  get getRiskIndicators () {
    return this.riskIndicators
  }

  get getChildren () {
    return this.children
  }

  get hasChildren () {
    return this.children.length > 0;
  }
}
