<template>
  <div class="dropdown">
    <Tippy tag="div" content="Export" v-if="exportTypes.length === 1">
      <button
        class="dropdown-toggle btn border-theme-36 p-2 mb-5 sm:mb-0 opacity-50"
        aria-expanded="false"
        @click="onExport(exportTypes[0].key)"
        
      >
        <DownloadIcon size="16" class="custom-icon" fill="white" />
      </button>
    </Tippy>
    <button
      class="dropdown-toggle btn btn-outline-dark mb-5 sm:mb-0 opacity-50"
      aria-expanded="false"
      v-else
    >
      <DownloadIcon class="w-5 h-5 mr-5" /> Export
    </button>
    <div v-if="exportTypes.length > 1" class="dropdown-menu w-40">
      <div class="dropdown-menu__content box p-2">
        <a
          v-for="exportType in exportTypes"
          :key="exportType"
          href="javascript:;"
          class="block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md"
          @click="onExport(exportType.key)"
          >{{ exportType.label }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['export'],
  props: {
    exportTypes: Array,
  },
  setup(_, { emit }) {
    const onExport = (key) => {
      cash('.dropdown').dropdown('hide');
      emit('export', key);
    };

    return {
      onExport,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-icon {
  stroke: 'black';
  stroke-width: 3px;
}
</style>
