import PortfolioKey from "../models/PortfolioKey"

class PortfolioKeyFactory {
  get (data) {
    return new PortfolioKey({
      name: data.name,
      owner: data.owner,
    })
  }
}

export default new PortfolioKeyFactory()
