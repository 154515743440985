export function percent(number, decimals) {
  if (typeof decimals == 'undefined') {
    decimals = 2;
  }
  const multiplier = 10 ** decimals;
  return Math.round(number * 100 * multiplier) / 100 + '%';
}

export function format(number, decimals) {
  const options = {};
  if (typeof decimals != 'undefined') {
    options.minimumFractionDigits = decimals;
  }
  return new Intl.NumberFormat('EN', options).format(number);
}

export function stringToNumber(string) {
  return parseFloat(string);
}

export function roundToTwoDecimals(number) {
  return Math.round(number * 100) / 100;
}

export function turnCurrencyStringToNumber(string) {
  return stringToNumber(string.replace(/[^0-9]/g, ''));
}
