import { getTypeFromAutomaticCondition } from "./StrategyItemType"

class StrategyCreateTransformer {
  get (data, owner) {
    const processedData = {
      owner: owner,
      name: data.name,
      description: data.description,
      acl: data.acl,
      benchmark: data.benchmark.split('@')[0],
      portfolios: data.portfolio.filter(portfolio => !!portfolio).map(portfolio => parsePortfolio(portfolio)),
      strategy: {
        indicator: data.riskIndicator,
        type: getTypeFromAutomaticCondition(data.thresholdData.automaticThreshold),
      }
    }

    if (data.oldName !== null) {
      processedData["old_name"] = data.oldName
    }

    if (!data.thresholdData.automaticThreshold) {
      processedData.strategy["thresholds"] = data.thresholdData.thresholdRange
    }

    return processedData
  }
}

const parsePortfolio = (portfolio) => {
  return {
    name: portfolio.getName,
    owner: portfolio.getOwner,
    acl: portfolio.getACL,
    description: portfolio.getDescription,
    assets: portfolio.getAssets.map(asset => {
      return {
        ticker: asset.ticker,
        weight: parseInt(asset.weight, 10)
      }
    })
  }
}

export default new StrategyCreateTransformer()
