export default class Portfolio {
  constructor(data) {
    this.assets = data.assets;
    this.buy = data.buy;
    this.name = data.name;
    this.owner = data.owner;
    this.portfolioValue = data.portfolio_value;
    this.sell = data.sell;
    this.tradeValue = data.trade_value;
    this.weights = data.weights;
  }

  get getAssets() {
    return this.assets;
  }

  get getBuy() {
    return this.buy;
  }

  get getName() {
    return this.name;
  }

  get getOwner() {
    return this.owner;
  }

  get getPortfolioValue() {
    return this.portfolioValue;
  }

  get getSell() {
    return this.sell;
  }

  get getTradeValue() {
    return this.tradeValue;
  }

  get getWeights() {
    return this.weights;
  }
}
