<template>
  <div class="flex flex-col gap-y-5 lg:flex-row gap-x-5 lg:gap-x-7" :class="item_align">
    <div class="button-group">
      <Button
        :extraClass="rangeButtonClass('month', id)"
        :id="id"
        period="month"
        @search="search"
        v-if="skipButtons.indexOf('month') < 0"
      >Month</Button>
      <Button
        :extraClass="rangeButtonClass('ytd', id)"
        :id="id"
        period="ytd"
        @search="search"
        v-if="skipButtons.indexOf('ytd') < 0"
      >YTD</Button>
      <Button
        :extraClass="rangeButtonClass('year', id)"
        :id="id"
        period="year"
        @search="search"
        v-if="skipButtons.indexOf('year') < 0"
      >1Y</Button>
      <Button
        :extraClass="rangeButtonClass('year2',id)"
        :id="id"
        period="year2"
        @search="search"
        v-if="skipButtons.indexOf('year2') < 0"
        >2Y</Button>
      <Button
        :extraClass="rangeButtonClass('year3', id)"
        :id="id"
        period="year3"
        @search="search"
        v-if="skipButtons.indexOf('year3') < 0"
      >3Y</Button>
      <Button
        :extraClass="rangeButtonClass('year5', id)"
        :id="id"
        period="year5"
        @search="search"
        v-if="skipButtons.indexOf('year5') < 0"
        >5Y</Button>
      <Button
        :extraClass="rangeButtonClass('max', id)"
        :id="id"
        period="max"
        @search="search"
        v-if="skipButtons.indexOf('max') < 0"
      >Max.</Button>
    </div>
    <div>
      <DateRange
        class="relative w-60 mx-auto"
        :key="dates"
        v-model="dates"
        :openPosition="openPosition"
        @selected="calendarSearch(id)"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref} from 'vue'
import dateRange from '@/domain/services/dateHelper'
import dayjs from "dayjs";
import Button from "./Button.vue";
import DateRange from '../date-range/Main.vue';

export default defineComponent({
  emits: [ 'search' ],
  components: { Button, DateRange },
  props: {
    id: String,
    item_align: {
      type: String,
      default: 'items-start'
    },
    selected: String,
    skipButtons: {
      type: Array,
      default: () => { return [] }
    },
    minDate: String,
    isMaxSelected: {
      type: Boolean,
      default: false
    },
    openPosition: {
      type: String,
      default: 'center'
    }
  },
  setup(props, { emit }) {
    const selectedRangeButton = ref(props.selected)
    const dates = ref()
    const ranges = {
      month: dateRange.month,
      ytd: dateRange.ytd,
      year: dateRange.year,
      year2: dateRange.year2,
      year3: dateRange.year3,
      year5: dateRange.year5,
      max: dateRange.max,
    }

    onMounted(() => {
      search(props.selected)
    })

    const fromDateToString = (dateRange) => {
      return dayjs(dateRange[0]).format('D MMM, YYYY') + ' - ' + dayjs(dateRange[1]).format('D MMM, YYYY')
    }

    const rangeButtonClass = (data) => {
      return selectedRangeButton.value === data ? 'btn-primary' : 'btn-secondary'
    }

    const search = (type, id) => {
      selectedRangeButton.value = type
      const dateRange = ranges[selectedRangeButton.value]()

      if(type === 'max' && props.minDate) {
        dateRange[0] = new Date(Date.parse(props.minDate))
      }
      dates.value = dateRange
      const data = {
        id: props.id,
        range: dateRange
      }
      emit('search', data, type)
    }

    const calendarSearch = () => {
      selectedRangeButton.value = ''
      const data = {
        id: props.id,
        range: dates.value
      }
      emit('search', data)
    }

    dates.value = fromDateToString(ranges[props.selected]())
    dates.value = [
      ranges[props.selected]()[0],
      ranges[props.selected]()[1],
    ]


    return {
      dates,

      rangeButtonClass,
      search,
      calendarSearch
    }
  }
})
</script>

<style lang="scss" scoped>
  .button-group {
    button.btn:first-child {
      border-top-left-radius: .375rem;
      border-bottom-left-radius: .375rem;
    }
    button.btn:last-child {
      border-top-right-radius: .375rem;
      border-bottom-right-radius: .375rem;
    }
    button.btn {
      border-radius: 0;
      border-width: 0;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &.btn-secondary {
        background-color: #e4e8ec;
      }
    }
  }
</style>
