import Portfolio from "@/domain/models/Portfolio"
import PortfolioAsset from "@/domain/models/PortfolioAsset"

class PortfolioFactory {
  get (data) {
    const portfolio = {
      name: data.name,
      description: data.description,
      benchmark: data.benchmark,
      owner: data.owner,
      assets: data.assets.map(elem => new PortfolioAsset(elem)),
      acl: data.acl
    }

    return new Portfolio(portfolio)
  }
}

export default new PortfolioFactory()
