import { isAutomaticThresholdFromType } from "../services/StrategyItemType"

export default class StrategyItem {
  constructor (data) {
    this.indicator = data.indicator
    this.type = data.type
    this.thresholds = data.thresholds
  }

  get getIndicator () {
    return this.indicator
  }

  get getType () {
    return this.type
  }

  get getThresholds () {
    return this.thresholds
  }

  get automaticThresholds () {
    return isAutomaticThresholdFromType(this.getType)
  }

  get getThresholdRanges () {
    if (this.automaticThresholds) {
      return this.getThresholds.map(th => Object.values(th).pop())
    }

    return this.getThresholds
  }
}
