import DataCategory from "@/domain/models/DataCategory"

class DataCategoryFactory {
  get (data) {
    const dataCategory = {
      id: data.id,
      label: data.label,
      description: data.description
    }

    return new DataCategory(dataCategory)
  }
}

export default new DataCategoryFactory()
