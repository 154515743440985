import StrategyData from "../models/StrategyData"

class StrategyDataFactory {
  get (data) {
    return new StrategyData({
      available: data.available,
      benchmark: data.benchmark,
      benchmarkDescription: data.benchmark_description,
      benchmarkTicker: data.benchmark_ticker,
      data: data.data,
      index: data.index,
      indicator: data.indicator,
      name: data.name,
      owner: data.owner,
      portfolio: data.portfolio,
      series: data.series,
      strategyIndicator: data.strategy.indicator,
      strategyThresholds: data.strategy.thresholds,
      strategyType: data.strategy.type,
      thresholds: data.thresholds
    })
  }
}

export default new StrategyDataFactory()
