<template>
  <Tippy tag="a" content="Notification">
    <button
      class="btn border-theme-36 p-2 mb-5 sm:mb-0 mr-2 opacity-50 font-bold"
      @click="$emit('change')"
    >
      <BellOffIcon v-if="!isNotificationOn" size="16" class="custom-icon" fill="white" />
      <BellIcon v-else size="16" class="custom-icon" fill="white" />
    </button>
  </Tippy>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['change'],
  props: {
    isNotificationOn: Boolean,
  },
});
</script>

<style lang="scss" scoped>
.custom-icon {
  stroke: 'black';
  stroke-width: 3px;
}
</style>
