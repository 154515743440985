import { login, resetPassword, setPassword, logout } from '@/api/AuthAPI';

import {
  getIndexRiskIndicators,
  getRiskIndicatorsSubIndicators,
  listRiskIndicators,
  getRiskIndicatorData
} from '@/api/RiskIndicatorsAPI';

import { getHelpRiskIndicator, getHelpTicker } from '@/api/HelpAPI';

import {
  getData,
  getKeys,
  getGraphicData,
  searchData,
  searchAssets,
  searchExactData,
  searchPreferredIndicators,
  searchIndicators,
  searchPreferredBenchmarks,
  searchBenchmarks,
  searchAssetData,
  getMetadata,
} from '@/api/DataAPI';

import {
  getStrategyData,
  getStrategyMinDate,
  getStrategyPerformanceStats,
  getStrategiesKeys,
  getStrategy,
  createStrategy,
  deleteStrategy,
  updateStrategyTrading,
  updateAccessSharingStrategy
} from '@/api/StrategiesAPI';

import {
  getPortfolioKeys,
  getPortfolio,
  getPortfolioData,
  getPortfolioStats,
  getPortfolioMinDate,
  createPortfolio,
  deletePortfolio,
  updatePortfolioTrading,
  updateAccessSharingPorfolio
} from '@/api/PortfoliosAPI';

import { getSimulation } from '@/api/WealthSimulatorAPI';

import {
  strategiesExportDoc,
  dataExportDoc,
  portfoliosExportDoc,
} from '@/api/ExportAPI';

import {
  getAllUsers,
  getUserInfo,
  getRoles,
  updatePassword,
  deleteUser,
  updateUser,
  createUser,
  whoAmI,
} from '@/api/UsersAPI';

import {
  setEmailNotificationsPreference,
  getEmailNotificationsPreference,
  setThemeColorPreference,
  getThemeColorPreference,
  setLogoPreference,
  getLogoPreference,
  getNotificationPreference,
  setNotificationPreference,
  deleteNotificationPreference,
} from '@/api/PreferencesAPI';
import {getError} from "@/domain/services/ErrorDataTransformer";
import { ChevronLeftIcon } from '@zhuowenli/vue-feather-icons';

export default {
  login({ commit }, formData) {
    return login(formData)
      .then((res) => {
        commit('setAuth', res.data);
        return res;
      })
      .catch((error) => Promise.reject(error));
  },
  resetPassword(_, user) {
    return resetPassword(user)
      .then()
      .catch((error) => Promise.reject(error));
  },
  setPassword({ commit }, { token, password }) {
    return setPassword(token, password)
      .then()
      .catch((error) => Promise.reject(error));
  },
  logout({ commit }) {
    return logout()
      .finally(() => {
        commit('logout');
      })
      .catch((error) => Promise.reject(error));
  },

  getIndexRiskIndicators(_, { ticker, risk, from, to }) {
    return getIndexRiskIndicators(ticker, risk, from, to)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  getRiskIndicatorsSubIndicators(_, { ticker }) {
    return getRiskIndicatorsSubIndicators(ticker)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  listRiskIndicators(_, data) {
    return listRiskIndicators(data)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  getRiskIndicator(_, { ticker }) {
    return getRiskIndicatorData(ticker)
        .then((res) => res)
        .catch((error) => Promise.reject(error))
  },

  getData(_, { category, page }) {
    return getData(category, page)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  getKeys() {
    return getKeys()
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  getGraphicData(_, { ticker, from, to }) {
    return getGraphicData(ticker, from, to)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  searchData(_, search) {
    return searchData(search)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  searchAssets(_, search) {
    return searchAssets(search)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  searchPreferredBenchmarks() {
    return searchPreferredBenchmarks()
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  searchBenchmarks(_, search) {
    return searchBenchmarks(search)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  getMetadata(_, ticker) {
    return getMetadata(ticker)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  searchPreferredIndicators() {
    return searchPreferredIndicators()
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  searchIndicators(_, search) {
    return searchIndicators(search)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  searchAssetData(_, search) {
    return searchAssetData(search)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },

  getStrategiesKeys() {
    return getStrategiesKeys()
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  getStrategy(_, { name, owner }) {
    return getStrategy(name, owner)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  getStrategyData(_, { name, owner, from, to, series }) {
    return getStrategyData(name, owner, from, to, series)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  getStrategyMinDate(_, { name, owner }) {
    return getStrategyMinDate(name, owner)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  getStrategyPerformanceStats(_, { name, owner, from, to }) {
    return getStrategyPerformanceStats(name, owner, from, to)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  searchExactData(_, { search, mode }) {
    return searchExactData(search, mode)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  createStrategy(_, strategy) {
    return createStrategy(strategy)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  deleteStrategy(_, { name, owner }) {
    return deleteStrategy(name, owner)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  updateStrategyTrading(_, { name, owner, portfolio, assets }) {
    return updateStrategyTrading(name, owner, portfolio, assets)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  updateACLStrategy(_, {name, owner, acl}) {
    return updateAccessSharingStrategy(name, owner, acl)
        .then((res) => res)
        .catch((error) => Promise.reject(error));
  },
  getPortfolioKeys() {
    return getPortfolioKeys()
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  getPortfolio(_, { name, owner }) {
    return getPortfolio(name, owner)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  getPortfolioData(_, { name, owner, from, to, series }) {
    return getPortfolioData(name, owner, from, to, series)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  getPortfolioStats(_, { name, owner, from, to }) {
    return getPortfolioStats(name, owner, from, to)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  getPortfolioMinDate(_, { name, owner }) {
    return getPortfolioMinDate(name, owner)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  createPortfolio(_, portfolio) {
    return createPortfolio(portfolio)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  deletePortfolio(_, { name, owner }) {
    return deletePortfolio(name, owner)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  updatePortfolioTrading(_, { name, owner, assets }) {
    return updatePortfolioTrading(name, owner, assets)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },

  updateACLPortfolio(_, {owner, name, acl}) {
    return updateAccessSharingPorfolio(owner, name, acl)
        .then(res => res)
        .catch((error) => Promise.reject(error))
  },

  getSimulation(
    _,
    {
      owner,
      initialContribution,
      strategy,
      periodicContribution,
      frequency,
      years,
    }
  ) {
    return getSimulation(
      owner,
      initialContribution,
      strategy,
      periodicContribution,
      frequency,
      years
    )
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },

  strategiesExportDoc(_, data) {
    return strategiesExportDoc(data)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  dataExportDoc(_, data) {
    return dataExportDoc(data)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  portfoliosExportDoc(_, data) {
    return portfoliosExportDoc(data)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },

  getHelpRiskIndicators() {
    return getHelpRiskIndicator();
  },

  getHelpTicker(_, ticker) {
    return getHelpTicker(ticker);
  },

  getAllUsers() {
    return getAllUsers()
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  getUserInfo(_, name) {
    return getUserInfo(name)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  getRoles() {
    return getRoles()
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  updatePassword(_, { username, password }) {
    return updatePassword(username, password)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  deleteUser(_, { username }) {
    return deleteUser(username)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  updateUser(_, { username, email, roles }) {
    return updateUser(username, email, roles)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  createUser(_, { name, email, password, roles }) {
    return createUser(name, email, password, roles);
  },
  whoAmI({ commit }) {
    return whoAmI()
      .then((res) => {
        commit('setUser', res);

        return res;
      })
      .catch((error) => Promise.reject(error));
  },
  setEmailNotificationsPreference({ commit }, { username, isEmailNotificationEnabled }) {
    return setEmailNotificationsPreference(username, isEmailNotificationEnabled)
      .then()
      .catch((error) => {
        return Promise.reject(error)
      });
  },
  getEmailNotificationsPreference(_, username) {
    return getEmailNotificationsPreference(username)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  setThemeColorPreference(_, { username, themeColor }) {
    return setThemeColorPreference(username, themeColor)
      .then()
      .catch((error) => Promise.reject(error));
  },
  getThemeColorPreference(_, username) {
    return getThemeColorPreference(username)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  setLogoPreference(_, { username, logo }) {
    return setLogoPreference(username, logo)
      .then()
      .catch((error) => Promise.reject(error));
  },
  getLogoPreference(_, username) {
    return getLogoPreference(username)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  setNotificationPreference(_, { username, key, value }) {
    return setNotificationPreference(username, key, value)
      .then()
      .catch((error) => Promise.reject(error));
  },
  getNotificationPreference(_, { username, key }) {
    return getNotificationPreference(username, key)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  },
  deleteNotificationPreference(_, { username, key }) {
    return deleteNotificationPreference(username, key)
      .then()
      .catch((error) => Promise.reject(error));
  },
};
