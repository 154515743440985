import axios from '@/plugins/axios';

const URL_GET_PREFERENCES =
  '/api/users/preferences?username={username}&key={key}';
const URL_SET_PREFERENCES = '/api/users/preferences';

const ENABLED_STRING_VALUE = 'true';
const DISABLED_STRING_VALUE = 'false';
const EMAIL_NOTIFICATIONS_KEY = 'email-notifications';
const THEME_COLOR_KEY = 'theme-color';
const LOGO_KEY = 'logo';

export function getEmailNotificationsPreference(username) {
  const url = URL_GET_PREFERENCES.replace('{username}', username).replace(
    '{key}',
    EMAIL_NOTIFICATIONS_KEY
  );

  return axios
    .get(url)
    .then((res) => {
      return res.data.value === ENABLED_STRING_VALUE;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function setEmailNotificationsPreference(
  username,
  isEmailNotificationEnabled
) {
  return axios
    .put(URL_SET_PREFERENCES, {
      username: username,
      key: EMAIL_NOTIFICATIONS_KEY,
      value: isEmailNotificationEnabled
        ? ENABLED_STRING_VALUE
        : DISABLED_STRING_VALUE,
    })
    .then()
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function getThemeColorPreference(username) {
  const url = URL_GET_PREFERENCES.replace('{username}', username).replace(
    '{key}',
    THEME_COLOR_KEY
  );

  return axios
    .get(url)
    .then((res) => {
      return res.data.value ?? '';
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function setThemeColorPreference(username, themeColor) {
  return axios
    .put(URL_SET_PREFERENCES, {
      username: username,
      key: THEME_COLOR_KEY,
      value: themeColor,
    })
    .then()
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function getLogoPreference(username) {
  const url = URL_GET_PREFERENCES.replace('{username}', username).replace(
    '{key}',
    LOGO_KEY
  );

  return axios
    .get(url)
    .then((res) => {
      return res.data.value ?? '';
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function setLogoPreference(username, logo) {
  return axios
    .put(URL_SET_PREFERENCES, {
      username: username,
      key: LOGO_KEY,
      value: logo,
    })
    .then()
    .catch((error) => {
      return Promise.reject(error);
    });
}
export function getNotificationPreference(username, key) {
  const url = URL_GET_PREFERENCES.replace('{username}', username).replace(
    '{key}',
    `${EMAIL_NOTIFICATIONS_KEY}/${key}`
  );
  return axios
    .get(url)
    .then((res) => res.data)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function setNotificationPreference(username, key, value) {
  return axios
    .put(URL_SET_PREFERENCES, {
      username: username,
      key: `${EMAIL_NOTIFICATIONS_KEY}/${key}`,
      value: value,
    })
    .then()
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function deleteNotificationPreference(username, key) {
  const data = {
    username: username,
    key: `${EMAIL_NOTIFICATIONS_KEY}/${key}`,
  };
  return axios
    .delete(URL_SET_PREFERENCES, { data })
    .then()
    .catch((error) => {
      return Promise.reject(error);
    });
}
