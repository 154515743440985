import axios from '@/plugins/axios';
import UserFactory from '@/domain/services/UserFactory';

const URL_USERS = '/api/users';
const URL_USERS_SPECIFIC = `${URL_USERS}?username={name}`;
const URL_USERS_LIST = `${URL_USERS}/list`;
const URL_USERS_ROLES = `${URL_USERS}/roles`;
const URL_USERS_PASSWORD = `${URL_USERS}/password`;
const URL_USERS_WHOAMI = `${URL_USERS}/whoami`;

export function whoAmI() {
  const url = URL_USERS_WHOAMI;
  return axios
    .get(url)
    .then((res) => UserFactory.get(res.data))
    .catch((error) => Promise.reject(error));
}

export function getAllUsers() {
  const url = URL_USERS_LIST;
  return axios.get(url).then((res) => res.data);
}

export function getUserInfo(name) {
  const url = URL_USERS_SPECIFIC.replace('{name}', encodeURIComponent(name));
  return axios
    .get(url)
    .then((res) => UserFactory.get(res.data))
    .catch((error) => Promise.reject(error));
}

export function getRoles() {
  const url = URL_USERS_ROLES;
  return axios.get(url).then((res) => res.data);
}

export function updatePassword(user, password) {
  const data = {
    username: user,
    password: password,
  };
  return axios
    .put(URL_USERS_PASSWORD, data)
    .then()
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function deleteUser(user) {
  const data = {
    username: user,
  };

  return axios
    .delete(URL_USERS, { data: data })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

export function updateUser(user, email, roles) {
  const data = {
    username: user,
    email: email,
    roles: roles,
  };

  return axios
    .put(URL_USERS, data)
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

export function createUser(name, email, password, roles) {
  const data = {
    username: name,
    email: email,
    password: password,
    roles: roles,
  };

  return axios
    .post(URL_USERS, data)
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}
