export default class Strategy {
  constructor (data) {
    this.acl = data.acl
    this.benchmark = data.benchmark
    this.description = data.description
    this.equityExposure = data.equityExposure
    this.fqn = data.fqn
    this.name = data.name
    this.owner = data.owner
    this.portfolios = data.portfolios
    this.strategy = data.strategy
  }

  get getACL () {
    return this.acl
  }

  get getBenchmark () {
    return this.benchmark
  }

  get getDescription () {
    return this.description
  }

  get getEquityExposure () {
    return this.equityExposure
  }

  get getFQN () {
    return this.fqn
  }

  get getName () {
    return this.name
  }

  get getOwner () {
    return this.owner
  }

  get getPortfolios () {
    return this.portfolios
  }

  get getStrategy () {
    return this.strategy
  }
}
