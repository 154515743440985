import WealthSimulator from "../models/WealthSimulator"
import DataFactory from "./DataFactory"
class WealthSimulatorFactory {
  get (data) {
    return new WealthSimulator({
      returnOnInvestment: data.capital.final,
      totalNetWorth: data.capital.roi,
      initialContribution: data.capital.initial,
      totalPeriodicContribution: data.capital.periodic,
      totalExpenses: null,
      strategy: data.simulation.strategy,
      data: DataFactory.get(data.value.data, data.value.index.map(date => (new Date(date)).getTime()))
    })
  }
}

export default new WealthSimulatorFactory()
