<template>
  <div>
    <div>
      <a
        href="javascript:;"
        data-toggle="modal"
        data-target="#change-password-modal"
        class="button inline-block password-button"
      >
        Change password
      </a>
    </div>
    <div class="modal" id="change-password-modal">
      <div class="modal__content flex flex-col absolute w-full bg-white">
        <div class="py-5 px-8 text-m text-left text-black">
          <p class="font-bold">Change password</p>
          <p class="mt-2 mb-4">Type the password and save</p>

          <input
            type="text"
            class="intro-x form-control py-3 px-4 border-gray-300 block text-gray-800"
            v-model="newPassword"
            placeholder="**********"
          />
        </div>
        <div class="px-5 pb-5 text-right">
          <button
            type="button"
            data-dismiss="modal"
            class="btn btn-primary"
            @click="updatePassword"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  emits: ['update'],
  setup(_, { emit }) {
    const newPassword = ref('');

    const updatePassword = () => {
      emit('update', newPassword.value);
      newPassword.value = '';
    };

    return {
      newPassword,

      updatePassword,
    };
  },
});
</script>

<style lang="scss" scoped>
.modal__content {
  max-width: 380px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
}
.password-button {
  color: #6b7baa;
}
</style>
