import RiskIndicatorItem from "@/domain/models/RiskIndicatorItem";

class RiskDataFactory {
    get (data) {
        const dataRiskIndicator = {
            ticker: data.ticker,
            available: data.available,
        }
        return new RiskIndicatorItem(dataRiskIndicator)
    }
}

export default new RiskDataFactory()
