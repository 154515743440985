import Data from "@/domain/models/Data"
class DataFactory {
  get (x, y) {
    const data = {
      x: x,
      y: y,
    }

    return new Data(data)
  }
}

export default new DataFactory()
