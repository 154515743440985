<template>
  <div class="py-6">
    <Error
      v-if="creationError"
      :errors="creationError"
      @dismiss="creationError = null"
    />
    <div class="text-3xl font-bold">
      Create new user
    </div>
    <form class="w-full mt-5">
      <div
        class="flex flex-col h-full sm:w-[600px] sm:max-w-[600px] md:w-[800px] md:max-w-[800px]"
      >
        <div class="flex flex-col sm:flex-row gap-x-10 mb-6">
          <div class="w-full mb-6 sm:mb-0">
            <div class="mb-2">
              User
            </div>
            <Field name="name" label="Name" rules="required" v-slot="{ field }">
              <input
                type="text"
                class="intro-x form-control py-3 px-4 border-gray-300 block"
                placeholder="John Doe"
                v-model="userForm.name"
                :name="field.name"
                @blur="field.onBlur"
                @input="field.onInput"
                @change="field.onChange"
              />
              <ErrorMessage name="name" />
            </Field>
          </div>
          <div class="w-full">
            <div class="mb-2">
              Email
            </div>
            <Field
              name="email"
              label="Email"
              rules="required"
              v-slot="{ field }"
            >
              <input
                type="email"
                class="intro-x form-control py-3 px-4 border-gray-300 block"
                placeholder="John@doe.com"
                v-model="userForm.email"
                :name="field.email"
                @blur="field.onBlur"
                @input="field.onInput"
                @change="field.onChange"
              />
              <ErrorMessage name="email" />
            </Field>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row gap-x-10 mb-6">
          <div class="w-full mb-6 sm:mb-0">
            <div class="mb-2">
              Password
            </div>
            <Field
              name="password"
              label="Password"
              rules="required|min:10"
              v-slot="{ field }"
            >
              <input
                type="text"
                class="intro-x form-control py-3 px-4 border-gray-300 block"
                placeholder="**********"
                v-model="userForm.password"
                :name="field.password"
                @blur="field.onBlur"
                @input="field.onInput"
                @change="field.onChange"
              />
              <ErrorMessage name="password" />
            </Field>
          </div>
          <div class="w-full">
            <div class="mb-2">
              Confirm Password
            </div>
            <Field
              name="confirm_password"
              label="Confirm Password"
              rules="required|min:10"
              v-slot="{ field }"
            >
              <input
                type="text"
                :class="{ 'has-error': passwordError }"
                class="intro-x form-control py-3 px-4 border-gray-300 block"
                placeholder="**********"
                v-model="userForm.confirm_password"
                :name="field.confirm_password"
                @blur="field.onBlur"
                @input="field.onInput"
                @change="field.onChange"
              />
              <ErrorMessage name="confirm_password" />
              <div v-if="passwordError" class="text-sm text-theme-35 p-1">
                Passwords do not match
              </div>
            </Field>
          </div>
        </div>
        <div class="mt-6">
          <Roles :roles="roles" @changeRol="changeRol" />
        </div>

        <div class="mt-10 flex justify-end">
          <button class="btn border text-gray-700" @click="onUserClose">
            Cancel
          </button>
          <button class="btn btn-primary ml-3" @click="onUserSubmit">
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent, ref, reactive } from 'vue';
import { Field, useForm, defineRule, ErrorMessage } from 'vee-validate';
import { required, min } from '@vee-validate/rules';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Roles from '@/components/user-roles/Main.vue';
import Error from '@/components/error/Main.vue'

export default defineComponent({
  components: { Field, Roles, ErrorMessage, Error },
  setup() {
    const store = useStore();
    const router = useRouter();
    const user = ref();
    const roles = ref();
    const userRoles = ref([]);
    const passwordError = ref(false);
    const creationError = ref();
    const userForm = reactive({
      name: '',
      email: '',
      password: '',
      confirm_password: '',
    });

    const { handleSubmit } = useForm();
    defineRule('required', required);
    defineRule('min', min);

    const changeRol = (rol) => {
      userRoles.value.push(rol);
    };

    const passwordMatch = () => {
      return userForm.password === userForm.confirm_password ?? false;
    };

    const onUserClose = () => {
      router.push({ name: 'users' });
    };

    const onUserSubmit = handleSubmit((_, actions) => {
      if (passwordMatch()) {
        const data = {
          name: userForm.name,
          email: userForm.email,
          password: userForm.password,
          roles: Object.values(userRoles.value),
        };
        passwordError.value = false;
        store
          .dispatch('createUser', data)
          .then(() => onUserClose())
          .catch((error) => {
            creationError.value = error.response.data;
          });
        return;
      }
      passwordError.value = true;
    });

    store.dispatch('getRoles').then((userRolList) => {
      roles.value = Object.entries(userRolList);
    });

    return {
      user,
      roles,
      userForm,
      passwordError,
      creationError,

      changeRol,
      onUserSubmit,
      onUserClose,
    };
  },
});
</script>
