<template>
  <div class="logo-upload max-w-xs">
    <div class="logo-upload__wrapper">
      <XCircleIcon
        fill="red"
        class="logo-upload__clear-image"
        @click="clearImage"
      />
      <div v-if="modelValue === ''" class="logo-upload__no-image">
        400 x 200
      </div>
      <img v-else :src="modelValue"  class="logo-upload__image" />
    </div>
    <button type="button" class="btn btn-primary" @click="openImageDialog">Change logo</button>
    <input class="hidden" ref="inputFile" type="file" @change="uploadImage" accept="image/*" />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'LogoUpload',
  emits: ["update:modelValue", "changeLogo"],
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const inputFile = ref();
    const clearImage = () => {
      emit("update:modelValue", "");
      emit("changeLogo")

    }

    const openImageDialog = () => {
      inputFile.value.click();
    }

    const uploadImage = (input) => {
      const file = input.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        emit("update:modelValue", reader.result);
        emit("changeLogo")
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }

    return {
      inputFile,

      clearImage,
      openImageDialog,
      uploadImage,
    }
  },
})
</script>

<style lang="scss" scoped>
  .logo-upload {
    @apply flex flex-col items-center;
    &__wrapper {
      @apply border-2 border-dashed m-4 p-4 relative w-full;
      @apply border-theme-7 text-center;
    }
    &__no-image {
      @apply bg-theme-7 text-theme-28 flex justify-center p-10;
    }
    &__image {
      @apply max-h-24 inline-block;
    }
    &__clear-image {
      @apply text-theme-15 absolute right-2 top-2 cursor-pointer;
    }
  }
</style>
