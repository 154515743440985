<template>
  <div class="bg-white my-4 p-4 rounded-lg w-full 2xl:w-3/4 xl:w-3/4">
    <form @submit="onSubmit">
      <div class="mb-4">
        <div class="font-bold mb-2">
          Name
        </div>
        <Field name="name" :value="name" label="Strategy name" rules="required|hyphenName" v-slot="{ field, meta }">
          <input
            type="text"
            :class="{ 'has-error': meta.validated && !meta.valid }"
            class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
            placeholder="Strategy name"
            v-bind="field"
            @input="$emit('update:name', $event.target.value)"
          />
          <ErrorMessage name="name" />
        </Field>
      </div>
      <div class="mb-4">
        <div class="font-bold mb-2">
          Description
        </div>
        <Field name="description" :value="description" label="Strategy description" rules="required" v-slot="{ field, meta }">
          <input
            type="text"
            :class="{ 'has-error': meta.validated && !meta.valid }"
            class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
            placeholder="Strategy description"
            v-bind="field"
            @input="$emit('update:description', $event.target.value)"
          />
          <ErrorMessage name="description" />
        </Field>
      </div>
      <div class="mb-4">
        <div class="font-bold mb-2">
          Risk Indicator
        </div>
        <Field
          name="riskIndicator"
          :value="unprefixedRiskIndicator"
          label="Risk indicator"
          rules="required"
          v-slot="{ field, meta }"
        >
          <RiskIndicator
            v-bind="field"
            :usedClass="{ 'has-error': meta.validated && !meta.valid }"
            @change="$emit('update:riskIndicator', $event)"
          />
        </Field>
      </div>
      <div class="mb-4">
        <div class="font-bold mb-2">
          Benchmark
        </div>
        <Field :value="benchmark" name="benchmark" label="Benchmark" rules="required" v-slot="{ field, meta }">
          <Benchmark
            v-bind="field"
            :usedClass="{ 'has-error': meta.validated && !meta.valid }"
            @change="$emit('update:benchmark', $event)"
          />
          <ErrorMessage name="benchmark" />
        </Field>
      </div>
      <div>
        <Field v-model="filledInPortfolios" name="portfolios" label="portfolios" rules="minPortfolio">
          <div class="font-bold mb-2">
            Base portfolios
          </div>
          <Portfolio
            :portfolio="portfolio"
            :thresholdData="thresholdData"
            :options="options"
            @positionCreated="updatePosition"
            @positionDeleted="updatePosition"
            @thresholdAdjusted="$emit('update:thresholdData', $event)"
          />
          <ErrorMessage name="portfolios" />
          <div v-if="showErrorMessage" class="text-theme-5 mt-2">
            {{ thresholdMessage }}
          </div>
        </Field>
      </div>
      <div class="flex justify-between mt-9">
        <div>
          <button v-if="canDelete" class="btn btn-danger" type="button" @click="$emit('delete')">Delete</button>
        </div>
        <div class="flex justify-end gap-4">
          <router-link class="btn btn-outline-secondary" :to="{ name: 'strategies'}">Cancel</router-link>
          <button class="btn btn-primary">Save</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import Portfolio from './Portfolio.vue'
import { Field, ErrorMessage, defineRule, useForm } from "vee-validate"
import { required } from "@vee-validate/rules"
import Benchmark from '@/components/benchmark/Main'
import RiskIndicator from '@/components/risk-indicator/Main'
import { removePrefix } from '@/domain/services/RiskIndicatorTransformer'

const MIN_PORTFOLIOS = 2

export default defineComponent({
  components: {
    Field,
    ErrorMessage,
    Portfolio,
    Benchmark,
    RiskIndicator,
  },
  emits: [
    'submit',
    'update:name',
    'update:description',
    'update:riskIndicator',
    'update:thresholdData',
    'update:portfolio',
    'update:benchmark',
    'delete',
  ],
  props: {
    name: String,
    portfolio: Array,
    riskIndicator: String,
    benchmark: String,
    description: String,
    thresholdData: Object,
    canDelete: {
      type: Boolean,
      default: false
    },
    acl: Array,
    options: Array,
    thresholdMessage: String
  },
  setup(props, { emit }) {
    const { handleSubmit } = useForm({})
    const showErrorMessage = ref(false)

    const filledInPortfolios = computed(() => {
      const filled = props.portfolio.filter(portfolio => portfolio != null)

      return filled.length
    })

    const unprefixedRiskIndicator = ref(removePrefix(props.riskIndicator))

    const updatePosition = (event) => {
      emit('update:portfolio', event)
    }
    const hasDuplicatedThresholds = (thresholds) => {
      const thresholdValues = Object.values(thresholds);
      return thresholdValues.length !== new Set(thresholdValues).size
    }

    const onSubmit = handleSubmit(() => {
      if(hasDuplicatedThresholds(props.thresholdData.thresholdRange)){
        showErrorMessage.value = true
        emit('submit', showErrorMessage.value)
        return;
      }
      
      emit('submit')
    })

    defineRule("required", required)
    defineRule("minPortfolio", (value, _, { field }) => {
      if (value < MIN_PORTFOLIOS) {
        return `Should be at least two ${field}`
      }

      return true
    })

    defineRule("hyphenName", (value, _, {field}) => {
      if (value.includes(' - ')) {
        return `Invalid ${field}, don't use hyphen before space`
      }
      return true
    })

    return {
      filledInPortfolios,
      unprefixedRiskIndicator,
      showErrorMessage,

      onSubmit,
      updatePosition,
    }
  }
})
</script>
