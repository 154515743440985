export default class EquityExposure {
  constructor (data) {
    this.name = data.name
    this.value = data.value
  }

  get getName () {
    return this.name
  }

  get getValue () {
    return this.value
  }
}
