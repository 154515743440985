export default class Portfolio {
  constructor (data) {
    this.name = data.name
    this.description = data.description
    this.benchmark = data.benchmark
    this.owner = data.owner
    this.assets = data.assets
    this.equityExposure = null
    this.acl = data.acl
  }

  get getName () {
    return this.name
  }

  get getDescription () {
    return this.description
  }

  get getOwner () {
    return this.owner
  }

  get getAssets () {
    return this.assets
  }

  get getEquityExposure () {
    return this.equityExposure
  }

  get getACL () {
    return this.acl
  }

  setEquityExposure (equityExposure) {
    this.equityExposure = equityExposure
  }
}
