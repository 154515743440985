export default {
  getLogo(state) {
    return state.logo
  },
  getToken(state) {
    return state.auth.token
  },
  getRefreshToken(state) {
    return state.auth.refreshToken
  },

  getMenu (state) {
    return state.menu
  },
  getNonAdminMenu (state) {
    return state.nonAdminMenu
  },
  hasUserLoggedIn (state) {
    return state.auth.token !== null
  },

  getLoggedUser (state) {
    return state.auth.user
  },

  getLoggedUserInfo (state) {
    return state.loggedUser
  },

  hasLoggedUserInfo (state) {
    return state.loggedUser !==  null
  },

  isUserAdmin (state) {
    return state.loggedUser !== null &&
        state.loggedUser.roles.includes('admin')
  },

  getErrors (state) {
    return state.errors
  },
  hasErrors (state) {
    return state.errors != null
  }

}
