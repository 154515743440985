<template>
  <div class="box bg-theme-32 text-white">
    <div class="flex items-start px-5 pt-5">
      <div class="w-full flex flex-col lg:flex-row items-center">
        <div class="w-16 h-16 image-fit">
          <img
            class="rounded-full"
            :src="require(`@/assets/images/profile/${photo}`)"
          />
        </div>
        <div>
          <div class="lg:ml-4 text-center lg:text-left mt-3 lg:mt-0">
            <span class="font-medium">{{ name }}</span>
          </div>
          <div class="lg:ml-4 text-center lg:text-left mt-3 lg:mt-0">
            <span>{{ title }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center lg:text-left p-5">
      <div><slot /></div>
      <div
        class="flex items-center justify-center lg:justify-start text-gray-600 mt-5"
      >
        <MailIcon class="w-3 h-3 mr-2" /> {{ email }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    photo: String,
    name: String,
    title: String,
    email: String
  },
})
</script>