import RiskIndicator from "@/domain/models/RiskIndicator"
import RiskIndicatorItemFactory from "./RiskIndicatorItemFactory";

class RiskIndicatorFactory {
  get (data) {
    const dataRiskIndicator = {
      index_name: data.index.name,
      name: data.name,
      ticker: data.index.ticker,
      riskIndicators: data.indicators.map(item => RiskIndicatorItemFactory.get(item))
    }

    return new RiskIndicator(dataRiskIndicator)
  }
}


export default new RiskIndicatorFactory()
