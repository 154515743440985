<template>
  <div>
    <div class="intro-y text-3xl font-bold mt-6 mb-5">Team</div>
      <div class="text-xl">IT</div>
      <div class="grid grid-cols-12 gap-6 my-5">
        <Card
          class="intro-y col-span-12 md:col-span-6 lg:col-span-4"
          name="Fernando Berzal"
          title="Head of Technology"
          email="fberzal@accipartners.com"
          photo="fernando.png"
        >
          <p>
          Fernando Berzal, Ph.D., teaches Computational Intelligence and
          Information Systems Engineering at the Department of Computer Science
          and Artificial Intelligence at the University of Granada (Spain). He
          holds a Ph.D. in data mining and he was awarded the Computer Science
          Studies National First Prize by the Spanish Ministry of Education. He
          has been a visiting research scientist at the University of Illinois
          at Urbana-Champaign and he is a Senior Member of the Association for
          Computing Machinery (ACM). His research interests include Artificial
          Intelligence, complex networks, and deep learning, as well as
          model-driven software development, software design, and the
          application of data mining techniques to engineering problems. He has
          coauthored dozens of research papers on machine learning, data
          mining, complex networks, natural language processing, and software
          engineering. His research work has been featured in top research
          journals such as Machine Learning, Data Mining &amp; Knowledge
          Discovery, ACM Computing Surveys, IEEE Transactions on Data &amp;
          Knowledge Engineering, Complexity, IEEE Software, Communications of
          the ACM, Information Sciences, Knowledge &amp; Information Systems,
          Data &amp; Knowledge Engineering, Expert Systems with Applications,
          or the Journal of Computational Science, to name a few. He is also
          the author of a top-selling deep learning textbook in Spanish,
          currently used at multiple Spanish-speaking universities.
          </p>
        </Card>
        <Card
          class="intro-y col-span-12 md:col-span-6 lg:col-span-4"
          name="Juan Carlos Álvarez"
          title="Senior Technology Officer"
          email="jcalvarez@accipartners.com"
          photo="juan-carlos.png"
        >
          <p>
            Juan Carlos Álvarez holds a Master's Degree in Computer Engineering by the University of Granada.
          </p>
          <p>
            He has more than 20 years of experience working in software development, developing in disruptive
            technologies like Cloud Computing or Biometrics and leading teams for big multinational companies
            like Indra, Accenture, Amadeus, Telefonica, Michelin, Curtiss-Wright, Roche,...
          </p>
          <p>
            He has worked in many different industries (Aerospace, Security, Software Consulting, Telecomms,
            Public Administration, Finance, Risk Management, Online Betting, Pharmaceutical, Enterprise, ...).
            He has worked in key projects for the Ministries of Interior and Foreign Affairs of Spain like Spanish
            Electronic ID and the Biometric Passport and also in the FEGA project for the Ministry of Agriculture,
            Fisheries and Food.
          </p>
          <p>
            He has been working in Ireland, UK and Spain. He speaks Spanish and English.
          </p>
        </Card>
        <Card
            class="intro-y col-span-12 md:col-span-6 lg:col-span-4"
            name="Daniel Requejo"
            title="Senior Technology Officer"
            email="drequejo@accipartners.com"
            photo="dani-requejo.png"
        >
          <p>Daniel Requejo is a computer engineer with more than 25 years of experience in the IT industry.
            Daniel has worked as IT program manager for the last 20 years around the world managing complex IT
            transformation programs in different industries (finance, aeronautic, insurance, retail, mining, products).
            During his professional career, he has worked for consulting companies such as Arthur Andersen, Accenture,
            and Avanade (an Accenture and Microsoft join venture). He has worked in Spain, USA, Italy, France, Ireland,
            UK, and Australia.</p>

          <p>
            He has been interested in the financial markets and algorithmic trading since 2001, when he started investing
            in the stock market. He has experience in trading options, ETFs, and shares. He has an extensive knowledge
            in technical indicators, breath indicators, and market timing.
          </p>
          <p>
            He is an expert in developing and testing trading algorithms. He has developed automatic trading strategies
            for years based on mean revision, asset allocation, trend following, passive investment, and swing trading.
            He has managed funds with his algorithms using technical indicators and market timing techniques since 2020,
            obtaining outstanding results.
          </p>
          <p>
            He speaks Spanish and English.
          </p>
        </Card>

      </div>
      <div class="text-xl mt-14">Business Development</div>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <Card
          class="intro-y col-span-12 md:col-span-6 lg:col-span-4"
          name="Jesús Gil"
          email="jgil@accipartners.com"
          photo="jesus-gil.jpg"
        >
          <p>
          Jesus holds a Law Degree and has a Master's degree from the
          prestigious College of Europe in Bruges (Belgium) in European
          Politics and Economics, with a scholarship from the Spanish Ministry
          of Foreign Affairs. He speaks English, French and Spanish.
          </p>
          <p>
          He worked from 2001 to 2006 in international organizations such as
          the World Trade Organization in Geneva and the European Commission in
          Brussels. From 2006 to 2008 he worked as private banker in Citi
          Switzerland for LATAM region; from 2008 until 2018 he worked at UBS
          Wealth Management in Geneva as business developer covering MFO in
          the LATAM region and Southern Europe.
          </p>
        </Card>
        <Card
          class="intro-y col-span-12 md:col-span-6 lg:col-span-4"
          name="Antonio de la Oliva"
          email="adelaoliva@accipartners.com"
          photo="antonio-delaoliva.jpg"
        >
          <p>
          Antonio de la Oliva has worked at Alchemia EAFI during the last few
          years advising HNWI on their Fixed Income strategies. He has helped
          to design optimal asset allocation models depending on each
          client&apos;s profile. Prior to joining Alchemia, he worked in New
          York for BCP Securities LLC, a boutique investment bank focused on
          Emerging Market debt.
          </p>
          <p>
          He has also worked for KPMG. Antonio holds a degree in Business
          Asministration from Universidad Pontificia de Comillas ICADE (E2) and
          a Master in Accounting from Instituto de Estudios Bursátiles (IEB).
          </p>
          <p>
          He speaks Spanish and English.
          </p>
        </Card>
        <Card
          class="intro-y col-span-12 md:col-span-6 lg:col-span-4"
          name="Manuel Gil"
          email="mgil@accipartners.com"
          photo="manuel-gil.jpg"
        >
          <p>
          Manuel is a senior banker with over 20 years of experience in
          international DCM (Debt Capital Markets), with strong in depth
          knowledge of Emerging Markets fixed income. He started his career in
          2000 in New York as market analyst for the Trade Commission of Spain.
          From 2001 to 2006, he worked for Santander (Banesto) as proprietary
          trader, managing a 300 MM EUR book of LBO loans. In 2006 he joins BCP
          Securities LLC, an American boutique investment bank focused in EM
          bonds, as senior partner. He was a sales trader of High Yield and
          Emerging Markets bonds, covering European institutional clients. He
          is part of ACCI’s portfolio management team. He holds a degree in
          Economics from Universidad de Granada, postgraduate studies from
          University of Miami and FINRA Series 7, 24 and 65 Certificates.
          </p>
          <p>
          He speaks Spanish, English and French.
          </p>
        </Card>
      </div>

      <div class="text-xl mt-14">Head Asset Allocation</div>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <Card
          class="intro-y col-span-12 md:col-span-6 lg:col-span-4"
          name="Alberto García"
          email="albertogarcia@accipartners.com"
          photo="alberto-garcia.jpg"
        >
          <p>
            Alberto worked from 2005 to 2010 as a Senior Business Analyst for Ahorro Corporación and moved to UK where he worked as an Investment Analyst/Quantitative Developer for Collidr until 2015. From 2015 to November 2022, he worked for Santander Asset Management first in the Portfolio Construction and Risk Management Team to move internally to the Asset Allocation team where he spent the last 3 years.
          </p>
          <p>
            Alberto holds a Business & Administration Degree and has also obtained some of the most recognized Investment Certificates in Finance. During his career Alberto earned the CFA,CAIA, FRM, ESG CFA Investment Certificate and the IMC designation.
          </p>
          <p>
            He speaks English and Spanish.
          </p>
        </Card>
      </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Card from './Card.vue'

export default defineComponent({
  components: { Card },
})
</script>
<style lang="scss" scoped>
  p {
    @apply mb-3
  }
</style>
