export default class DataListItem {
    constructor (data) {
      this.category = data.category,
      this.description = data.description,
      this.last_update = data.last_update,
      this.since = data.since,
      this.ticker = data.ticker
    }
  
    get getCategory () {
      return this.category
    }
  
    get getDescription () {
      return this.description
    }
  
    get getLastUpdate () {
      return this.last_update
    }
  
    get getSince () {
      return this.since
    }
  
    get getTicker () {
      return this.ticker
    }
  }
  