import axios from '@/plugins/axios'
import dateHelper from '@/domain/services/dateHelper'
import StrategyKeyFactory from '@/domain/services/StrategyKeyFactory'
import StrategyPerformanceStatsFactory from '@/domain/services/StrategyPerformanceStatsFactory'
import StrategyFactory from '@/domain/services/StrategyFactory'
import StrategyDataFactory from '@/domain/services/StrategyDataFactory'
import PortfolioTradingFactory from '@/domain/services/PortfolioTradingFactory'

const URL_STRATEGY = '/api/strategies'
const URL_STRATEGY_LIST = `${URL_STRATEGY}/list`
const URL_STRATEGY_DATA = `${URL_STRATEGY}/data?name={name}&owner={owner}&from={from}&to={to}&series={series}`
const URL_SPECIFIC_STRATEGY = `${URL_STRATEGY}?name={name}&owner={owner}`
const URL_PERFORMANCE_STATS = `${URL_STRATEGY}/stats?name={name}&owner={owner}&from={from}&to={to}`
const URL_STRATEGY_TRADING = `${URL_STRATEGY}/trading`
const URL_STRATEGY_SHARING = `${URL_STRATEGY}/sharing`
const URL_STRATEGY_MIN_DATA = `${URL_STRATEGY}/data?name={name}&owner={owner}`


export function getStrategyData(name, owner, from, to, series) {
  const url = URL_STRATEGY_DATA
    .replace('{series}', series)
    .replace('{name}', encodeURIComponent(name))
    .replace('{owner}', encodeURIComponent(owner))
    .replace('{from}', dateHelper.format(from))
    .replace('{to}', dateHelper.format(to))
  return axios.get(url)
    .then(res => StrategyDataFactory.get(res.data))
    .catch(error => Promise.reject(error));
}

export function getStrategyPerformanceStats(name, owner, from, to) {
  const url = URL_PERFORMANCE_STATS
    .replace('{name}', encodeURIComponent(name))
    .replace('{owner}', encodeURIComponent(owner))
    .replace('{from}', dateHelper.format(from))
    .replace('{to}', dateHelper.format(to))
  return axios.get(url)
    .then(res => StrategyPerformanceStatsFactory.get(res.data))
    .catch(error => Promise.reject(error));
}

export function getStrategy(name, owner) {
  const url = URL_SPECIFIC_STRATEGY
    .replace('{owner}', encodeURIComponent(owner))
    .replace('{name}', encodeURIComponent(name))
  return axios.get(url)
    .then(res => {
      return StrategyFactory.get(res.data)
    })
    .catch(error => Promise.reject(error));
}

export function getStrategiesKeys() {
  return axios.get(URL_STRATEGY_LIST)
    .then(res => res.data.map(elem => StrategyKeyFactory.get(elem)))
    .catch(error => Promise.reject(error));
}

export function getStrategyMinDate(name, owner) {
  const url = URL_STRATEGY_MIN_DATA
    .replace('{name}', encodeURIComponent(name))
    .replace('{owner}', encodeURIComponent(owner))
  return axios.get(url)
    .then(res => StrategyDataFactory.get(res.data))
    .catch(error => Promise.reject(error));
}

export function createStrategy(strategy) {
  return axios.put(URL_STRATEGY, strategy)
    .then(res => res)
    .catch(error => Promise.reject(error));
}

export function deleteStrategy(name, owner) {
  const data = {
    name: name,
    owner: owner,
  }
  return axios.delete(URL_STRATEGY, { data: data })
    .then(res => res)
    .catch(error => Promise.reject(error));
}

export function updateStrategyTrading(name, owner, portfolio, assets) {
  const data = {
    name: name,
    owner: owner,
    portfolio: portfolio,
    assets: assets
  }
  return axios.post(URL_STRATEGY_TRADING, data)
    .then(res => PortfolioTradingFactory.get(res.data))
    .catch(error => Promise.reject(error));
}

export function updateAccessSharingStrategy (name, owner, acl) {
  const data = {
    name: name,
    owner: owner,
    acl: acl
  }
  return axios.put(URL_STRATEGY_SHARING, data)
      .then(res => res)
      .catch(error => Promise.reject(error))

}
