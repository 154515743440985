export default class DataCategory {
    constructor (data) {
      this.id = data.id,
      this.label = data.label
      this.description = data.description
    }
  
    get getId () {
      return this.id
    }
  
    get getLabel () {
      return this.label
    }
  
    get getDescription () {
      return this.description
    }
  }
  