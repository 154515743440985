export default function tomSelectGroupParser(list, groupName, tickerConverter) {
  return list.map(data => {
    const ticker = typeof(tickerConverter) === 'undefined' ? data.getTicker : tickerConverter(data.getTicker)
    return {
      group: groupName,
      ticker: ticker,
      description: data.getDescription,
    }
  })
}
