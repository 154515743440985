<template>
  <router-view />
</template>

<script>
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { updateTheme } from '@/domain/services/UpdateTheme';

export default defineComponent({
  setup() {
    const store = useStore()
    const username = store.getters.getLoggedUser

    if (username) {
      store.dispatch("getThemeColorPreference", username)
        .then(themeColor => {
          updateTheme(themeColor)
        })
      store.dispatch("getLogoPreference", username)
        .then(logo => {
          store.commit("setLogo", logo)
        })
     }
    return {
      store
    }
  }
})
</script>
