function start(date) {
  date.setHours(0, 0, 0, 0)
  return date
}

function end(date) {
  date.setHours(23, 59, 59, 999)
  return date
}

function getLastValidDay() {
  const d = new Date()
  d.setDate(d.getDate() - 1)
  return d
}

export default {
  lastValidDay() {
    return getLastValidDay()
  },
  day() {
    const s = getLastValidDay()
    const e = getLastValidDay()
    return [start(s), end(e)]
  },
  month() {
    const s = new Date()
    s.setMonth(s.getMonth() - 1)
    const e = getLastValidDay()
    return [start(s), end(e)]
  },
  ytd() {
    const s = new Date()
    s.setMonth(0, 1)
    const e = getLastValidDay()
    return [start(s), end(e)]
  },
  year() {
    const s = new Date()
    s.setMonth(s.getMonth() - 12)
    const e = getLastValidDay()
    return [start(s), end(e)]
  },
  year2() {
    const s = new Date()
    s.setMonth(s.getMonth() - 24)
    const e = getLastValidDay()
    return [start(s), end(e)]
  },
  year3() {
    const s = new Date()
    s.setMonth(s.getMonth() - 36)
    const e = getLastValidDay()
    return [start(s), end(e)]
  },
  year5() {
    const s = new Date()
    s.setMonth(s.getMonth() - 60)
    const e = getLastValidDay()
    return [start(s), end(e)]
  },
  max() {
    const s = new Date(0)
    const e = getLastValidDay()
    return [start(s), end(e)]
  },
  format(d) {
    return d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, '0') + "-" + d.getDate().toString().padStart(2, '0')
  }
}
