<template>
  <div>
        <Tippy
          tag="a"
          content="Sharing"
          data-toggle="modal"
          data-target="#change-permission-modal"
          class="btn border-theme-36 p-2 mb-5 sm:mb-0 opacity-50"
        >
          <Share2Icon size="16" class="custom-icon" fill="white" />
        </Tippy>
  </div>
  <div class="modal" id="change-permission-modal">
    <div class="modal__content left-1/2 top-1/2 rounded-2xl max-w-md flex flex-col absolute w-64 bg-white">
      <div class="py-5 px-8 text-m text-left text-black">
        <ACL
          :key="acl"
          :acl="acl"
          @changeRoles="updateACL"
          @onCancelButton="closeModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import ACL from '@/components/acl/Main';

export default defineComponent({
  props: {
    acl: Array
  },
  emits: ['updateACL'],
  components: { ACL },
  setup(_, { emit }) {
    const showModal = ref(false)

    const onShowModal = () => {
      showModal.value = !showModal.value
    }

    const updateACL = (acl) => {
      cash('#change-permission-modal').modal('hide')
      emit('updateACL', acl)

    }

    const closeModal = () => {
      cash('#change-permission-modal').modal('hide')
    }


    return {
      showModal,

      onShowModal,
      updateACL,
      closeModal
    }

  }
})
</script>

<style lang="scss" scoped>
.modal__content {
  width: 500px;
  transform: translate(-50%, -50%);
}
.custom-icon {
  stroke: black;
  stroke-width: 3px;
}
</style>
