<template>
  <div class="py-6">
    <div v-if="store.getters.hasErrors">
      <Error
        :errors="store.getters.getErrors"
      />
    </div>
    <div v-if="!hasIndicators" class="bg-white my-4 rounded-lg">
      <div class="text-center p-10">
        There are no indicators created
      </div>
    </div>
    <div
      v-else
      :id="indicator.getName"
      class="accordion border-b-2 mb-5 pb-3"
      v-for="(indicator, index) in indicators"
      :key="indicator.getName"
    >
      <div class="accordion-item">
        <button
          :id="'button' + index"
          class="grid accordion-button" :class="{ collapsed: index > 0}"
          :aria-expanded="index == 0"
          @click="displayedIndicators[indicator.getName] = true"
        >
          <span class="flex min-w-0">
            <span class="flex items-center text-3xl font-bold overflow-ellipsis whitespace-nowrap overflow-hidden">
              <div
                class="mr-2"
              >
              {{ indicator.ind }}
              </div>
              <div
                  class="rounded-full px-2 py-0.5 text-xs inline-block text-white font-medium mr-2"
                  :class="{'bg-theme-10': indicator.diff > 0, 'bg-theme-24': indicator.diff < 0 }"
              >
                {{ format(indicator.diff) }}
                  <ChevronUpIcon  v-if="indicator.diff > 0" class="w-4 h-4 ml-0.5" />
                  <ChevronDownIcon v-else class="w-4 h-4 ml-0.5" />
              </div>
              {{ indicator.getName }}
            </span>
            <ChevronDownIcon class="chevron w-8 h-8 opacity-50 flex-shrink-0" />
          </span>
        </button>
        <RiskIndicatorData
          class="accordion-collapse collapse hide" :class="{ show: index == 0 }"
          v-if="displayedIndicators[indicator.getName] || index == 0"
          :indicator="indicator"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useStore } from "vuex";
import { format } from '@/domain/services/numberHelper'
import RiskIndicatorData from './RiskIndicatorData.vue';
import Error from '@/components/error/Main.vue'

export default defineComponent({
  components: {
    RiskIndicatorData,
    Error
  },
  setup() {
    const store = useStore()
    const indicators = ref([])
    const hasIndicators = ref(true)
    const displayedIndicators = ref({})

    const doSearch = () => {
      hasIndicators.value = true
      indicators.value = []
      displayedIndicators.value = {}
      store.dispatch("listRiskIndicators")
        .then(res => {
          hasIndicators.value = res.length > 0
          indicators.value = res
          indicators.value.forEach(indicator => {
            displayedIndicators.value[indicator.getName] = false
            indicator.getRiskIndicators.forEach(subIndicator => {
              const payload = {
                ticker: subIndicator.ticker
              }
              store.dispatch('getRiskIndicatorsSubIndicators', payload)
                  .then(res => {
                    indicator.ind = res.ind
                    indicator.diff = res.diff
                  })
            })
          })
        })
    }

    doSearch()

    return {
      format,
      indicators,
      hasIndicators,
      displayedIndicators,
      store
    }
  }
})
</script>

<style lang="scss" scoped>
  button.accordion-button[aria-expanded=false] {
    .chevron {
      @apply rotate-180
    }
  }
</style>
