<template>
  <div class="mt-1 flex gap-x-3 items-stretch justify-between">
    <span>{{ name }}</span>
    <Tippy :content="title" :options="{ trigger: 'click mouseenter' }" class="flex items-center">
      <div class="indicator w-16 h-1 relative rounded-full opacity-70">
        <span
          class="absolute w-1 h-1 bg-white border rounded-full"
          :style="{ left: ((ind + 1) / 2 * (100 - gap)) + '%' }" />
      </div>
    </Tippy>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    name: String,
    ind: Number
  },
  setup(props) {
    const title = computed(() => {
      return props.name + ': ' + props.ind
    })

    return {
      title,
      gap: 4
    }
  }
})

</script>
<style lang="scss" scoped>
  .indicator {
    background-image: linear-gradient(to right, rgb(7,26,81), rgb(96, 207, 226) 99%);
  }
</style>
