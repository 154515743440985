export const ITEMS_PER_PAGE = 20

export default class DataList {
    constructor (data) {
      this.page = data.page,
      this.pageSize = data.pageSize,
      this.totalRows = data.totalRows,
      this.data = data.data
    }

    get getPage () {
      return this.page
    }

    get getPageSize () {
      return this.pageSize
    }

    get getTotalRows () {
      return this.totalRows
    }

    get getData () {
      return this.data
    }

    get getTotalPages () {
      return Math.ceil(this.getTotalRows / this.getPageSize)
    }
  }
