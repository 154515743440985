<template>
  <div>
    <Edit2Icon class="w-4 h-4 mr-1 cursor-pointer" @click="params.edited(params.rowIndex)" />
    <Trash2Icon class="w-4 h-4 mr-1 cursor-pointer" @click="params.deleted(params.rowIndex)" />
  </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: {
    params: Object,
  }
})
</script>
