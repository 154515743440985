<template>
  <div class="flex flex-col w-full items-center lg:flex-row lg:items-start">
    <div class="graphic flex flex-col flex-grow w-full lg:w-96 mr-12">
      <div class="top">
        <VueEcharts
          @datazoom="sync($event)"
          @restore="restore()"
          :option="{
            grid: {
              top: 20,
              bottom: 20,
            },
            xAxis: {
              type: 'time',
              boundaryGap: false,
              splitLine: {
                show: true,
              },
            },
            yAxis: {
              type: 'value',
              boundaryGap: ['1%', '1%'],
              splitNumber: 10,
            },
            legend: {
              data: [strategyName, data.getBenchmarkDescription],
            },
            toolbox: {
              show: true,
              orient: 'vertical',
              feature: {
                dataZoom: {
                  yAxisIndex: 'none',
                  icon: {
                    zoom: 'path://',
                    back: 'path://',
                  },
                },
                restore: {},
              },
            },
            series: [
              {
                name: strategyName,
                type: 'line',
                showSymbol: false,
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: 'rgba(96, 207, 226,10)',
                      },
                      {
                        offset: 1,
                        color: '#182a5c',
                      },
                    ],
                    global: false, // default is false
                  },
                },
                zlevel: 1,
                z: 1,
                itemStyle: {
                  color: 'rgba(96, 207, 226,10)',
                },
                lineStyle: {
                  color: '#b7dffb00',
                },
                data: graphics.top.xData.map((x, index) => [
                  graphics.top.yData[index],
                  x,
                ]),
              },
              {
                name: graphicData.getBenchmarkDescription,
                type: 'line',
                showSymbol: false,
                zlevel: 1,
                z: 1,
                itemStyle: {
                  color: '#ff000090',
                },
                lineStyle: {
                  color: '#ff0000',
                  width: 1,
                },
                data: graphics.top.benchmark.map((x, index) => [
                  graphics.top.yData[index],
                  x,
                ]),
              },
              {
                name: 'Risk Indicator',
                type: 'line',
                symbol: 'none',
                zlevel: 1,
                z: 1,
                itemStyle: {
                  color: '#fcfc4200',
                },
                lineStyle: {
                  width: 0,
                },
                data: graphics.bottom.xData.map((x, index) => [
                  graphics.bottom.yData[index],
                  x,
                ]),
              },
            ],
            tooltip: {
              trigger: 'axis',
              formatter: function(params) {
                var lpad = (value) => {
                  value = value.toString();
                  if (value.length > 1) {
                    return value;
                  }
                  return '0' + value;
                };
                var d = new Date(params[0].data[0]);
                var ret =
                  d.getFullYear() +
                  '-' +
                  lpad(1 + d.getMonth()) +
                  '-' +
                  lpad(d.getDate());
                ret += '<br /><table cellpadding=3>{data}</table>';
                var cont = params
                  .map((param) => {
                    var ret = '<tr>';
                    ret += `<td><div style='border-radius: 50%; background-color: ${param.color}; width: 10px; height: 10px'></div></td>`;
                    ret += `<td>${param.seriesName}</td>`;
                    ret += '<th align=right>' + format(param.data[1]) + '</th>';
                    ret += '<tr>';
                    return ret;
                  })
                  .join('');
                return ret.replace('{data}', cont);
              },
            },
          }"
          style="width: 100%; height: 400px"
          ref="top_chart"
        />
      </div>
      <div class="top mt-10">
        <VueEcharts
          @datazoom="sync($event)"
          @restore="restore()"
          :option="{
            legend: {
              data: ['Indicator', 'Threshold'],
            },
            grid: {
              top: 20,
              bottom: 50,
            },
            xAxis: {
              type: 'time',
              boundaryGap: false,
              splitLine: {
                show: true,
              },
            },
            yAxis: {
              type: 'value',
              boundaryGap: [0, 0],
              min: -1,
              splitNumber: 5,
            },
            series: [
              {
                name: 'Indicator',
                type: 'line',
                showSymbol: false,
                zlevel: 1,
                z: 1,
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: 'rgba(96, 207, 226,10)',
                      },
                      {
                        offset: 1,
                        color: '#182a5c',
                      },
                    ],
                  },
                },
                itemStyle: {
                  color: '#60CFE2'
                },
                lineStyle: {
                  color: '#b7dffb00',
                },
                data: graphics.bottom.xData.map((x, index) => [
                  graphics.bottom.yData[index],
                  x,
                ]),
              },
              ...xExtraLines,
            ],
            tooltip: {
              trigger: 'axis',
              formatter: function(params) {
                var lpad = (value) => {
                  value = value.toString();
                  if (value.length > 1) {
                    return value;
                  }
                  return '0' + value;
                };
                var d = new Date(params[0].data[0]);
                var ret =
                  d.getFullYear() +
                  '-' +
                  lpad(1 + d.getMonth()) +
                  '-' +
                  lpad(d.getDate());
                ret += '<br /><table cellpadding=3>{data}</table>';
                var cont = params
                  .map((param) => {
                    var ret = '<tr>';
                    ret += `<td><div style='border-radius: 50%; background-color: ${param.color}; width: 10px; height: 10px'></div></td>`;
                    ret += `<td>${param.seriesName}</td>`;
                    ret += '<th align=right>' + format(param.data[1]) + '</th>';
                    ret += '<tr>';
                    return ret;
                  })
                  .join('');
                return ret.replace('{data}', cont);
              },
            },
            toolbox: {
              show: true,
              orient: 'vertical',
              feature: {
                dataZoom: {
                  yAxisIndex: 'none',
                  icon: {
                    zoom: 'path://',
                    back: 'path://',
                  },
                },
                restore: {},
              },
            },
          }"
          style="width: 100%; height: 250px"
          ref="bottom_chart"
        />
      </div>
    </div>
    <div>
      <ThreeColumnsTable
        :data="topData"
        :topLabels="['', 'Portfolio', 'Benchmark']"
        :keys="['name', 'portfolio', 'benchmark']"
      />
      <div>
        <div class="text-sm mt-4 flex gap-4 items-center cursor-pointer">
          <span class="text-right">{{ tooltipMessage }}</span>
          <Tippy
            tag="a"
            data-set="false"
            href="javascript:;"
            content="Loading..."
            class="tooltip"
            :options="{ onShow: toggleTooltip }"
          >
            <AlertCircleIcon />
          </Tippy>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import ThreeColumnsTable from '@/components/three-columns-table/Main';
import { VueEcharts } from 'vue3-echarts';
import { useStore } from 'vuex';
import { format } from '@/domain/services/numberHelper';

export default defineComponent({
  emits: ['changeSerie'],
  components: {
    VueEcharts,
    ThreeColumnsTable,
  },
  props: {
    data: Object,
    graphicData: Object,
    strategyName: String,
  },
  setup(props) {
    const store = useStore();

    const lock = ref(false);
    const top_chart = ref();
    const bottom_chart = ref();
    const tooltipMessage = ref('');

    onMounted(() => {
      top_chart.value.chart.on('finished', function() {
        applyAutoZoom();
      });
      bottom_chart.value.chart.on('finished', function() {
        applyAutoZoom();
      });
    });

    const graphics = {
      top: {
        xData: props.graphicData.getData.map(
          (value) => Math.round(value * 1000) / 1000
        ),
        yData: props.graphicData.getIndex.map((value) => new Date(value)),
        benchmark: props.graphicData.getBenchmark.map(
          (value) => Math.round(value * 1000) / 1000
        ),
      },
      bottom: {
        xData: props.graphicData.getIndicator.map(
          (value) => Math.round(value * 1000) / 1000
        ),
        yData: props.graphicData.getIndex.map((value) => new Date(value)),
      },
    };

    store
      .dispatch('getRiskIndicatorsSubIndicators', {
        ticker: props.graphicData.getStrategyIndicator,
      })
      .then((indicators) => {
        tooltipMessage.value = indicators.message;
      });

    const applyAutoZoom = () => {
      top_chart.value.dispatchAction({
        type: 'takeGlobalCursor',
        key: 'dataZoomSelect',
        dataZoomSelectActive: true,
      });
      bottom_chart.value.dispatchAction({
        type: 'takeGlobalCursor',
        key: 'dataZoomSelect',
        dataZoomSelectActive: true,
      });
    };

    const topData = props.data.getBenchmark.map((benchmark) => {
      const key = benchmark.getKey;
      const portfolio = props.data.getPortfolio.find(
        (portfolio) => portfolio.getKey === key
      );
      return {
        name: benchmark.getName,
        benchmark: benchmark.getValue,
        portfolio: portfolio.getValue,
      };
    });

    const restore = () => {
      if (lock.value) return;
      lock.value = true;
      top_chart.value.dispatchAction({
        type: 'restore',
      });
      bottom_chart.value.dispatchAction({
        type: 'restore',
      });
      lock.value = false;
      applyAutoZoom();
    };

    const sync = (data) => {
      if (lock.value) return;
      lock.value = true;
      if (data.batch && data.batch.length > 0) {
        const batch = data.batch;

        top_chart.value.dispatchAction({
          type: 'dataZoom',
          dataZoomIndex: 0,
          start: batch[0].start,
          end: batch[0].end,
          startValue: batch[0].startValue,
          endValue: batch[0].endValue,
        });

        bottom_chart.value.dispatchAction({
          type: 'dataZoom',
          dataZoomIndex: 0,
          start: batch[0].start,
          end: batch[0].end,
          startValue: batch[0].startValue,
          endValue: batch[0].endValue,
        });
      }
      lock.value = false;
    };

    const xExtraLines = [];

    Object.values(props.graphicData.getThresholds).forEach(
      (threshold, index) => {
        xExtraLines.push({
          name: 'Threshold',
          type: 'line',
          showSymbol: false,
          zlevel: 2 + index,
          z: 2 + index,
          itemStyle: {
            color: '#ff000090',
          },
          lineStyle: {
            color: '#ff0000',
            width: 1,
          },
          data: Object.values(threshold).map((x, index) => [
            graphics.bottom.yData[index],
            x,
          ]),
        });
      }
    );

    const toggleTooltip = (tippy) => {
      if (tippy.reference.getAttribute('data-set') === 'false') {
        store.dispatch('getHelpRiskIndicators').then((res) => {
          tippy.setProps({ content: res.html, allowHTML: true });
          tippy.reference.setAttribute('data-set', 'true');
        });
      }
    };

    return {
      graphics,
      topData,
      top_chart,
      bottom_chart,
      xExtraLines,
      tooltipMessage,
      restore,
      sync,
      toggleTooltip,
      format,
    };
  },
});
</script>
