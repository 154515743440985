<template>
  <div class="alert alert-danger alert-dismissible show flex items-center mb-2">
    <AlertCircleIcon class="w-6 h-6 mr-2" />
    {{ getError }}
    <button
      type="button"
      class="btn-close text-white"
      aria-label="Close"
      @click="dismiss"
    >
      <XIcon class="w-4 h-4" />
    </button>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    errors: Object || String,
  },
  emits: ['dismiss'],

  setup(props, { emit }) {
    const store = useStore();
    const dismiss = () => {
      store.commit('setErrors', null);
      emit('dismiss');
    };

    const getError = computed(() => {
      return props.errors.status && props.errors.status === 500
        ? props.errors.statusText
        : props.errors.error;
    });

    return {
      getError,
      dismiss,
    };
  },
});
</script>

<style scoped></style>
