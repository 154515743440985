<template>
  <div class="container sm:px-10">
    <div class="block md:grid grid-cols-2 gap-4">
      <!-- BEGIN: Login Info -->
      <div class="hidden md:flex flex-col min-h-screen">
        <div class="my-auto">
          <Particles
              id="tsparticles"
              :options='{
              "style": {
                width: "50%",
              },
              "background":{
                "color":{
                    "value":"#142e72"
                }
              },
              "pauseOnBlur": true,
              "fpsLimit": 60,
              "interactivity": {
                "detectsOn":"window",
                "events":{
                    "onClick":{
                      "enable":true,
                      "mode":"push"
                    },
                    "onHover":{
                      "enable":true,
                      "mode":"repulse"
                    },
                    "resize":false
                },
              },
              "particles":{
                "color":{
                    "value":"#ffffff"
                },
                "links":{
                    "color":"#ffffff",
                    "distance":150,
                    "enable":true,
                    "opacity":0.5,
                    "width":1
                },
                "collisions":{
                    "enable": false
                },
                "move":{
                    "direction": "none",
                    "enable": true,
                    "outModes": "bounce",
                    "random": false,
                    "speed": { min: 0, max: 1 }, // modify these if you want to change the particles speed!
                    "straight": true,
                    "gravity": { enable: false, maxSpeed: 6, acceleration: 0 },
                    "attract": { enable: false }
                },
                "number":{
                    "density":{
                      "enable":true,
                      "value_area":800
                    },
                    "value":80
                },
                "opacity":{
                    "value":0.5
                },
                "shape":{
                    "type":"circle"
                },
                "size":{
                    "random":true,
                    "value":5
                }
              },
              "detectRetina":true
          }'
          />
          <div
              class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
          >
            Managing risk and creating alpha
          </div>
          <div
              class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500"
          >
            Professional Portfolio Management Software for Financial Advisors and External Asset Managers
          </div>
        </div>
      </div>
      <!-- END: Login Info -->
      <!-- BEGIN: Login Form -->
      <div class="h-screen md:h-auto flex py-5 md:py-0 my-0">
        <slot />
      </div>
      <!-- END: Login Form -->
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive} from 'vue'
import cash from "cash-dom/dist/cash"

export default defineComponent({
  components: { },
  setup() {
    onMounted(() => {
      cash('body')
          .removeClass('main')
          .removeClass('error-page')
          .addClass('login')
    })
  }
})
</script>
