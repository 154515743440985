<template>
  <div class="flex flex-col w-full items-center lg:flex-row lg:items-start">
    <div class="graphic flex flex-col flex-grow w-full lg:w-96">
      <div class="top">
        <VueEcharts
          :option="{
            legend: {
              data: [data.getName, data.getBenchmarkDescription],
            },
            grid: {
              bottom: 20,
            },
            xAxis: {
              type: 'time',
              boundaryGap: false,
              splitLine: {
                show: true,
              },
            },
            yAxis: {
              type: 'value',
              boundaryGap: ['1%', '1%'],
              splitNumber: 10,
            },
            series: [
              {
                name: data.getName,
                type: 'line',
                showSymbol: false,
                areaStyle: {},
                zlevel: 1,
                z: 1,
                itemStyle: {
                  color: 'rgba(96, 207, 226, 10)',
                },
                lineStyle: {
                  color: '#b7dffb00',
                },
                data: graphic.xData.map((x, index) => [
                  graphic.yData[index],
                  x,
                ]),
              },
              {
                name: data.getBenchmarkDescription,
                type: 'line',
                showSymbol: false,
                zlevel: 1,
                z: 1,
                itemStyle: {
                  color: '#ff000090',
                },
                lineStyle: {
                  color: '#ff0000ff',
                  width: 1,
                },
                data: graphic.benchmark.map((x, index) => [
                  graphic.yData[index],
                  x,
                ]),
              },
            ],
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(96, 207, 226,10)',
                  },
                  {
                    offset: 1,
                    color: '#182a5c',
                  },
                ],
                global: false, // default is false
              },
            },
            toolbox: {
              show: true,
              orient: 'vertical',
              feature: {
                dataZoom: {
                  yAxisIndex: 'none',
                  icon: {
                    zoom: 'path://',
                    back: 'path://',
                  },
                },
                restore: {},
              },
            },
            tooltip: {
              trigger: 'axis',
              formatter: function(params) {
                var lpad = (value) => {
                  value = value.toString();
                  if (value.length > 1) {
                    return value;
                  }
                  return '0' + value;
                };
                var d = new Date(params[0].data[0]);
                var ret =
                  d.getFullYear() +
                  '-' +
                  lpad(1 + d.getMonth()) +
                  '-' +
                  lpad(d.getDate());
                ret += '<br /><table cellpadding=3>{data}</table>';
                var cont = params
                  .map((param) => {
                    var ret = '<tr>';
                    ret += `<td><div style='border-radius: 50%; background-color: ${param.color}; width: 10px; height: 10px'></div></td>`;
                    ret += `<td>${param.seriesName}</td>`;
                    ret += '<th align=right>' + format(param.data[1]) + '</th>';
                    ret += '<tr>';
                    return ret;
                  })
                  .join('');
                return ret.replace('{data}', cont);
              },
            },
          }"
          style="width: 100%; height: 400px"
          ref="top_chart"
        />
      </div>
    </div>
    <div>
      <ThreeColumnsTable
        :data="topData"
        :topLabels="['', 'Portfolio', 'Benchmark']"
        :keys="['name', 'portfolio', 'benchmark']"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import ThreeColumnsTable from '@/components/three-columns-table/Main';
import { VueEcharts } from 'vue3-echarts';
import { useStore } from 'vuex';
import { format } from '@/domain/services/numberHelper';

export default defineComponent({
  components: {
    VueEcharts,
    ThreeColumnsTable,
  },
  props: {
    data: Object,
    stats: Object,
  },
  setup(props) {
    const store = useStore();
    const top_chart = ref();
    const tooltipMessage = ref('');

    const graphic = {
      xData: props.data.getData.map((value) => Math.round(value * 1000) / 1000),
      yData: props.data.getIndex.map((value) => new Date(value)),
      benchmark: props.data.getBenchmark.map(
        (value) => Math.round(value * 1000) / 1000
      ),
    };

    onMounted(() => {
      top_chart.value.chart.on('finished', function() {
        applyAutoZoom();
      });
    });

    const applyAutoZoom = () => {
      top_chart.value.dispatchAction({
        type: 'takeGlobalCursor',
        key: 'dataZoomSelect',
        dataZoomSelectActive: true,
      });
    };

    const topData = props.stats.getBenchmark.map((benchmark) => {
      const key = benchmark.getKey;
      const portfolio = props.stats.getPortfolio.find(
        (portfolio) => portfolio.getKey === key
      );
      return {
        name: benchmark.getName,
        benchmark: benchmark.getValue,
        portfolio: portfolio.getValue,
      };
    });

    const toggleTooltip = (tippy) => {
      if (tippy.reference.getAttribute('data-set') === 'false') {
        store.dispatch('getHelpRiskIndicators').then((res) => {
          tippy.setProps({ content: res.html, allowHTML: true });
          tippy.reference.setAttribute('data-set', 'true');
        });
      }
    };

    return {
      graphic,
      topData,
      top_chart,
      tooltipMessage,
      toggleTooltip,
      format,
    };
  },
});
</script>
