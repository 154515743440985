class ACLTransformer {
    get(owner, name, acl) {
        return {
            name: name,
            owner: owner,
            acl: acl
        }
    }
}

export default new ACLTransformer()
