const thresholdsTypes = [{
    type: 'financial.strategies.threshold.DynamicThresholdStrategy',
    auto: true,
  }, {
    type: 'financial.strategies.threshold.ThresholdStrategy',
    auto: false,
  }]

export function isAutomaticThresholdFromType(type) {
  const typeObject = thresholdsTypes.find(currentTypeObject => currentTypeObject.type === type)
  return typeObject.auto
}

export function getTypeFromAutomaticCondition(isAutomatic) {
  const typeObject = thresholdsTypes.find(currentTypeObject => currentTypeObject.auto === isAutomatic)
  return typeObject.type
}
