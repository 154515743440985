<template>
  <div class="py-6">
    <div v-if="store.getters.hasErrors">
      <Error :errors="store.getters.getErrors" @dismiss="creationError = null"/>
    </div>
    <div>
      <div class="text-3xl font-bold">
        Modify portfolio
      </div>
      <div class="mt-2">
        Fill in this form to modify the selected portfolio
      </div>
    </div>
    <div class="w-full xl:w-3/4 2xl:w-1/2 bg-white mt-2 p-4 rounded">
      <CreateStandAlonePosition
        v-if="loaded"
        :portfolio="portfolio"
        canDelete
        @created="onPositionCreated"
        @closed="onPositionClosed"
        @delete="onDelete"
      />
    </div>
    <div class="modal" id="delete-confirm-dialog" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-10 text-center">
            <div class="m-4 text-theme-24 text-xl">Attention!</div>
            <div class="m-4">Are you sure you want to delete this portfolio?</div>
            <div class="flex justify-center gap-2">
              <button type="button" class="btn" @click="onCloseDeleteConfirmDialog">Cancel</button>
              <button type="button" class="btn btn-danger" @click="onRealDelete">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import CreateStandAlonePosition from '@/components/create-stand-alone-position/Main.vue'
import { useRoute, useRouter } from 'vue-router'
import PortfolioCreateTransformer from '@/domain/services/PortfolioCreateTransformer'
import Error from '@/components/error/Main'

export default defineComponent({
  components: { CreateStandAlonePosition, Error },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const portfolio = ref()
    const creationError = ref()
    const loaded = ref(false)

    const portfolioName = route.query.name

    const onPositionCreated = ((form) => {
      const owner = store.getters.getLoggedUser
      store.dispatch("createPortfolio", PortfolioCreateTransformer.get(form, owner))
        .then(() => {
          router.push({ name: 'portfolios', query: { name: form.name } })
        })
        .catch((error) => {
          creationError.value = error.response.data.error
        })
    })

    const onPositionClosed = () => {
      router.push({ name: 'portfolios' })
    }

    const portfolioData = {
      name: portfolioName,
      owner: store.getters.getLoggedUser
    }
    store.dispatch('getPortfolio', portfolioData)
      .then(retrievedPortfolio => {
        portfolio.value = retrievedPortfolio
        loaded.value = true
      })
      .catch((error) => {
        creationError.value = error.response.data.error
        onCloseDeleteConfirmDialog()
    })

    const onDelete = () => {
      cash("#delete-confirm-dialog").modal('show')
    }

    const onCloseDeleteConfirmDialog = () => {
      cash("#delete-confirm-dialog").modal('hide')
    }

    const onRealDelete = () => {
      const data = {
        name: portfolioName,
        owner: store.getters.getLoggedUser
      }
      store.dispatch("deletePortfolio", data)
        .then(() => {
          router.push({ name: 'portfolios' })
            .then(() => {
              onCloseDeleteConfirmDialog()
            })
        })
        .catch((error) => {
          store.commit('setErrors', error.response.data)
          onCloseDeleteConfirmDialog()
        })
    }

    return {
      portfolio,
      loaded,
      creationError,
      store,

      onPositionClosed,
      onPositionCreated,
      onCloseDeleteConfirmDialog,
      onRealDelete,
      onDelete,
    }
  }
})
</script>
