<template>
  <div class="theme-colors flex gap-2 p-2">
    <div
      class="theme-colors__wrapper"
      :class="{ selected: modelValue === color.name }"
      v-for="color in colors"
      :key="color.name"
    >
      <div
        class="theme-colors__wrapper--item w-6 h-6 cursor-pointer"
        :style="{ backgroundColor: color.color }"
        @click="updateColor(color.name)"
      />
    </div>
  </div>
</template>

<script>
import { updateTheme } from '@/domain/services/UpdateTheme';
import { defineComponent } from 'vue'

export default defineComponent({
  emits: ["update:modelValue", "changeColor"],
  props: {
    modelValue: String,
  },
  setup(_, { emit }) {
    const colors = [
      { color: "#1C3271", name: "" },
      { color: "#202020", name: "black" },
      { color: "#B92918", name: "red" },
      { color: "#315841", name: "green" },
      { color: "#484848", name: "gray" },
      { color: "#9B6B60", name: "pink" },
      { color: "#3D727E", name: "turquoise" },
      { color: "#362400", name: "brown" },
    ];

    const updateColor = (themeName) => {
      updateTheme(themeName);
      emit("update:modelValue", themeName)
      emit("changeColor")
    }

    return {
      colors,

      updateColor
    }
  },
})
</script>

<style lang="scss" scoped>
  .theme-colors {
    &__wrapper {
      @apply border-transparent border-2;
      &.selected {
        @apply border-black;
      }
      &--item {
        @apply m-0.5;
      }
    }
  }
</style>
