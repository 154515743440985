<template>
  <Shell>
    <!-- BEGIN: Login Form -->
    <div class="h-screen h-auto flex py-0 md:my-0 w-full">
      <div
        class="my-auto mx-auto ml-0 md:ml-10 lg:ml-20 bg-white px-5 sm:px-8 py-8 md:p-0 w-full rounded-md shadow-md lg:shadow-none"
      >
        <h2 class="intro-x font-bold text-2xl xl:text-3xl text-left">
          Reset Password
        </h2>
        <div v-if="sent">
          <div class="py-4">
            We have sent you an email with a link to reset your password
          </div>
          <router-link class="btn btn-primary py-3 px-4 block sm:w-32 align-top" :to="{ name: 'login' }">Understood</router-link>
        </div>
        <form v-else @submit="onSubmit">
          <div class="intro-x mt-8">
            <Field name="user" label="User" rules="required" v-slot="{ field, meta }">
              <input
                  type="text"
                  :class="{ 'has-error': meta.validated && !meta.valid }"
                  class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                  placeholder="User"
                  v-model="user"
                  v-bind="field"
              />
              <ErrorMessage name="user" />
            </Field>
          </div>
          <div class="intro-x mt-5 xl:mt-8 text-center flex justify-between w-full flex flex-col sm:flex-row">
            <router-link
                :to="{ name: 'login' }"
                class="btn btn-outline-secondary py-3 px-4 block mb-4 sm:mb-0 sm:w-32 align-top"
            >
              Back
            </router-link>
            <button
                class="btn btn-primary py-3 px-4 block sm:w-32 align-top"
            >
              Reset
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- END: Login Form -->
  </Shell>
</template>

<script>
import {defineComponent, ref} from 'vue'
import { useForm, Field, ErrorMessage, defineRule } from "vee-validate"
import { required } from "@vee-validate/rules"
import { useRouter } from 'vue-router'
import { useStore } from "vuex"
import Shell from "@/components/templates/Login/Shell"

export default defineComponent({
  components: { Field, ErrorMessage, Shell },
  setup() {
    const store = useStore()
    const router = useRouter()
    const user = ref('')
    const sent = ref(false)
    const { handleSubmit } = useForm({})

    const onSubmit = handleSubmit(values => {
      store.dispatch('resetPassword', user.value)
          .then(() => {
            sent.value = true
          })
          .catch(() => {
            console.log('Invalid login')
          })
          .finally(() =>{
            sent.value = true
          })
    })

    defineRule("required", required)

    return {
      user,
      sent,

      onSubmit
    }
  }
})
</script>
