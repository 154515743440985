import DataCategoryGraphic from "@/domain/models/DataCategoryGraphic"

class DataCategoryGraphicFactory {
  get (data) {
    const graphicsData = []
    for (let i=0; i < data.data.length; i++) {
      graphicsData.push({
        label: (new Date(data.index[i])).getTime(),
        value: data.data[i]
      })
    }
    const dataCategoryGraphic = {
      available: data.available,
      from: data.from,
      to: data.to,
      name: data.name,
      totalPoints: data.total_points,
      data: graphicsData
    }

    return new DataCategoryGraphic(dataCategoryGraphic)
  }
}

export default new DataCategoryGraphicFactory()
