<template>
  <div class="py-6">
    <div v-if="store.getters.hasErrors">
      <Error
        :errors="store.getters.getErrors"
        @dismiss="creationError = null"
      />
    </div>
    <div class="flex flex-col lg:flex-row justify-between">
      <div
        class="flex flex-col items-stretch gap-4 lg:flex-row lg:items-center"
      >
        <div class="flex items-center justify-between">
          <span class="text-3xl mr-4 sm:mr-0 font-bold truncate">
            Portfolios
          </span>
          <div class="flex items-center gap-2 lg:hidden whitespace-nowrap">
            <router-link
              v-if="selectedPortfolio && portfolioBelongsToUser"
              :to="{
                name: 'modify-portfolio',
                query: { name: selectedPortfolio.getName },
              }"
              class="btn btn-secondary"
            >
              Modify
            </router-link>
            <router-link
              :to="{ name: 'new-portfolio' }"
              class="btn btn-primary"
            >
              Create Portfolio
            </router-link>
          </div>
        </div>
        <div v-if="hasPortfolios" class="select-group flex-grow w-full md:w-96">
          <NameOwnerSelector
            v-if="isPortfolioSelected"
            :key="mergeNameOwner(selectedPortfolio)"
            :selected="mergeNameOwner(selectedPortfolio)"
            :options="options"
            placeholder="Type to get a list of portfolios"
            @change="changePortfolio"
          />
        </div>
      </div>
      <div class="hidden items-center gap-2 lg:flex">
        <router-link
          v-if="hasPortfolios && portfolioBelongsToUser"
          :to="{
            name: 'modify-portfolio',
            query: { name: selectedPortfolio.getName },
          }"
          class="btn btn-secondary"
        >
          Modify
        </router-link>
        <router-link :to="{ name: 'new-portfolio' }" class="btn btn-primary">
          Create Portfolio
        </router-link>
      </div>
    </div>
    <div v-if="!hasPortfolios" class="bg-white my-4 rounded-lg">
      <div class="text-center p-10">
        There are no portfolios created
      </div>
    </div>
    <div v-else class="bg-white my-4 rounded-lg">
      <div
        class="flex flex-col gap-y-5 gap-x-5 items-start lg:flex-row lg:justify-between border-b border-gray-400 px-8 py-4"
      >
        <div class="order-2 flex flex-row gap-x-2 br-5">
          <TradingButton name="portfolio-trading" />
          <SharingButton
            :key="selectedPortfolio.getName"
            v-if="portfolioBelongsToUser"
            :acl="selectedPortfolio.getACL"
            @updateACL="updateACL"
          />
          <MultiExport :exportTypes="types" @export="exportType" />
          <NotificationButton
            :isNotificationOn="isNotificationOn"
            @change="updateNotification"
          />
        </div>
        <div class="order-1 flex flex-col gap-y-5 lg:flex-row lg:gap-x-6">
          <DateToolbar
            :key="availableDate"
            :id="selectedPortfolio.getName"
            :isMaxSelected="isMaxSelected"
            :minDate="availableDate"
            :selected="selectedDateRange"
            @search="doSearchSpecific"
          />
          <GraphicDropdown v-model:serie="serie" @update:serie="getData" />
        </div>
      </div>
      <div class="py-4 px-8">
        <div v-if="!portfolio" class="flex flex-col items-center">
          <div class="w-8 h-8 inline-block">
            <LoadingIcon icon="puff" class="w-8 h-8" />
          </div>
          <div class="text-center text-xs">Loading...</div>
        </div>
        <div v-else>
          <div>
            <span class="text-xl font-bold">
              Performance
            </span>
            <PortfolioTopSection
              class="top"
              :data="portfolio"
              :stats="stats"
              v-model:serie="serie"
              @update:serie="getData"
            />
            <div class="h-"></div>
            <div class="border-b-2 border-dashed pl-5 py-10">
              <div class="text-xl">
                Description
              </div>
              <div>
                {{ portfolioDescription }}
              </div>
            </div>
            <div class="mt-8">
              <PortfolioBottomSection :data="composition" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, ref, computed} from 'vue'
import { useStore } from 'vuex'
import DateToolbar from '@/components/date-toolbar/Main.vue'
import MultiExport from '@/components/multi-export/Main.vue'
import PortfolioTopSection from '@/components/portfolio-top-section/Main.vue'
import PortfolioBottomSection from '@/components/portfolio-bottom-section/Main.vue'
import dateRange from '@/domain/services/dateHelper'
import { useRoute } from 'vue-router'
import TradingButton from '@/components/trading/Trading-button.vue'
import NotificationButton from '@/components/notification-button/Main.vue'
import NameOwnerSelector from '@/components/name-owner-selector/Main'
import { mergeNameOwner, transformToPortfolioKey } from "@/domain/services/stringHelper";
import Error from '@/components/error/Main.vue';
import GraphicDropdown from '@/components/graphic-dropdown/Main.vue'
import SharingButton from "@/components/acl/SharingButton";
import ACLTransformer from "@/domain/services/ACLTransformer";
import { updateNotificationPreference } from '@/domain/services/NotificationHelper'

export default defineComponent({
  components: {
    SharingButton,
    DateToolbar,
    MultiExport,
    PortfolioTopSection,
    PortfolioBottomSection,
    TradingButton,
    NameOwnerSelector,
    GraphicDropdown,
    NotificationButton,
    Error
  },
  setup() {
    const store = useStore()
    const options = ref([])
    const selectedPortfolio = ref()
    const description = ref()
    let globalRange = dateRange.month()
    const portfolio = ref()
    const stats = ref()
    const hasPortfolios = ref(false)
    const route = useRoute()
    const portfolioBelongsToUser = ref(false)
    const creationError = ref()
    const availableDate = ref()
    const serie = ref('return')
    const portfolioDescription = ref()
    const composition = ref()
    const isNotificationOn = ref()
    const username = store.getters.getLoggedUser;
    const isMaxSelected = ref(false)
    const selectedData = ref({})
    const selectedDateRange = ref('year')
    const MAX_TYPE = 'max'
    const TYPE = 'portfolio'
    const isPortfolioSelected = computed(() => {return Object.keys(selectedPortfolio).length > 0})


    store.dispatch("getPortfolioKeys")
      .then(portfolioKeyList => {
        options.value = portfolioKeyList
        hasPortfolios.value = portfolioKeyList.length > 0
        if (portfolioKeyList.length > 0) {
          let initiallySelectedIndex = portfolioKeyList.findIndex(portfolio => portfolio.getName === route.query.name)
          if (initiallySelectedIndex < 0) {
            initiallySelectedIndex = 0
          }
          selectedPortfolio.value = portfolioKeyList[initiallySelectedIndex]
          description.value = portfolioKeyList[initiallySelectedIndex].getDescription

          doSearch(dateRange.year())
        }
      })
      .catch(error => {
        store.commit('setErrors', error.response)
        hasPortfolios.value = false
      })

    const searchCompleteData = (data) => {
      getNotificationPreference()
      store.dispatch("getPortfolioData", data)
        .then(specificPortfolio => {
          portfolioBelongsToUser.value = selectedPortfolio.value.getOwner === store.getters.getLoggedUser
          store.dispatch("getPortfolioStats", data)
            .then((portfolioStats) => {
              portfolio.value = specificPortfolio
              stats.value = portfolioStats
            })
            .catch(error => {
              store.commit('setErrors', error.response.data)
            })
          if (!isMaxSelected.value) {
            availableDate.value = specificPortfolio.available
          }
        })
        .catch((error) => {
          store.commit('setErrors', error.response.data)
          creationError.value = error.response.data.error
        })


      store.dispatch("getPortfolio", data)
        .then(res => {
          portfolioDescription.value = res.description
          selectedPortfolio.value.acl = res.acl
          composition.value = res.assets
          })
        .catch(error => {
          store.commit('setErrors', error.response)
        })
    }

    const doSearch = (range) => {
      store.commit('setErrors', null)
      globalRange = range
      portfolio.value = null
      const data = {
        name: selectedPortfolio.value.getName,
        owner: selectedPortfolio.value.getOwner,
      }

      if (isMaxSelected.value) {
        globalRange = 'max'
        store.dispatch('getPortfolioMinDate', data)
          .then(portfolioInformation => {
            availableDate.value = portfolioInformation.available

            selectedData.value = {
              name: selectedPortfolio.value.getName,
              owner: selectedPortfolio.value.getOwner,
              from: new Date(Date.parse(availableDate.value)),
              to: dateRange.lastValidDay(),
              series: 'return'
            }

            searchCompleteData(selectedData.value)
          })
        return
      }

      const portfolioDataNotMax = {
        name: selectedPortfolio.value.getName,
        owner: selectedPortfolio.value.getOwner,
        from: range[0],
        to: range[1],
        series: serie.value
      }
      searchCompleteData(portfolioDataNotMax)
    }

    const doSearchSpecific = ({ _, range }, type) => {
      isMaxSelected.value = type === MAX_TYPE
      selectedDateRange.value = type

      doSearch(range)
    }

    const getData = () => {
      availableDate.value = null
      doSearch(globalRange)
    }

    const changePortfolio = (portfolio) => {
      selectedPortfolio.value = transformToPortfolioKey(portfolio)
      doSearch(globalRange)
    }

    const updateACL = (acl) => {
      store.dispatch('updateACLPortfolio', ACLTransformer.get(selectedPortfolio.value.getOwner, selectedPortfolio.value.getName, acl))
          .then(() => {
            doSearch(globalRange)
          })
    }

    const exportType = (key) => {
      const data = {
        name: selectedPortfolio.value.getName,
        owner: selectedPortfolio.value.getOwner,
        format: key,
        from: dateRange.format(globalRange[0]),
        to: dateRange.format(globalRange[1])
      }
      store.dispatch('portfoliosExportDoc', data)
        .then(contents => {
          const url = window.URL.createObjectURL(contents.data)
          const a = document.createElement('a')
          a.href = url
          a.download = data.name + '-' + data.from + '--' + data.to + '.xlsx'
          a.click()
          window.URL.revokeObjectURL(url)
        })
    }

    const types = [{
      label: 'Export Excel',
      key: 'xlsx'
    }]

    const updateNotification = () => {
      const selectedData = {
        username: username,
        owner: selectedPortfolio.value.getOwner,
        name: selectedPortfolio.value.getName
      }

      isNotificationOn.value = updateNotificationPreference(TYPE, store, selectedData, isNotificationOn.value)
    }

    const getNotificationPreference = () => {
      const data = {
        username: username,
        key: `${TYPE}/${selectedPortfolio.value.getOwner}/${selectedPortfolio.value.getName}`,
      }

      store
        .dispatch('getNotificationPreference', data)
        .then((res) => {
          isNotificationOn.value = !!res.value
        })
    }

    return {
      options,
      selectedPortfolio,
      description,
      portfolio,
      stats,
      types,
      hasPortfolios,
      portfolioBelongsToUser,
      creationError,
      availableDate,
      serie,
      portfolioDescription,
      composition,
      isNotificationOn,
      store,
      isMaxSelected,
      selectedDateRange,
      isPortfolioSelected,

      doSearchSpecific,
      exportType,
      updateACL,
      getData,
      updateNotification,
      changePortfolio,
      mergeNameOwner
    }
  }
})
</script>

<style lang="scss" scoped>
.data {
  &.left {
    td,
    th {
      @apply text-left pr-4 pl-0;
    }
  }
  &__title {
    th {
      @apply py-2 pl-4 text-right;
    }
  }
  &__content {
    td {
      @apply py-2 text-right border-b;
    }
  }
}
</style>
