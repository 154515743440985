<template>
  <div class="flex gap-4">
    <div>
      <div class="text-black mb-1">Quantity</div>
      <input
        type="text"
        :class="{ 'has-error': vQuantity.meta.validated && !vQuantity.meta.valid }"
        class="form-control py-2 px-4 border-gray-300 block"
        placeholder="100,000"
        :value="modelValue.quantity"
        @blur="vQuantity.handleBlur"
        @input="update('quantity', $event.target.value)"
        autocomplete="off"
      />
      <ErrorMessage name="cQuantity" />
    </div>
    <div>
      <div class="text-black mb-1">Years of investment</div>
      <div class="flex flex-col text-center">
        <input
          type="range"
          min="1"
          max="25"
          :class="{ 'has-error': vInvestment.validated && !vInvestment.valid }"
          :value="modelValue.yearsOfInvestment"
          @change="vInvestment.onChange"
          @input="updateYear('yearsOfInvestment', $event.target.value)"
        />
        <span v-if="modelValue.yearsOfInvestment === 1">{{ modelValue.yearsOfInvestment }} year</span>
        <span v-else>{{ modelValue.yearsOfInvestment }} years</span>
        <ErrorMessage name="cInvestment" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { defineRule, ErrorMessage, useField } from "vee-validate"
import { format as formatNumber } from "@/domain/services/numberHelper"
import { numeric } from '@vee-validate/rules'

export default defineComponent({
  components: { ErrorMessage },
  props: {
    modelValue: Object
  },
  setup(props, { emit }) {
    defineRule('numeric', numeric)

    const vQuantity = useField('cQuantity', 'required', {
      label: 'Quantity',
      initialValue: props.modelValue.getQuantity
    })
    const vInvestment = useField('cInvestment', {
      required: true,
      numeric: true
    }, {
      label: 'Years of investment',
      initialValue: props.modelValue.getYearsOfInvestment,
      standalone: true
    })

    const update = (key, value) => {
      value = value.replace(/[^0-9]/g, '')
      value = formatNumber(value)
      vQuantity.value.value = value
      emit('update:modelValue', {...props.modelValue, [key]: value})
    }

    const updateYear = (key, value) => {
      emit('update:modelValue', {...props.modelValue, [key]: parseInt(value)})
    }

    return {
      vQuantity,
      vInvestment,

      update,
      updateYear,
    }
  },
})
</script>