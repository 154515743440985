import BenchmarkFactory from "./BenchmarkFactory"
import PortfolioStats from "../models/PortfolioStats"

const postfixes = {
  '%': '%',
  'number': ''
}
class PortfolioStatsFactory {
  get (data) {
    return new PortfolioStats({
      benchmark: Object.entries(data.benchmark).map(([key, benchmark]) => BenchmarkFactory.get(key, benchmark)),
      benchmarkDescription: data.benchmark_description,
      benchmarkTicker: data.benchmark_ticker,
      market: data.market,
      name: data.name,
      owner: data.owner,
      portfolio: Object.entries(data.portfolio).map(([key, portfolio]) => BenchmarkFactory.get(key, portfolio)),
      from: data.from,
      to: data.to
    })
  }
}

export default new PortfolioStatsFactory()
