<template>
  <div>
    <div class="w-full">
      <div class="flex gap-4">
        <input
          id="automatic-threshold"
          type="checkbox"
          :value="thresholdData.automaticThreshold"
          :checked="thresholdData.automaticThreshold"
          @change="onThresholdTypeChange"
        />
        <label for="automatic-threshold">Automatic thresholds</label>
      </div>
      <Slider
        :value="thresholdData.thresholdRange"
        :disabled="thresholdData.automaticThreshold"
        @thresholdAdjusted="thresholdAdjusted"
      />
    </div>
    <div>
      <ModifyDeletePortfolio
        :portfolio="portfolio"
        :thresholdRange="thresholdData.thresholdRange"
        @editPortfolio="onShowModifyForm"
        @positionReordered="onPositionReordered"
        @positionDeleted="onPositionDeleted"
      />
    </div>
    <div class="text-center py-4 border-b border-r border-l border-theme-27 rounded-b-md">
      <button
        type="button"
        class="add-portfolio whitespace-nowrap"
        @click="onShowCreationForm"
        :disabled="MAX_PORTFOLIO_AMOUNT <= portfolio.length"
      >
        Add or create portfolio +
      </button>
    </div>
  </div>

  <div
    id="create-portfolio"
    class="modal modal-slide-over"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header p-5">
          <h2 class="font-medium text-base mr-auto">
            Create portfolio
          </h2>
        </div>
        <div class="modal-body">
          <CreatePosition
            v-if="options && shownCreationModal"
            :options="options"
            :strategyPortfolios="portfolio"
            @created="onPositionCreated"
            @closed="onHideCreatePortfolio"
          />
        </div>
      </div>
    </div>
  </div>

  <div
    id="modify-portfolio"
    class="modal modal-slide-over"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header p-5">
          <h2 class="font-medium text-base mr-auto">
            Modify portfolio
          </h2>
        </div>
        <div class="modal-body">
          <ModifyPosition
            v-if="shownModifyModal && positionIndex !== null"
            :options="options"
            :portfolio="selectedPortfolio"
            :strategyPortfolios="portfolio"
            @modified="onPositionModified"
            @closed="onHideModifyPortfolio"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref, unref } from 'vue'
import CreatePosition from '@/components/create-position/Main.vue'
import ModifyPosition from '@/components/modify-position/Main.vue'
import Slider from '@/components/slider/Main.vue';
import ModifyDeletePortfolio from './PortfolioList.vue';

const MAX_PORTFOLIO_AMOUNT = 8

export default defineComponent({
  components: { CreatePosition, ModifyPosition, Slider, ModifyDeletePortfolio },
  emits: [ 'positionCreated', 'positionDeleted', 'thresholdAdjusted' ],
  props: {
    portfolio: Array,
    thresholdData: Object,
    options: Array
  },
  setup(props, { emit }) {
    const positionIndex = ref(null)
    const selectedPortfolio = ref({})
    const shownCreationModal = ref(false)
    const shownModifyModal = ref(false)

    onMounted(() => {
      cash('#modify-portfolio').modal('on.hide', function () {
        positionIndex.value = null
        shownModifyModal.value = false
      })
      cash('#create-portfolio').modal('on.hide', function () {
        positionIndex.value = null
        shownCreationModal.value = false
      })
    })

    const filledInPortfolios = computed(() => {
      const filled = props.portfolio.filter(portfolio => portfolio != null)

      return filled.length
    })

    const onShowModifyForm = (index) => {
      shownModifyModal.value = true
      positionIndex.value = index
      
      selectedPortfolio.value = props.portfolio[positionIndex.value]

      cash('#modify-portfolio').modal('show')
    }

    const onShowCreationForm = () => {
      shownCreationModal.value = true
      positionIndex.value = props.portfolio.length + 1

      cash('#create-portfolio').modal('show')
    }

    const onPositionCreated = (portfolio) => {
      const ensureArrayValue = props.thresholdData.thresholdRange
      const newThreshold = Math.round(((getLastElementOfArray(ensureArrayValue) + 1) / 2) * 100) / 100

      if (props.portfolio.length > 0) {
        ensureArrayValue.push(newThreshold)
      }

      const data = {
        type: props.thresholdData.type,
        automaticThreshold: props.thresholdData.automaticThreshold,
        thresholdRange: ensureArrayValue,
      }
      emit('thresholdAdjusted', data) 

      let initialPortfolios = [portfolio]

      if (props.portfolio.length) {
        initialPortfolios = unref(props.portfolio)
        initialPortfolios.push(portfolio)
      }

      emit('positionCreated', initialPortfolios)
      onHideCreatePortfolio()
    }

    const onPositionDeleted = ({ portfolio, index }) => {
      const ensureArrayValue = props.thresholdData.thresholdRange
      const indexToDelete = index === 0 ? 0 : index - 1
      ensureArrayValue.splice(indexToDelete, 1)
      emit('positionDeleted', portfolio)
      const data = {
          type: props.thresholdData.type,
          automaticThreshold: props.thresholdData.automaticThreshold,
          thresholdRange: ensureArrayValue,
        }
      emit('thresholdAdjusted', data)
    }

    const getLastElementOfArray = (array) => {
      if (array.length === 0) {
        return 0
      }

      return array[array.length - 1]
    }

    const onPositionModified = (portfolio) => {
      const p = unref(props.portfolio)
      p[positionIndex.value] = portfolio
      onHideModifyPortfolio()
      emit('positionCreated', p)
    }

    const onPositionReordered = (portfolios) => {
      emit('positionCreated', portfolios)
    }

    const onHideCreatePortfolio = () => {
      positionIndex.value = null
      cash('#create-portfolio').modal('hide')
    }

    const onHideModifyPortfolio = () => {
      positionIndex.value = null
      cash('#modify-portfolio').modal('hide')
    }

    const onThresholdTypeChange = (ev) => {
      const data = {
        type: props.thresholdData.type,
        automaticThreshold: ev.target.checked,
        thresholdRange: props.thresholdData.thresholdRange,
      }
      emit('thresholdAdjusted', data)
    }

    const thresholdAdjusted = (dataArray) => {
      const data = {
        type: props.thresholdData.type,
        automaticThreshold: props.thresholdData.automaticThreshold,
        thresholdRange: dataArray,
      }
      emit('thresholdAdjusted', data)
    }

    return {
      positionIndex,
      filledInPortfolios,
      MAX_PORTFOLIO_AMOUNT,
      selectedPortfolio,
      shownCreationModal,
      shownModifyModal,

      onHideCreatePortfolio,
      onHideModifyPortfolio,
      onShowCreationForm,
      onShowModifyForm,
      onPositionCreated,
      onPositionModified,
      onPositionReordered,
      onPositionDeleted,
      onThresholdTypeChange,
      thresholdAdjusted
    }
  },
})
</script>

<style lang="scss" scoped>
  .add-portfolio:disabled {
    color: #C6D4FD; // theme-4
  }
</style>
