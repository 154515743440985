import PeriodicTransfer from "@/domain/models/PeriodicTransfer"
import { v4 } from "uuid"

class PeriodicTransferFactory {
  get (data) {
    const periodicTransfer = {
      id: v4(),
      quantity: data.quantity,
      frequency: data.frequency
    }

    return new PeriodicTransfer(periodicTransfer)
  }

  getEmpty () {
    return this.get({
        quantity: "",
        frequency: ""
      }
    )
  }

  getDefault () {
    return this.get({
        quantity: "",
        frequency: "monthly"
      }
    )
  }
}

export default new PeriodicTransferFactory()
