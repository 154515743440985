export default class Data {
  constructor (data) {
    this.x = data.x
    this.y = data.y
  }

  get getX () {
    return this.x
  }

  get getY () {
    return this.y
  }
}
