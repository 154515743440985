import DataList from "@/domain/models/DataList"
import { ITEMS_PER_PAGE } from "@/domain/models/DataList"
import DataListSearchItemFactory from "./DataListSearchItemFactory"

class DataListSearchFactory {
  get (data) {
    const dataList = {
      page: 0,
      pageSize: ITEMS_PER_PAGE,
      totalRows: data.length,
      data: data.map(res => DataListSearchItemFactory.get(res))
    }

    return new DataList(dataList)
  }
}

export default new DataListSearchFactory()
