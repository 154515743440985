import EquityExposure from "../models/EquityExposure";

class EquityExposureFactory {
  get (data) {
    const name = Object.keys(data)[0]
    const value = data[name]
    return new EquityExposure({ name, value })
  }
}

export default new EquityExposureFactory()
