import PortfolioData from "../models/PortfolioData"

class PortfolioDataFactory {
  get (data) {
    return new PortfolioData({
      available: data.available,
      benchmark: data.benchmark,
      benchmarkDescription: data.benchmark_description,
      benchmarkTicker: data.benchmark_ticker,
      data: data.data,
      index: data.index,
      name: data.name,
      owner: data.owner,
      series: data.series,
    })
  }
}

export default new PortfolioDataFactory()
