<template>
  <Shell>
    <!-- BEGIN: Login Form -->
    <div class="h-screen h-auto flex py-0 md:my-0 w-full">
      <div
        class="my-auto mx-auto ml-0 md:ml-10 lg:ml-20 bg-white px-5 sm:px-8 py-8 md:p-0 w-full rounded-md shadow-md lg:shadow-none"
      >
        <h2 class="intro-x font-bold text-2xl xl:text-3xl text-left">
          Reset Password
        </h2>
        <div v-if="sent">
          <div class="py-4">
            Done! Now you can login
          </div>
          <router-link
            class="btn btn-primary py-3 px-4 w-full md:w-32 md:mr-3 align-top"
            :to="{ name: 'login' }"
          >
            Login
          </router-link>
        </div>
        <form v-else @submit="onSubmit">
          <div class="intro-x mt-8">
            <Field name="password" label="Password" rules="required" v-slot="{ field, meta }">
              <input
                  type="password"
                  :class="{ 'has-error': meta.validated && !meta.valid }"
                  class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                  placeholder="Password"
                  v-model="password"
                  v-bind="field"
                  autocomplete="off"
              />
              <ErrorMessage name="password" />
            </Field>
            <Field name="repeated-password" label="Repeated password" rules="required|confirmed:@password" v-slot="{ field, meta }">
              <input
                  type="password"
                  :class="{ 'has-error': meta.validated && !meta.valid }"
                  class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                  placeholder="Repeated password"
                  v-model="repeated_password"
                  v-bind="field"
                  autocomplete="off"
              />
              <ErrorMessage name="repeated-password" />
            </Field>
          </div>
          <div class="intro-x mt-5 xl:mt-8 text-center flex justify-between flex flex-col w-full sm:block">
            <button
                class="btn btn-primary py-3 px-4 block sm:w-32 align-top"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- END: Login Form -->
  </Shell>
</template>

<script>
import {defineComponent, ref} from 'vue'
import { useForm, Field, ErrorMessage, defineRule } from "vee-validate"
import { required, confirmed } from "@vee-validate/rules"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from "vuex"
import Shell from "@/components/templates/Login/Shell"

export default defineComponent({
  components: { Field, ErrorMessage, Shell },
  setup() {
    const store = useStore()
    const route = useRoute()
    const password = ref('')
    const repeated_password = ref('')
    const sent = ref(false)
    const { handleSubmit } = useForm({})

    const onSubmit = handleSubmit(values => {
      const form = {
        token: route.params.token,
        password: password.value
      }
      store.dispatch('setPassword', form)
          .then(() => {
            sent.value = true
          })
          .catch(() => {
            console.log('Invalid login')
          })
          .finally(() =>{
            sent.value = true
          })
    })

    defineRule("required", required)
    defineRule("confirmed", confirmed)

    return {
      password,
      repeated_password,
      sent,

      onSubmit
    }
  }
})
</script>
