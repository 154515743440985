<template>
  <div class="py-6">
    <Error
      v-if="creationError"
      :errors="creationError"
      @dismiss="creationError = false"
    />
    <div>
      <div class="text-3xl font-bold">
        Create portfolio
      </div>
      <div class="mt-2">
        Fill in this form to create a new portfolio
      </div>
    </div>
    <div class="w-full xl:w-3/4 2xl:w-1/2 bg-white mt-2 p-4 rounded">
      <CreateStandAlonePosition
        :portfolio="portfolio"
        @created="onPositionCreated"
        @closed="onPositionClosed"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import CreateStandAlonePosition from '@/components/create-stand-alone-position/Main.vue'
import { useRouter } from 'vue-router'
import PortfolioCreateTransformer from '@/domain/services/PortfolioCreateTransformer'
import Error from '@/components/error/Main.vue'

export default defineComponent({
  components: { CreateStandAlonePosition, Error },
  setup() {
    const store = useStore()
    const router = useRouter()
    const portfolio = ref()
    const creationError = ref()

    const onPositionCreated = ((form) => {
      const owner = store.getters.getLoggedUser

      store.dispatch("createPortfolio", PortfolioCreateTransformer.get(form, owner))
        .then(() => {
          router.push({ name: 'portfolios', query: { name: form.name } })
        })
        .catch((error) => {
          creationError.value = error.response.data.error
        })
    })

    const onPositionClosed = () => {
      router.push({ name: 'portfolios' })
    }

    return {
      portfolio,
      creationError,

      onPositionClosed,
      onPositionCreated
    }
  }
})
</script>
