<template>
  <div class="py-6">
    <Error
      v-if="creationError"
      :errors="creationError"
      @dismiss="creationError = false"
    />
    <Error
      v-if="store.getters.hasErrors"
      :errors="store.getters.getErrors"
      @dismiss="creationError = false"
    />
    <Error
      v-if="thresholdDataError"
      :errors="thresholdError"
      @dismiss="thresholdDataError = false"
    />
    <div>
      <div class="text-3xl font-bold">
        Modify strategy
      </div>
      <div class="mt-2">
        Fill in this form to modify the selected strategy
      </div>
    </div>
    <Strategy
      v-if="form.loaded && portfolioOptions.length"
      canDelete
      :options="portfolioOptions"
      v-model:name="form.name"
      v-model:description="form.description"
      v-model:riskIndicator="form.riskIndicator"
      v-model:thresholdData="form.thresholdData"
      v-model:benchmark="form.benchmark"
      v-model:portfolio="form.portfolio"
      v-model:acl="form.acl"
      :thresholdMessage="thresholdErrorMessage"
      @submit="onSubmit"
      @delete="onDelete"
    />
    <div
      class="modal"
      id="delete-confirm-dialog"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-10 text-center">
            <div class="m-4 text-theme-24 text-xl">Attention!</div>
            <div class="m-4">
              Are you sure you want to delete this strategy?
            </div>
            <div class="flex justify-center gap-2">
              <button
                type="button"
                class="btn"
                @click="onCloseDeleteConfirmDialog"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger"
                @click="onRealDelete"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import StrategyCreateTransformer from '@/domain/services/StrategyCreateTransformer';
import Strategy from '@/components/strategy/Main.vue';
import { useRoute, useRouter } from 'vue-router';
import Error from '@/components/error/Main.vue';
import ACLTransformer from '@/domain/services/ACLTransformer';
import { thresholdErrorMessage } from '@/domain/services/componentHelper';

export default defineComponent({
  components: { Strategy, Error },
  props: { name: String },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const form = reactive({
      loaded: false,
      oldName: '',
      name: '',
      description: '',
      riskIndicator: '',
      benchmark: '',
      portfolio: [],
      thresholdData: {
        type: '',
        thresholdRange: [],
        automaticThreshold: true,
      },
      acl: [],
    });
    const creationError = ref();
    const portfolioOptions = ref([]);
    const owner = store.getters.getLoggedUser;
    const thresholdDataError = ref(false);
    const thresholdError = {
      error: thresholdErrorMessage,
    };

    const addStrategyOwnerIndicator = (portfolios) => {
      return portfolios.map((portfolio) => ({
        ...portfolio,
        isOwnStrategy: true,
      }));
    };

    onMounted(() => {
      if (route.query.name) {
        store
          .dispatch('getPortfolioKeys')
          .then((portfolioKeyList) => {
            const generalPortfolios = portfolioKeyList;
            const strategyData = {
              name: route.query.name,
              owner: owner,
            };

            store
              .dispatch('getStrategy', strategyData)
              .then((strategyPortfolios) => {
                const portfolios = addStrategyOwnerIndicator(
                  strategyPortfolios.portfolios
                );
                portfolioOptions.value = portfolios.concat(generalPortfolios);
              });
          })
          .catch((error) => {
            console.log('error', error);
          });
      }
    });

    const onSubmit = async (isThresholdDuplicated = false) => {
      if (isThresholdDuplicated) {
        thresholdDataError.value = true;
        return;
      }

      store
        .dispatch('createStrategy', StrategyCreateTransformer.get(form, owner))
        .then(() => {
          store
            .dispatch(
              'updateACLStrategy',
              ACLTransformer.get(owner, form.name, form.acl)
            )
            .then(() => {
              router.push({ name: 'strategies', query: { name: form.name } });
            })
            .catch((error) => {
              creationError.value = error.response.data.error;
            });
        })
        .catch((error) => {
          creationError.value = error.response.data.error;
        });
    };

    const onDelete = () => {
      cash('#delete-confirm-dialog').modal('show');
    };

    const onCloseDeleteConfirmDialog = () => {
      cash('#delete-confirm-dialog').modal('hide');
    };

    const onRealDelete = () => {
      const data = {
        name: form.name,
        owner: store.getters.getLoggedUser,
      };
      store
        .dispatch('deleteStrategy', data)
        .then(() => {
          router
            .push({ name: 'strategies', query: { name: form.name } })
            .then(() => {
              onCloseDeleteConfirmDialog();
            });
        })
        .catch((error) => {
          creationError.value = error.response.data.error;
          onCloseDeleteConfirmDialog();
        });
    };

    const strategyData = {
      name: route.query.name,
      owner: store.getters.getLoggedUser,
    };

    store
      .dispatch('getStrategy', strategyData)
      .then(async (strategy) => {
        form.oldName = strategy.getName;
        form.name = strategy.getName;
        form.description = strategy.getDescription;
        form.benchmark = strategy.getBenchmark;
        form.riskIndicator = strategy.getStrategy.getIndicator;
        form.thresholdData.type = strategy.getStrategy.type;
        form.thresholdData.thresholdRange =
          strategy.getStrategy.getThresholdRanges;
        form.thresholdData.automaticThreshold =
          strategy.getStrategy.automaticThresholds;
        form.portfolio = strategy.getPortfolios;
        form.acl = strategy.getACL;
        form.loaded = true;
      })
      .catch((error) => {
        store.commit('setErrors', error.response);
        onCloseDeleteConfirmDialog();
      });

    return {
      form,
      creationError,
      store,
      portfolioOptions,
      thresholdErrorMessage,
      thresholdError,
      thresholdDataError,

      onCloseDeleteConfirmDialog,
      onSubmit,
      onDelete,
      onRealDelete,
    };
  },
});
</script>
