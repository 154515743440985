import RiskIndicatorCurrent from "../models/RiskIndicatorCurrent"

class RiskIndicatorCurrentFactory {
  get (data) {
    const current = {
      ind: data.current,
      date: data.date,
      diff: data.change,
      message: data.tooltip,
      subIndicators: data.subindicators
    }

    return new RiskIndicatorCurrent(current)
  }
}

export default new RiskIndicatorCurrentFactory()
