import RiskIndicatorItem from "@/domain/models/RiskIndicatorItem"

class RiskIndicatorItemFactory {
  get (data) {
    const dataRiskIndicatorItem = {
      name: data.name,
      ticker: data.ticker
    }

    return new RiskIndicatorItem(dataRiskIndicatorItem)
  }
}

export default new RiskIndicatorItemFactory()
