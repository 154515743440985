<template>
  <div class="py-6">
    <div v-if="creationError" class="alert alert-danger alert-dismissible show flex items-center mb-2" role="alert">
        <AlertCircleIcon class="w-6 h-6 mr-2" /> {{ creationError }}
        <button type="button" class="btn-close text-white" @click="creationError = null" data-tw-dismiss="alert" aria-label="Close">
            <XIcon class="w-4 h-4" />
        </button>
    </div>
    <div v-if="portfolioOptions.length">
      <div class="text-3xl font-bold">
        Create strategy
      </div>
      <div class="mt-2">
        Fill in this form to create a new strategy
      </div>
    </div>
    <Strategy
      v-if="portfolioOptions.length"
      :options="portfolioOptions"
      v-model:name="form.name"
      v-model:description="form.description"
      v-model:riskIndicator="form.riskIndicator"
      v-model:thresholdData="form.thresholdData"
      v-model:benchmark="form.benchmark"
      v-model:portfolio="form.portfolio"
      v-model:acl="form.acl"
      @submit="onSubmit"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import StrategyCreateTransformer from '@/domain/services/StrategyCreateTransformer'
import ACLTransformer from "@/domain/services/ACLTransformer";
import Strategy from '@/components/strategy/Main'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: { Strategy },
  setup() {
    const store = useStore()
    const router = useRouter()
    const form = reactive({
      oldName: null,
      name: '',
      description: '',
      riskIndicator: '',
      benchmark: '',
      portfolio: [],
      thresholdData: {
        type: '',
        thresholdRange: [],
        automaticThreshold: true
      },
      acl: []
    })
    const creationError = ref()
    const portfolioOptions = ref([])

    store.dispatch("getPortfolioKeys")
      .then(portfolioKeyList => {
        portfolioOptions.value = portfolioKeyList
        if (portfolioKeyList.length <= 0) {
          creationError.value = 'To create a strategy first create a portfolio in the portfolio page'
        }
      })


    const onSubmit = (async () => {
      const owner = store.getters.getLoggedUser

      store.dispatch("createStrategy", StrategyCreateTransformer.get(form, owner))
        .then(() => {
          store.dispatch("updateACLStrategy", ACLTransformer.get(owner, form.name, form.acl))
              .then(() => {
                router.push({ name: 'strategies', query: { name: form.name } })
              })
              .catch(error => {
                creationError.value = error.response.data.error
              })
        })
        .catch((error) => {
          console.log('error', error)
        })
    })

    return {
      form,
      creationError,
      portfolioOptions,

      onSubmit
    }
  }
})
</script>
