export default class DataCategoryGraphic {
    constructor (data) {
      this.from = data.from,
      this.to = data.to,
      this.name = data.name,
      this.available = data.available,
      this.totalPoints = data.totalPoints,
      this.data = data.data
    }

    get getFrom () {
      return this.from
    }

    get getTo () {
      return this.to
    }

    get getName () {
      return this.name
    }

    get getTotalPoints () {
      return this.totalPoints
    }

    get getData () {
      return this.data
    }

    get getAvailable () {
        return this.available
    }
  }
