export default class Benchmark {
  constructor (data) {
    this.key = data.key
    this.format = data.format
    this.name = data.name
    this.value = data.value
  }

  get getKey () {
    return this.key
  }

  get getFormat () {
    return this.format
  }

  get getName () {
    return this.name
  }

  get getValue () {
    if (this.format === 'number') {
      return this.value
    }

    return this.value + this.format
  }
}
