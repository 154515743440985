<template>
  <div>
    <div class="py-6">
      <Alert v-if="saved" message="Changes were saved correctly!" @dismiss="saved = false" />
      <div class="flex flex-col sm:flex-row justify-between">
        <span class="text-3xl font-bold">
          Users
        </span>
        <div class="flex flex-row gap-x-4">
          <div
            class="relative w-full sm:w-56 text-gray-700 dark:text-gray-300 mt-6 sm:mt-0"
          >
            <input
              type="text"
              class="form-control w-full sm:w-56 box pr-10 placeholder-theme-8"
              v-model="search"
              @keyup="filter"
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
          <router-link :to="{ name: 'new-user' }" class="btn btn-primary">
            Create User
          </router-link>
        </div>
      </div>
      <div class="user-list-container flex flex-col sm:flex-row">
        <div
          class="scroll-behavior users-table-wrapper h-56 max-h-56 sm:h-[820px] sm:max-h-[820px] mt-4 px-4 py-6 bg-white rounded-md"
        >
          <table class="users-table w-full text-left">
            <tr class="border-b-2">
              <th class="font-normal">Name</th>
            </tr>
            <tr v-if="isLoading">
              <td class="text-center">
                <div class="w-8 h-8 inline-block">
                  <LoadingIcon icon="puff" class="w-8 h-8" />
                </div>
              </td>
            </tr>
            <tr v-else class="border-b" v-for="user in usersName" :key="user">
              <td class="py-2 cursor-pointer" @click="showUserContent(user)">
                <a>{{ user }}</a>
              </td>
            </tr>
          </table>
        </div>

        <div class="form-container">
          <form class="mt-9 sm:m-9 mb-0 w-full">
            <div class="h-full">
              <div class="mb-6">
                <div class="mb-2">
                  Username
                </div>
                <Field name="username" label="Username">
                  <input
                    type="text"
                    class="intro-x form-control py-3 px-4 border-gray-300 block"
                    placeholder="Username"
                    v-model="username"
                    disabled
                  />
                </Field>
                <div class="mt-4 mb-2">
                  Email
                </div>
                <Field name="email" label="Email">
                  <input
                    type="email"
                    class="intro-x form-control py-3 px-4 border-gray-300 block"
                    placeholder="Email"
                    v-model="email"
                  />
                </Field>
              </div>
              <div>
                <Modal @update="updatePassword" />
              </div>
              <div class="mt-6">
                <label>Roles</label>
                <div
                  v-for="rol in roles"
                  :key="rol"
                  :id="rol"
                  class="flex items-center text-gray-700 mt-2"
                >
                  <input
                    type="checkbox"
                    class="input border mr-3"
                    name="userRole"
                    :value="rol[0]"
                    :checked="checkedRol(rol[0])"
                    :id="rol"
                    @change="onChangeRol(rol[0])"
                  />
                  <label class="cursor-pointer select-none" :for="rol"
                    >{{ capitalizeFirstLetter(rol[0]) }}: {{ rol[1] }}</label
                  >
                </div>
              </div>

              <div class="my-6">
                <p>
                  Log
                </p>
                <div
                  class="h-[220px] max-h-[220px] scroll-behavior mt-4 px-4 py-6 w-full bg-theme-7 rounded-md"
                >
                  <table class="w-full h-full text-left">
                    <thead class="leading-6">
                      <tr>
                        <th class="font-normal">Timestamp</th>
                        <th class="font-normal">Action</th>
                        <th class="font-normal">Message</th>
                        <th class="font-normal">IP</th>
                      </tr>
                      <tr class="h-1"></tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="activity in activities"
                        :key="activity.timestamp"
                      >
                        <td>{{ activity.timestamp }}</td>
                        <td>{{ activity.action }}</td>
                        <td>{{ activity.message }}</td>
                        <td>{{ activity.ip }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="flex justify-between">
                <button class="btn bg-theme-5 text-white" @click="onDelete">
                  Delete
                </button>
                <div>
                  <button
                    class="btn border text-gray-700"
                    @click="cancelChanges"
                  >
                    Cancel
                  </button>
                  <button class="btn btn-primary ml-3" @click="onUserSubmit">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal" id="delete-confirm-dialog" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body p-10 text-center">
                <div class="m-4 text-theme-24 text-xl">Attention!</div>
                <div class="m-4">Are you sure you want to delete this user?</div>
                <div class="flex justify-center gap-2">
                  <button type="button" class="btn" @click="onCloseDeleteConfirmDialog">Cancel</button>
                  <button type="button" class="btn btn-danger" @click="deleteUser">Delete</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { Field } from 'vee-validate';
import Modal from './Modal.vue';
import Alert from "@/components/alert/Main.vue";

export default defineComponent({
  components: { Field, Modal, Alert },
  setup() {
    const store = useStore();
    const isLoading = ref(true);
    const search = ref('');
    const hasData = ref(false);
    const usersName = ref();
    const allUsersList = ref();
    const userInfo = ref();
    const username = ref();
    const email = ref();
    const roles = ref();
    const activities = ref();
    const userRoles = ref();
    const initialRoles = ref();
    const initialEmail = ref();
    const firstLoad = ref(true);
    const saved = ref(false);

    const getUsers = () => {
      store
        .dispatch('getAllUsers')
        .then((usersNameList) => {
          hasData.value = true;
          usersName.value = usersNameList;
          allUsersList.value = usersNameList;
          isLoading.value = false;
        })
        .finally(() => {
          if (firstLoad.value) {
            showFirstUserContent();
          }
        });
    };

    const showFirstUserContent = () => {
      const user = usersName.value[0];
      showUserContent(user);
    };

    const filter = () => {
      usersName.value = allUsersList.value;

      if (search.value.length > 0) {
        isLoading.value = true;

        const filteredUsers = usersName.value.filter((user) => {
          return user.includes(search.value.toLowerCase());
        });

        isLoading.value = false;
        usersName.value = filteredUsers;
        return;
      }
    };

    const updatePassword = (newPassword) => {
      const userForm = {
        username: username.value,
        password: newPassword,
      };

      store
        .dispatch('updatePassword', userForm)
        .then()
        .catch((error) => console.log('error', error));
    };

    const showUserContent = (user) => {
      store.dispatch('getUserInfo', user).then((res) => {
        userInfo.value = res;
        username.value = res.username;
        email.value = res.email;
        initialEmail.value = res.email;
        activities.value = res.activity;
        userRoles.value = res.roles;
        initialRoles.value = res.roles;
        firstLoad.value = false;
      });
    };

    const capitalizeFirstLetter = (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    };

    const checkedRol = (listRol) => {
      if (typeof userRoles.value !== 'undefined') {
        return userRoles.value.find((el) => el === listRol);
      }
    };

    const onChangeRol = (rol) => {
      const asignedRol = userRoles.value.includes(rol);
      if (asignedRol) {
        const index = userRoles.value.indexOf(rol);
        userRoles.value.splice(index, 1);
      } else {
        userRoles.value.push(rol);
      }
    };
    const onDelete = (event) => {
      event.preventDefault()
      cash("#delete-confirm-dialog").modal('show')

    }
    const onCloseDeleteConfirmDialog = () => {
      cash("#delete-confirm-dialog").modal('hide')
    }
    const deleteUser = () => {
      const user = {
        username: username.value,
      };
      store
        .dispatch('deleteUser', user)
        .then(() => {
          onCloseDeleteConfirmDialog()
          getUsers()
          showFirstUserContent()
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const cancelChanges = () => {
      userRoles.value = initialRoles.value;
    };

    const onUserSubmit = (e) => {
      e.preventDefault();
      const hasEmailChanges = email.value !== initialEmail.value;

      let user = {
        username: username.value,
        roles: Object.values(userRoles.value),
      };

      if (hasEmailChanges) {
        user = {
          username: username.value,
          email: email.value,
          roles: Object.values(userRoles.value),
        };
      }

      store
        .dispatch('updateUser', user)
        .then(() => {
          getUsers();
          saved.value = true;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getUsers();

    store.dispatch('getRoles').then((userRolList) => {
      roles.value = Object.entries(userRolList);
    });

    return {
      isLoading,
      hasData,
      search,
      usersName,
      allUsersList,
      username,
      email,
      userInfo,
      roles,
      activities,
      userRoles,
      firstLoad,
      saved,

      filter,
      updatePassword,
      showUserContent,
      capitalizeFirstLetter,
      checkedRol,
      onChangeRol,
      onDelete,
      deleteUser,
      onCloseDeleteConfirmDialog,
      cancelChanges,
      onUserSubmit,
      showFirstUserContent,
    };
  },
});
</script>

<style lang="scss" scoped>
.users-table-wrapper {
  flex-basis: 20%;
}

.form-container {
  flex-basis: 75%;
}

.scroll-behavior {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.users-table {
  tr {
    td,
    th {
      @apply px-2;
    }
    td {
      @apply py-2;
      a {
        color: #2e5bd8;
      }
    }
  }
}

input[type='checkbox'] {
  width: 30px;
  height: 30px;
  border-radius: 6px;
}
</style>
