import axios from '@/plugins/axios'
import PortfolioFactory from '@/domain/services/PortfolioFactory'
import PortfolioKeyFactory from '@/domain/services/PortfolioKeyFactory'
import dateHelper from '@/domain/services/dateHelper'
import PortfolioDataFactory from '@/domain/services/PortfolioDataFactory'
import PortfolioStatsFactory from '@/domain/services/PortfolioStatsFactory'
import PortfolioTradingFactory from '@/domain/services/PortfolioTradingFactory'

const URL_PORTFOLIO = '/api/portfolios'
const URL_PORTFOLIO_DATA = `${URL_PORTFOLIO}/data?name={name}&owner={owner}&from={from}&to={to}&series={series}`
const URL_PORTFOLIO_STATS = `${URL_PORTFOLIO}/stats?name={name}&owner={owner}&from={from}&to={to}`
const URL_GET_PORTFOLIO = `${URL_PORTFOLIO}?name={name}&owner={owner}`
const URL_PORTFOLIO_LIST = `${URL_PORTFOLIO}/list`
const URL_PORTFOLIO_TRADING = `${URL_PORTFOLIO}/trading`
const URL_PORTFOLIO_SHARING = `${URL_PORTFOLIO}/sharing`
const URL_PORTFOLIO_MIN_DATA = `${URL_PORTFOLIO}/data?name={name}&owner={owner}`


export function getPortfolioKeys() {
  return axios.get(URL_PORTFOLIO_LIST)
    .then(strategies => strategies.data.map(strategy => PortfolioKeyFactory.get(strategy)))
    .catch(error => Promise.reject(error));
}

export function getPortfolio(name, owner) {
  const url = URL_GET_PORTFOLIO
    .replace('{owner}', encodeURIComponent(owner))
    .replace('{name}', encodeURIComponent(name))
  return axios.get(url)
    .then(res => PortfolioFactory.get(res.data))
    .catch(error => Promise.reject(error));
}

export function getPortfolioData(name, owner, from, to, series) {
  const url = URL_PORTFOLIO_DATA
      .replace('{owner}', encodeURIComponent(owner))
      .replace('{name}', encodeURIComponent(name))
      .replace('{from}', dateHelper.format(from))
      .replace('{series}', encodeURIComponent(series))
      .replace('{to}', dateHelper.format(to))
  return axios.get(url)
    .then(res => PortfolioDataFactory.get(res.data))
    .catch(error => Promise.reject(error));
}

export function getPortfolioStats(name, owner, from, to) {
  const url = URL_PORTFOLIO_STATS
    .replace('{owner}', encodeURIComponent(owner))
    .replace('{name}', encodeURIComponent(name))
    .replace('{from}', dateHelper.format(from))
    .replace('{to}', dateHelper.format(to))
  return axios.get(url)
    .then(res => PortfolioStatsFactory.get(res.data))
    .catch(error => Promise.reject(error));
}

export function getPortfolioMinDate(name, owner) {
  const url = URL_PORTFOLIO_MIN_DATA
    .replace('{name}', encodeURIComponent(name))
    .replace('{owner}', encodeURIComponent(owner))
  return axios.get(url)
    .then(res => PortfolioDataFactory.get(res.data))
    .catch(error => Promise.reject(error));
}

export function createPortfolio(portfolio) {
  return axios.put(URL_PORTFOLIO, portfolio)
    .then(res => res)
    .catch(error => Promise.reject(error));
}

export function deletePortfolio(name, owner) {
  const data = {
    name: name,
    owner: owner
  }
  return axios.delete(URL_PORTFOLIO, { data: data })
    .then(res => res)
    .catch(error => Promise.reject(error));
}

export function updatePortfolioTrading(name, owner, assets) {
  const data = {
    name: name,
    owner: owner,
    assets: assets
  }
  return axios.post(URL_PORTFOLIO_TRADING, data)
    .then(res => PortfolioTradingFactory.get(res.data))
    .catch(error => Promise.reject(error))
}

export function updateAccessSharingPorfolio(owner, name, acl)
{
  const data = {
    name: name,
    owner: owner,
    acl: acl
  }
  return axios.put(URL_PORTFOLIO_SHARING, data)
      .then(res => res)
      .catch(error => Promise.reject(error))
}
