export default class StrategyData {
  constructor (data) {
    this.available = data.available
    this.benchmark = data.benchmark
    this.benchmarkDescription = data.benchmarkDescription
    this.benchmarkTicker = data.benchmarkTicker
    this.data = data.data
    this.index = data.index
    this.indicator = data.indicator
    this.name = data.name
    this.owner = data.owner
    this.portfolio = data.portfolio
    this.series = data.series
    this.strategyIndicator = data.strategyIndicator
    this.strategyThresholds = data.strategyThresholds
    this.strategyType = data.strategyType
    this.thresholds = data.thresholds
  }

  get getAvailable () {
    return this.available
  }

  get getBenchmark () {
    return this.benchmark
  }

  get getBenchmarkDescription () {
    return this.benchmarkDescription
  }

  get getBenchmarkTicker () {
    return this.benchmarkTicker
  }

  get getData () {
    return this.data
  }

  get getIndex () {
    return this.index
  }

  get getIndicator () {
    return this.indicator
  }

  get getName () {
    return this.name
  }

  get getOwner () {
    return this.owner
  }

  get getPortfolio () {
    return this.portfolio
  }

  get getLastItemInPortfolio () {
    return this.portfolio[this.portfolio.length - 1]
  }

  get getSeries () {
    return this.series
  }

  get getStrategyIndicator () {
    return this.strategyIndicator
  }

  get getStrategyThresholds () {
    return this.strategyThresholds
  }

  get getStrategyType () {
    return this.strategyType
  }

  get getThresholds () {
    return this.thresholds
  }
}
