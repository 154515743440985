<template>
  <div class="py-6">
    <div>
      <div class="text-3xl font-bold">Wealth Simulator</div>
      <div class="text-xl">Select the simulation in order to generate the graph.</div>
    </div>
    <div class="mt-4 px-4 py-6 bg-white rounded-md">
      <form @submit="onSubmit">
        <div class="font-bold text-base">Initial contribution</div>
        <InitialContribution
          v-model="form.initialContribution"
        />
        <div class="font-bold text-base mt-7">Periodic contribution</div>
        <PeriodicTransfer
          v-model="form.periodicContribution"
        />
        <div class="font-bold text-base mt-7">Strategy</div>
        <div class="w-1/4">
          <div class="text-black mb-1">Strategy</div>
          <Strategies
            v-model="form.strategy"
            :strategies="strategies"
          />
        </div>
        <div class="flex justify-end mt-7">
          <button type="submit" class="btn btn-primary">Simulate</button>
        </div>
      </form>
    </div>
    <div v-if="timesLoaded > 0">
      <div class="mt-4 px-4 py-6 bg-white rounded-md" v-if="graphicData">
        <div class="text-xl font-bold">
          Investment Summary
        </div>
        <div class="flex flex-col gap-x-4 lg:flex-row">
          <div class="flex-grow w-full 2xl:w-3/4 xl:w-1/2 lg:w-1/2">
            <VueEcharts
              @restore="applyAutoZoom()"
              :option="{
                xAxis: {
                  type: 'time',
                  boundaryGap: false,
                  splitLine: {
                    show: true
                  }
                },
                yAxis: {
                  type: 'value',
                  boundaryGap: [0, '10%'],
                  min: function (value) {
                    return Math.floor(value.min)
                  },
                },
                series: {
                  name: 'Portfolio value',
                  type: 'line',
                  showSymbol: false,
                  sampling: 'lttb',
                  lineStyle: {
                    color: '#b7dffb00'
                  },
                  zlevel: 0,
                  z: 0,
                  itemStyle: {
                    color: 'rgba(96, 207, 226,10)',
                  },
                  areaStyle: {},
                  data: transform(graphicData.getData)
                },
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: 'rgba(96, 207, 226,10)'
                    }, {
                        offset: 1, color: '#182a5c'
                    }],
                    global: false // default is false
                  }
                },
                tooltip: {
                  trigger: 'axis',
                  formatter: function(params) {
                    var lpad = (value) => {
                      value = value.toString();
                      if (value.length > 1) {
                        return value;
                      }
                      return '0' + value;
                    }
                    var d = new Date(params[0].data[0])
                    var ret = d.getFullYear() + '-' + lpad(1+d.getMonth()) + '-' + lpad(d.getDate())
                    ret += '<br /><table cellpadding=3>{data}</table>'
                    var cont = params.map(param => {
                      var ret = '<tr>'
                      ret += `<td><div style='border-radius: 50%; background-color: ${param.color}; width: 10px; height: 10px'></div></td>`
                      ret += `<td>${param.seriesName}</td>`
                      ret += '<th>' + format(param.data[1]) + '</th>'
                      ret += '<tr>'
                      return ret
                    }).join('')
                    return ret.replace('{data}', cont)
                  }
                },
                toolbox: {
                  show: true,
                  orient: 'vertical',
                  feature: {
                    dataZoom: {
                      yAxisIndex: 'none',
                      icon: {
                        zoom: 'path://',
                        back: 'path://'
                      }
                    },
                    restore: { }
                  }
                },
              }" style="width: 100%; height: 400px" ref="chart" />
          </div>
          <div class="flex-grow-0 mt-5 lg:mt-0">
            <p class="text-xl text-gray-600">Strategy</p>
            <p class="text-3xl">{{ graphicData.strategy }}</p>
            <p class="mt-5 text-sm text-gray-600">Porfolio value</p>
            <p class="text-xl">{{ format(graphicData.returnOnInvestment) }}</p>
            <p class="mt-5 text-sm text-gray-600">ROI</p>
            <p class="text-xl">{{ format(graphicData.totalNetWorth) }}</p>
            <p class="mt-5 text-sm text-gray-600">Initial contribution</p>
            <p class="text-xl">{{ format(graphicData.initialContribution) }}</p>
            <p class="mt-5 text-sm text-gray-600">Total periodic contribution</p>
            <p class="text-xl">{{ format(graphicData.totalPeriodicContribution) }}</p>
            <p class="mt-5 text-sm text-gray-600">Years of investment</p>
            <p class="text-xl">{{ form.initialContribution.yearsOfInvestment }}</p>
          </div>
        </div>
        <div>
          SEC Rule 156 requires funds to tell investors not to base their expectations
          of future results on past performance before they invest.
        </div>
      </div>
      <div v-else>
        <div class="mt-4 px-4 py-6 bg-white rounded-md text-center">
          <div class="w-8 h-8 inline-block">
            <LoadingIcon icon="puff" class="w-8 h-8" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick, reactive, ref } from 'vue'
import { useForm } from "vee-validate"
import { useStore } from 'vuex'
import { VueEcharts } from 'vue3-echarts'
import InitialContribution from '@/components/contribution/Main'
import PeriodicTransfer from '@/components/periodic-transfer/Main'
import Strategies from '@/components/strategies/Main'
import PeriodicTransferFactory from '@/domain/services/PeriodicTransferFactory'
import InitialContributionFactory from '@/domain/services/InitialContributionFactory'
import { format } from '@/domain/services/numberHelper'

export default defineComponent({
  components: {
    InitialContribution,
    PeriodicTransfer,
    Strategies,
    VueEcharts
  },
  setup() {
    const store = useStore()
    const form = reactive({
      initialContribution: InitialContributionFactory.getEmpty(),
      periodicContribution: PeriodicTransferFactory.getDefault(),
      strategy: ""
    })
    const strategies = ref([])

    const timesLoaded = ref(0)

    const graphicData = ref()
    const chart = ref()

    const { handleSubmit } = useForm({})

    const applyAutoZoom = () => {
      chart.value.dispatchAction({
        type: 'takeGlobalCursor',
        key: 'dataZoomSelect',
        'dataZoomSelectActive': true
      })
    };

    const onSubmit = handleSubmit(() => {
      timesLoaded.value++
      graphicData.value = null
      const data = {
        owner: strategies.value.find((strategy) => strategy.getName === form.strategy).getOwner,
        initialContribution: parseInt(form.initialContribution.quantity.replace(/[^0-9]/, '')),
        strategy: form.strategy,
        periodicContribution: parseInt(form.periodicContribution.quantity.replace(/[^0-9]/, '')),
        frequency: form.periodicContribution.frequency,
        years: form.initialContribution.yearsOfInvestment
      }
      store.dispatch("getSimulation", data)
        .then(res => {
          graphicData.value = res
          nextTick(() => {
            chart.value.chart.on('finished', function () {
              applyAutoZoom()
              chart.value.chart.off('finished');
            })
          })
        })
    })

    store.dispatch("getStrategiesKeys")
      .then(res => {
        strategies.value = res
      })

    const transform = (data) => {
      const y = data.getY
      return data.getX.map((x, index) => [y[index], x])
    }

    return {
      chart,
      form,
      strategies,
      graphicData,
      timesLoaded,

      format,
      onSubmit,
      applyAutoZoom,
      transform
    }
  },
})
</script>
