import StrategyKey from "../models/StrategyKey"

class StrategyKeyFactory {
  get (data) {
    return new StrategyKey({
      name: data.name,
      owner: data.owner,
    })
  }
}

export default new StrategyKeyFactory()
