import User from "../models/User"

class UserFactory {
  get (data) {
    const user = {
      activity: data.activity,
      email: data.email,
      roles: data.roles,
      username: data.username
    }

    return new User(user)
  }
}

export default new UserFactory()
