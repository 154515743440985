<template>
  <TomSelect
    id="select-strategy"
    @change="change($event)"
    v-model="newSelected"
    class="form-select-md sm:mr-2 min-w-full w-full"
    :options="{
      maxItems: 1,
      items: newSelected,
      placeholder: placeholder,
      searchField: ['value'],
      render: {
        item: function(data, escape) {
          return getSelectorItems(data);
        },
        option: function(data, escape) {
          return getSelectorItems(data);
        },
      },
    }"
  >
    <option
      v-for="(option, index) in options"
      :key="index"
      :value="getSelectorOption(option)"
      :disabled="option.name === ''"
    >
      {{ option.name }}
    </option>
  </TomSelect>
</template>

<script>
import { defineComponent, ref } from 'vue';
import {
  mergeString,
  getNameInData,
  getOwnerInData,
  delimiterChar,
} from '@/domain/services/stringHelper';
export default defineComponent({
  props: {
    options: Object,
    selected: String,
    placeholder: String,
  },
  emits: ['change'],
  setup(props, { emit }) {
    const newSelected = ref(props.selected);

    const getSelectorOption = (option) => {
      if (option.name !== '') {
        return mergeString(option.owner, option.name);
      }
      return '';
    };

    const change = (value) => {
      if (value && value.target.value.includes(delimiterChar)) {
        newSelected.value = value.target.value;
        emit('change', value.target.value);
        return;
      }
      
      return;
    };

    const getSelectorItems = (data) => {
      const name = getNameInData(data.value);
      const owner = getOwnerInData(data.value);

      return styleItemSelector(owner, name);
    };

    const styleItemSelector = (owner, name) => {
      return (
        '<div style="display: flex; flex-direction: row"><div style="font-size:80%; color: grey">' +
        owner +
        ' </div>' +
        '<strong style="margin-left: 5px; padding-right: 0.1rem">' +
        name +
        '</strong></div>'
      );
    };

    return {
      newSelected,

      change,
      styleItemSelector,
      mergeString,
      getSelectorItems,
      getSelectorOption,
    };
  },
});
</script>
