<template>
  <div>
    <MobileMenu />
    <TopBar />
    <div class="wrapper">
      <div class="wrapper-box">
        <!-- BEGIN: Side Menu -->
        <nav class="side-nav">
          <ul>
            <li v-for="(menu, menuKey) in formattedMenu" :key="menu + menuKey">
              <SideMenuTooltip
                tag="a"
                :content="menu.title"
                :href="router.resolve({ name: menu.pageName }).path"
                class="side-menu"
                :class="{
                  'side-menu--active': menu.active
                }"
                @click="linkTo(menu, router, $event)"
              >
                <div class="side-menu__icon">
                  <component :is="menu.icon" />
                </div>
                <div class="side-menu__title">
                  {{ menu.title }}
                </div>
              </SideMenuTooltip>
            </li>
          </ul>
        </nav>
        <!-- END: Side Menu -->
        <!-- BEGIN: Content -->
        <div class="content">
          <router-view />
        </div>
        <!-- END: Content -->
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, watch, ref } from 'vue'
import TopBar from '@/components/top-bar/Main.vue'
import { useRoute, useRouter } from 'vue-router'
import cash from "cash-dom/dist/cash";
import SideMenuTooltip from '@/components/side-menu-tooltip/Main.vue'
import MobileMenu from '@/components/mobile-menu/Main.vue'
import { useStore } from 'vuex';

export default defineComponent({
  components: { SideMenuTooltip, MobileMenu, TopBar },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const formattedMenu = ref([])
    store.dispatch('whoAmI')
        .then(() => {
          if (!store.getters.isUserAdmin) {
            formattedMenu.value = store.getters.getNonAdminMenu
                .map(elem => {
                  elem.active = route.name === elem.pageName
                  return elem
                })
            return;
          }
          formattedMenu.value = store.getters.getMenu
              .map(elem => {
                elem.active = route.name === elem.pageName
                return elem
              })

        })

    watch(computed(() => route.path), () => {
      if (!store.getters.isUserAdmin) {
        formattedMenu.value = store.getters.getNonAdminMenu
            .map(elem => {
              elem.active = route.name === elem.pageName
              return elem
            })
        return;
      }
      formattedMenu.value = store.getters.getMenu
           .map(elem => {
             elem.active = route.name === elem.pageName
             return elem
           })

    })

    onMounted(() => {
      cash('body')
        .removeClass('error-page')
        .removeClass('login')
        .addClass('main')
    })

    const linkTo = (menu, router, event) => {
      event.preventDefault()
      formattedMenu.value.map(elem => elem.active = false)

      menu.active = true
      router.push({
        name: menu.pageName
      })
    }

    return {
      router,
      formattedMenu,

      linkTo
    }
  }
})
</script>
