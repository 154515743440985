<template>
  <div>
    <label>Roles</label>
    <div
      v-for="rol in roles"
      :key="rol"
      :id="rol"
      class="flex items-center text-gray-700 mt-2"
    >
      <input
        type="checkbox"
        class="input border mr-3"
        name="userRole"
        :value="rol[0]"
        :id="rol"
        @change="$emit('changeRol', rol[0])"
      />
      <label class="cursor-pointer select-none" :for="rol"
        >{{ capitalizeFirstLetter(rol[0]) }}: {{ rol[1] }}</label
      >
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { capitalizeFirstLetter } from '@/domain/services/stringHelper';

export default defineComponent({
  props: {
    roles: Array,
  },
  setup() {
    return {
      capitalizeFirstLetter,
    };
  },
});
</script>

<style lang="scss" scoped>
input[type='checkbox'] {
  width: 30px;
  height: 30px;
  border-radius: 6px;
}
</style>
