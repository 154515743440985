import axios from '@/plugins/axios'

const URL_STRATEGIES_EXPORT = '/api/strategies/download?'
const URL_DATA_EXPORT = '/api/data/download?'
const URL_PORTFOLIO_EXPORT = '/api/portfolios/download?'

export function strategiesExportDoc(data) {
    const url = URL_STRATEGIES_EXPORT + serialize(data)
    return axios.get(url, {
      responseType: 'blob',
    })
    .then(res => {
      return res
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function dataExportDoc(data) {
    const url = URL_DATA_EXPORT + serialize(data)
    return axios.get(url, {
      responseType: 'blob',
    })
    .then(res => {
      return res
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function portfoliosExportDoc(data) {
    const url = URL_PORTFOLIO_EXPORT + serialize(data)
    return axios.get(url, {
      responseType: 'blob',
    })
    .then(res => {
      return res
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

function serialize(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]))
    }
  return str.join("&")
}
