<template>
  <!-- BEGIN: Top Bar -->
  <div
    class="top-bar-boxed border-b border-theme-2 -mt-7 md:-mt-5 -mx-3 sm:-mx-8 px-3 sm:px-8 md:pt-0 mb-12"
  >
    <div class="h-full flex items-center">
      <!-- BEGIN: Logo -->
      <a href="" class="-intro-x hidden md:flex">
        <img
          v-if="logo == ''"
          alt="AcciPartners"
          class="w-44"
          src="@/assets/images/logo-web.svg"
        />
        <img
          v-else
          alt="AcciPartners"
          class="h-6"
          :src="logo"
        />
      </a>
      <!-- END: Logo -->
      <!-- BEGIN: Breadcrumb -->
      <div class="-intro-x breadcrumb mr-auto">
        <a href="/dashboard/risk-indicators">{{ username }}</a>
        <span v-for="title in titles" :key="title">
          <ChevronRightIcon class="breadcrumb__icon" />
          <a href="" class="breadcrumb--active">{{ title }}</a>
        </span>
      </div>
      <!-- END: Breadcrumb -->
    </div>
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import { computed } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
export default {
  setup() {
    const route = useRoute()
    const store = useStore()
    const username = store.getters.getLoggedUser

    const logo = computed(() => {
      return store.getters.getLogo
    })

    const titles = computed(() => {
      return route.meta.title ?? ['Dashboard']
    })
    return {
      titles,
      logo,
      username,
    }
  },
}
</script>
