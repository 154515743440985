<template>
  <div class="py-6">
    <div class="flex flex-col sm:flex-row justify-between">
      <div class="flex items-center gap-8">
        <span class="text-3xl font-bold">
          Data
        </span>
        <div class="select-group" v-if="hasData">
          <select
            class="form-select form-select-md sm:mr-2"
            aria-label=".form-select-md"
            v-model="mod"
            @change="getData"
          >
            <option v-for="option in options" :key="option.name" :value="option.name">
              {{ option.value }}
            </option>
          </select>
        </div>
      </div>
      <div v-if="hasData" class="relative w-full sm:w-56 text-gray-700 dark:text-gray-300 mt-6 sm:mt-0">
        <input
          type="text"
          class="form-control w-full sm:w-56 box pr-10 placeholder-theme-8"
          placeholder="Search..."
          v-model="search"
          @keyup="filter"
        />
        <SearchIcon
          class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
        />
      </div>
    </div>
    <div v-if="!hasData" class="bg-white my-4 rounded-lg">
      <div class="text-center p-10">
        There is no data for this report
      </div>
    </div>
    <div v-else class="mt-4 px-4 py-6 bg-white rounded-md">
      <table class="data-table w-full text-left ">
        <tr class="border-b-2">
          <th>Variable</th>
          <th>Since</th>
          <th class="hidden sm:table-cell">Description</th>
        </tr>
        <tr v-if="paginator.linesToShow.length == 0">
          <td colspan="3">
            <div v-if="firstLoad" class="text-center text-lg p-4">
              <div class="w-8 h-8 inline-block">
                <LoadingIcon icon="puff" class="w-8 h-8" />
              </div>
            </div>
            <div v-else class="text-center text-lg p-4">
              No data found
            </div>
          </td>
        </tr>
        <tr v-else v-for="line in paginator.linesToShow" :key="line" class="border-b">
          <td>
            <a
              :id="line.getTicker"
              href="javascript:;"
              data-toggle="modal"
              data-target="#slide-over"
              @click="showGraphic(line.getTicker, line.getDescription)"
              class="break-all"
            >{{ line.getTicker }}</a>
          </td>
          <td class="whitespace-nowrap">
            {{ line.getSince }}
          </td>
          <td class="hidden sm:table-cell">
            {{ line.description }}
          </td>
        </tr>
      </table>
      <div class="text-center mt-4" v-if="!wasSearch">
        <button
          class="btn btn-outline-secondary"
          v-if="paginator.page + 1 < paginator.totalPages"
          @click="nextPage"
        >
          Next page
        </button>
      </div>
      <div class="text-center mt-4" v-else>
        <button
          class="btn btn-outline-secondary"
          v-if="paginator.page + 1 < paginator.totalPages"
          @click="nextSearchPage"
        >
          Next page
        </button>
      </div>
    </div>
    <div
      id="slide-over"
      class="modal modal-slide-over"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header p-5">
            <div class="flex flex-col w-full">
              <div class="flex">
                <h2 class="text-base mr-auto truncate">
                  {{ modalDescription }} <span class="font-medium">{{ modalKey }}</span>
                </h2>
                <MultiExport
                  class="order-1"
                  :exportTypes="[{ label: 'Export Excel', key: 'xlsx' }]"
                  @export="exportType"
                />
              </div>
            </div>
          </div>
          <div class="modal-body">
            <DateToolbar
              :key="modalKey"
              :id="modalKey"
              :minDate="availableDate"
              :selected="selectedDateRange"
              openPosition="left"
              class="order-2 lg:order-1"
              item_align='justify-between'
              @search="doSearchSpecific"
            />
            <div class="flex-shrink-0 relative w-full">
              <div v-if="graphicStatus == 'loading'" class="flex flex-col items-center">
                <div class="w-8 h-8 inline-block">
                  <LoadingIcon icon="puff" class="w-8 h-8" />
                </div>
                <div class="text-center text-xs">Loading...</div>
              </div>
              <div v-if="graphicStatus == 'error'" class="text-center mt-8">
                There is no data for the selected data range
              </div>
              <div v-if="graphicStatus == 'general-error'" class="text-center mt-8">
                There is no data for the selected data range
              </div>
              <VueEcharts
                v-if="graphicStatus == 'loaded'"
                @restore="applyAutoZoom()"
                :option="{
                  xAxis: {
                    type: 'time',
                    boundaryGap: false,
                    splitLine: {
                      show: true,
                    }
                  },
                  yAxis: {
                    type: 'value',
                    boundaryGap: [0, '10%'],
                    min: function (value) {
                      return Math.floor(value.min - (value.max - value.min) / 3)
                    }
                  },
                  series: {
                    name: modalKey,
                    type: 'line',
                    showSymbol: false,
                    itemStyle: {
                      color: 'rgba(96, 207, 226,10)'
                    },
                    lineStyle: {
                      color: '#b7dffb00'
                    },
                    zlevel: 1,
                    z: 1,
                    areaStyle: {
                      color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0, color: 'rgba(96, 207, 226,10)'
                        }, {
                            offset: 1, color: '#182a5c'
                        }],
                        global: false // default is false
                      }
                    },
                    data: xData
                  },
                tooltip: {
                  trigger: 'axis',
                  formatter: function(params) {
                    var lpad = (value) => {
                      value = value.toString();
                      if (value.length > 1) {
                        return value;
                      }
                      return '0' + value;
                    }
                    var d = new Date(params[0].data[0])
                    var ret = d.getFullYear() + '-' + lpad(1+d.getMonth()) + '-' + lpad(d.getDate())
                    ret += '<br /><table cellpadding=3>{data}</table>'
                    var cont = params.map(param => {
                      var ret = '<tr>'
                      ret += `<td><div style='border-radius: 50%; background-color: ${param.color}; width: 10px; height: 10px'></div></td>`
                      ret += `<td>${param.seriesName}</td>`
                      ret += '<th align=right>' + format(param.data[1]) + '</th>'
                      ret += '<tr>'
                      return ret
                    }).join('')
                    return ret.replace('{data}', cont)
                  }
                },
                toolbox: {
                  show: true,
                  orient: 'vertical',
                  feature: {
                    dataZoom: {
                      yAxisIndex: 'none',
                      icon: {
                        zoom: 'path://',
                        back: 'path://'
                      }
                    },
                    restore: { }
                  }
                },
              }" style="width: 100%; height: 400px" ref="chart" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick, reactive, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { VueEcharts } from 'vue3-echarts'
import DateToolbar from '@/components/date-toolbar/Main'
import MultiExport from '@/components/multi-export/Main'
import dateHelper from '@/domain/services/dateHelper'
import { ITEMS_PER_PAGE } from "@/domain/models/DataList"
import { format } from '@/domain/services/numberHelper'
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    DateToolbar,
    MultiExport,
    VueEcharts
  },
  setup() {
    const store = useStore()
    const options = ref([])
    const mod = ref('')
    const firstLoad = ref(true)
    const search = ref('')
    const modalKey = ref()
    const modalDescription = ref()
    const graphicStatus = ref('loading')
    const paginator = reactive({
      lines: [],
      linesToShow: [],
      page: 0,
      totalPages: 0
    })
    const chart = ref()
    const hasData = ref(true)
    const wasSearch = ref(false)
    const route = useRoute()
    const router = useRouter();
    const xData = ref([])
    const queryTicker = ref()
    const availableDate = ref()
    const selectedDateRange = ref('year')

    let selectedRange = []

    onMounted(() => {
      cash('#slide-over').modal('on.hide', function () {
        selectedDateRange.value = 'year'
        if (Object.keys(route.query).length) {
          router.push({name:'data'})
        }
      })
    })

    store.dispatch("getKeys")
      .then(res => {
        hasData.value = res.length > 0
        options.value = res.map(dataCategory => {
          return {
            name: dataCategory.getId,
            value: dataCategory.getLabel
          }
        })
        getTickerInformation(res[0].id)
      })

    const getTickerInformation = (initialId) => {
      if (Object.keys(route.query).length) {
        graphicStatus.value = 'loading'
        queryTicker.value = route.query.ticker
        store.dispatch('searchData', queryTicker.value)
        .then((res) => {
          const category = res.data[0].category
          mod.value = category;
          getData()
        })

        return
      }

      mod.value = initialId
      getData()
    }


    const applyAutoZoom = () => {
      chart.value.dispatchAction({
        type: 'takeGlobalCursor',
        key: 'dataZoomSelect',
        'dataZoomSelectActive': true
      })
    };

    const filter = () => {
      if (search.value.length > 1) {
        wasSearch.value = true
        firstLoad.value = true
          paginator.lines = []
          paginator.linesToShow = []
        store.dispatch('searchData', search.value.toLowerCase())
          .then((res) => {
            paginator.page = res.getPage
            paginator.totalPages = res.getTotalPages
            paginator.lines = res.getData
            const cloned = paginator.lines.slice()
            paginator.linesToShow = cloned.splice(0, ITEMS_PER_PAGE * (paginator.page + 1))

            firstLoad.value = false
          })
      }
    }

    const findPortfolio = () => {
      if (Object.keys(route.query).length) {
        const element = paginator.linesToShow.find((line) => queryTicker.value === line.ticker)

        nextTick(() => {
          const container = document.getElementById(queryTicker.value);
          container.click()
          showGraphic(element.ticker, element.description)
        })

        return
      }
    }

    const getData = () => {
      wasSearch.value = false
      paginator.lines = []
      paginator.linesToShow = []

      firstLoad.value = true
      getDataChunk(0)
    }

    const getDataChunk = (page) => {
      const data = {
        category: encodeURIComponent(mod.value),
        page: page
      }
      store.dispatch("getData", data)
        .then(res => {
          paginator.page = res.getPage
          paginator.totalPages = res.getTotalPages
          paginator.lines.push(...res.getData)
          paginator.linesToShow.push(...res.getData)
          firstLoad.value = false
          findPortfolio()
          graphicStatus.value = 'loaded'
        })
    }

    const nextPage = () => {
      getDataChunk(paginator.page + 1)
    }

    const nextSearchPage = () => {
      paginator.page++;
      const cloned = paginator.lines.slice()
      paginator.linesToShow = cloned.splice(0, ITEMS_PER_PAGE * (paginator.page + 1))
    }

    const showGraphic = (id, description) => {
      modalKey.value = id
      modalDescription.value = description
      const data = {
        id: id,
        range: dateHelper.year()
      }

      doSearch(data)
    }

    const exportType = (key) => {
      const data = {
        tickers: modalKey.value,
        format: key,
        from: dateHelper.format(selectedRange[0]),
        to: dateHelper.format(selectedRange[1]),
      }
      store.dispatch('dataExportDoc', data)
        .then(contents => {
          const url = window.URL.createObjectURL(contents.data)
          const a = document.createElement('a')
          a.href = url
          a.download = data.tickers + '-' + data.from + '--' + data.to + '.xlsx'
          a.click()
          window.URL.revokeObjectURL(url)
        })
    }

    const doSearchSpecific = ({ _, range }, type) => {
      selectedDateRange.value = type

      const tickerData = {
        id: modalKey.value,
        range: range
      }
      
      doSearch(tickerData)
    }

    const doSearch = ({id, range}) => {
      if (typeof id === 'undefined'){
        return
      } 

      selectedRange = range
      const data = {
        ticker: id,
        from: dateHelper.format(range[0]),
        to: dateHelper.format(range[1]),
      }
      graphicStatus.value = 'loading'
      store.dispatch("getGraphicData", data)
        .then(res => {
          if(res.getData.length <= 0) {
            graphicStatus.value = 'error'
            return
          }
          xData.value = res.getData.map(val => [val.label, val.value])
          availableDate.value = res.getAvailable

          graphicStatus.value = 'loaded'

          nextTick(() => {
            chart.value.chart.on('finished', function () {
              applyAutoZoom()
              chart.value.chart.off('finished');
            })
          })
        })
        .catch((res) => {
          if (res.response.status == 404) {
            graphicStatus.value = 'error'
          } else {
            graphicStatus.value = 'general-error'
          }
        })
    }

    return {
      options,
      firstLoad,
      search,
      paginator,
      mod,
      modalKey,
      modalDescription,
      xData,
      graphicStatus,
      chart,
      hasData,
      wasSearch,
      availableDate,
      selectedDateRange,

      doSearchSpecific,
      exportType,
      filter,
      getData,
      nextPage,
      nextSearchPage,
      showGraphic,
      doSearch,
      applyAutoZoom,
      format
    }
  }
})
</script>

<style lang="scss" scoped>
  .data-table {
    tr {
      td, th {
        @apply px-2
      }
      td {
        @apply py-2;
        a {
          color: #2e5bd8
        }
      }
    }
  }

</style>
