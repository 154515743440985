<template>
  <VueSlider
    ref="slider"
    class="mb-4 mt-8 h-3"
    :modelValue="currentValue"
    :disabled="disabled || value.length < 1"
    :min="-1"
    :max="1"
    :interval="0.01"
    tooltip="always"
    :enableCross="false"
    @change="onThresholdValueChange"
  />
</template>

<script>
import { computed, defineComponent, ref } from '@vue/runtime-core'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default defineComponent({
  components: { VueSlider },
  emits: [ 'thresholdAdjusted' ],
  props: {
    value: Array,
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, { emit }) {
    const slider = ref()
    const currentValue = computed(() => {
      if (slider.value) {
        slider.value.setValue(props.value)
      }

      return props.value
    })

    const onThresholdValueChange = (value) => {
      const ensureArrayValue = Array.isArray(value) ? value : [value]
      emit('thresholdAdjusted', ensureArrayValue)
    }

    return {
      currentValue,
      slider,

      onThresholdValueChange,
    }
  },
})
</script>

<style type="scss" scoped>
  :deep(.vue-slider-rail) {
    height: 10px;
    background-color: #3498db;
  }
  :deep(.vue-slider-dot-tooltip-inner-top::after) {
    border-color: transparent;
    border-top-color: #3498db;
  }
</style>
