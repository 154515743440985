<template>
  <Shell>
    <!-- BEGIN: Login Form -->
      <div class="h-screen h-auto flex py-0 md:my-0 w-full">
        <div
          class="my-auto mx-auto ml-0 md:ml-10 lg:ml-20 bg-white px-5 sm:px-8
          py-8 md:p-0 w-full rounded-md shadow-md md:shadow-none text-center lg:text-left"
        >
          <form @submit="onSubmit" class="login__container">
            <img alt="logo" :src="require('@/assets/images/logo.png')" />
            <div v-if="generalError" class="text-theme-24 text-center mt-8">Invalid username or password</div>
            <div class="intro-x mt-8">
              <Field name="user" label="User" rules="required" v-slot="{ field, meta }">
                <input
                  type="text"
                  :class="{ 'has-error': meta.validated && !meta.valid }"
                  class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                  placeholder="User"
                  v-model="form.user"
                  v-bind="field"
                />
                <ErrorMessage name="user" />
              </Field>
              <Field name="password" label="Password" rules="required" v-slot="{ field, meta }">
                <input
                  type="password"
                  :class="{ 'has-error': meta.validated && !meta.valid }"
                  class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                  placeholder="Password"
                  autocomplete="off"
                  v-model="form.password"
                  v-bind="field"
                />
                <ErrorMessage name="password" />
              </Field>
            </div>
            <div
              class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
            >
              <div class="flex items-center mr-auto">
                <!--
                <input
                  id="remember-me"
                  type="checkbox"
                  class="form-check-input border mr-2"
                />
                <label class="cursor-pointer select-none" for="remember-me"
                  >Remember me</label
                >-->
              </div>
              <router-link :to="{ name: 'resetPassword' }">Forgot Password?</router-link>
            </div>
            <div class="intro-x mt-5 md:mt-8 text-center md:text-left">
            <button
              class="btn btn-primary py-3 px-4 w-full md:w-32 md:mr-3 align-top"
            >
              Login
            </button>
          </div>
          </form>
        </div>
      </div>
    <!-- END: Login Form -->
  </Shell>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue'
import { useForm, Field, ErrorMessage, defineRule } from "vee-validate"
import { required } from "@vee-validate/rules"
import { useRouter } from 'vue-router'
import { useStore } from "vuex"
import Shell from "@/components/templates/Login/Shell"
import { updateTheme } from '@/domain/services/UpdateTheme';

export default defineComponent({
  components: { Field, ErrorMessage, Shell },
  setup() {
    const store = useStore()
    const router = useRouter()
    const form = reactive({
      user: '',
      password: ''
    })
    const generalError = ref(false)

    const { handleSubmit } = useForm({})

    const onSubmit = handleSubmit(() => {
      store.dispatch('login', form)
      .then(() => {
        router.push({ name: 'dashboard' })
          .then(() => {
            const username = form.user
            store.dispatch("getThemeColorPreference", username)
              .then(themeColor => {
                updateTheme(themeColor)
              })
            store.dispatch("getLogoPreference", username)
              .then(logo => {
                store.commit("setLogo", logo)
              })
          })
      })
      .catch(() => {
        generalError.value = true
      })
    })

    defineRule("required", required)

    return {
      form,
      generalError,

      onSubmit
    }
  }
})
</script>

<style lang="scss" scoped>
  .login__container {
    max-width: 297px;
  }
</style>
