import RiskIndicatorFactory from '@/domain/services/RiskIndicatorFactory';
import IndexRiskIndicatorChartFactory from '@/domain/services/IndexRiskIndicatorChartFactory';
import axios from '@/plugins/axios';
import dateHelper from '@/domain/services/dateHelper';
import RiskIndicatorCurrentFactory from '@/domain/services/RiskIndicatorCurrentFactory';
import RiskDataFactory from "@/domain/services/RiskDataFactory";

const URL_GET_RISK_INDICATORS = '/api/indicators/json'
const URL_GET_SPECIFIC_INDEX_RISK_INDICATORS = '/api/data/align?tickers={ticker},indicator/{risk}&from={from}&to={to}'
const URL_GET_SPECIFIC_RISK_INDICATORS_SUBINDICATORS = '/api/indicators/current?ticker={ticker}'
const URL_GET_RISK_INDICATOR_DATA = '/api/indicators/data?ticker={ticker}'

export function getIndexRiskIndicators(ticker, risk, from, to) {
  const url = URL_GET_SPECIFIC_INDEX_RISK_INDICATORS
    .replace('{ticker}', ticker)
    .replace('{risk}', risk)
    .replace('{from}', dateHelper.format(from))
    .replace('{to}', dateHelper.format(to))
  return axios.get(url)
    .then(res => IndexRiskIndicatorChartFactory.get(res.data))
    .catch(error => Promise.reject(error));
}

export function getRiskIndicatorsSubIndicators(ticker) {
  const url = URL_GET_SPECIFIC_RISK_INDICATORS_SUBINDICATORS
    .replace('{ticker}', ticker)
  return axios.get(url)
    .then(res => RiskIndicatorCurrentFactory.get(res.data))
    .catch(error => Promise.reject(error));
}

export function listRiskIndicators() {
  const url = URL_GET_RISK_INDICATORS;
  return axios.get(url)
    .then(res => res.data.map(ri => RiskIndicatorFactory.get(ri)))
    .catch(error => Promise.reject(error));
}

export function getRiskIndicatorData(ticker) {
  const url = URL_GET_RISK_INDICATOR_DATA
      .replace('{ticker}', ticker)
  return axios.get(url)
      .then(res => RiskDataFactory.get(res.data))
      .catch(error => Promise.reject(error))
}
