<template>
  <div class="py-2 px-6 text-m text-left text-black">
    <p class="mb-1.5 py-2">
      Share - Write the name of the users you want to share with
    </p>
    <TomSelect
      multiple
      v-model="selectedRoles"
      :options="{
        plugins: {
          remove_button: {
            title: 'Remove',
          },
        },
        items: selectedRoles,
        persist: false,
        maxItems: null,
        onDelete: null,
        create: true,
        placeholder:
          'Share - Write the name of the users you want to share with',
      }"
      class="w-full form-select-md"
    >
      <option v-for="(role, key) in roles" :key="role.key" :value="key">
        {{ role }}
      </option>
    </TomSelect>
  </div>
  <div class="mt-4 px-5 pb-2.5 text-right">
    <button type="button" class="btn mr-3" @click="onClickCancel">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      @click="onChangeSelectedRoles"
    >
      Save
    </button>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    acl: Array,
  },
  emits: ['changeRoles', 'onCancelButton'],
  name: 'Main',
  setup(props, { emit }) {
    const selectedRoles = ref([]);

    const roles = {
      client: 'Customer',
      employee: 'Company employee',
      manager: 'Fund manager',
      partner: 'Company partner',
    };

    if (props.acl && props.acl.length > 0) {
      selectedRoles.value = [...props.acl];
      props.acl.forEach((name) => {
        if (!roles[name]) {
          roles[name] = name;
        }
      })
    }

    const onChangeSelectedRoles = () => {
      emit('changeRoles', selectedRoles.value);
    };

    const onClickCancel = () => {
      emit('onCancelButton');
    };

    return {
      roles,
      selectedRoles,

      onChangeSelectedRoles,
      onClickCancel,
    };
  },
});
</script>

<style scoped></style>
