<template>
  <div>
    <TomSelect
      @change="$emit('change', $event.target.value)"
      :class="usedClass"
      :options="{
        maxItems: 1,
        loadThrottle: 250,
        placeholder: 'Type to get a list of indicators',
        valueField: 'ticker',
        labelField: 'description',
        searchField: ['description', 'ticker'],
        optgroups: [
          { value: 'Preferred', label: 'Preferred' },
          { value: 'All', label: 'All' }
        ],
        optgroupField: 'group',
        preload: true,

        render: {
          item: function (data, escape) {
            return '<div>' +
              '<strong>' + data.ticker + '</strong> ' +
              data.description +
              '</div>';
          },
          option: function (data, escape) {
            return '<div>' +
              '<strong>' + data.ticker + '</strong> ' +
               data.description +
              '</div>';
          }
        },
        onType: function (str) {
          this.optgroups = []
        },
        load: function (str, callback) {
          onRiskIndicatorLoad(str)
            .then((searchResults) => {
              callback(searchResults)
              if (this.times !== 1) {
                this.times = 1
                this.addItem(value)
              }
            })
            .catch((e) => {
              console.log(e)
              callback()
            })
        },
      }"
    />
  </div>
</template>

<script>
import tomSelectGroupParser from '@/domain/services/TomSelectGroupParser'
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import PortfolioFormTransformer from '@/domain/services/PortfolioFormTransformer'
import { removePrefix } from '@/domain/services/RiskIndicatorTransformer'

export default defineComponent({
  emits: ['change'],
  props: {
    value: String,
    usedClass: Object
  },
  setup(props) {
    const store = useStore()

    const onRiskIndicatorLoad = (str) => {
      if (props.value.length > 0 && str.length === 0) {
        return onSearchIndicators(props.value)
          .then((searchResults) => {
            return onSearchPreferredIndicators()
              .then((fillResults) => [...fillResults, ...searchResults])
          })
      }
      if (str.length === 0) {
        return onSearchPreferredIndicators()
          .then((searchResults) => searchResults)
      }
      return onSearchIndicators(str)
        .then((searchResults) => searchResults)
    }

    const onSearchIndicators = (str) => store.dispatch('searchIndicators', str)
      .then(searchResult => {
        return tomSelectGroupParser(searchResult.getData, 'All', removePrefix)
      })

    const onSearchPreferredIndicators = () => store.dispatch('searchPreferredIndicators')
      .then(preferredResult => {
        const preferredData = tomSelectGroupParser(preferredResult.getData, 'Preferred', removePrefix)
        return onSearchIndicators('a')
          .then(allData => {
            return [...preferredData, ...allData];
          })
      })


    return {
      PortfolioFormTransformer,

      onRiskIndicatorLoad,
    }
  }
})
</script>
