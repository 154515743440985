export default class User {
  constructor (data) {
    this.activity = data.activity
    this.email = data.email
    this.roles = data.roles
    this.username = data.username
  }

  get getActivity () {
    return this.activity
  }

  get getEmail () {
    return this.email
  }

  get getRoles () {
    return this.roles
  }

  get getUsername () {
    return this.username
  }
}
