export default class PortfolioData {
  constructor (data) {
    this.available = data.available
    this.benchmark = data.benchmark
    this.benchmarkDescription = data.benchmarkDescription
    this.benchmarkTicker = data.benchmarkTicker
    this.data = data.data
    this.index = data.index
    this.name = data.name
    this.owner = data.owner
    this.series = data.series
  }

  get getAvailable () {
    this.available
  }

  get getBenchmark () {
    return this.benchmark
  }

  get getBenchmarkDescription () {
    return this.benchmarkDescription
  }

  get getBenchmarkTicker () {
    return this.benchmarkTicker
  }

  get getData () {
    return this.data
  }

  get getIndex () {
    return this.index
  }

  get getName () {
    return this.name
  }

  get getOwner () {
    return this.owner
  }

  get getSeries () {
    return this.series
  }
}
