<template>
  <div class="w-full h-full overflow-x-scroll">
    <table class="w-full">
      <thead>
        <tr class="table-header w-full">
          <td align="left" class="whitespace-nowrap">Ticker</td>
          <td align="center" class="whitespace-nowrap hidden 3xl:table-cell">
            ISIN
          </td>
          <td align="center" class="whitespace-nowrap hidden sm:table-cell md:hidden 2xl:table-cell">
            Name
          </td>
          <td align="center" class="whitespace-nowrap">
            Variation
          </td>
          <td align="right" class="whitespace-nowrap">Value</td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="asset in data"
          :key="asset.ticker"
          class="border-b border-gray-300 pb-2"
        >
          <td align="left">
            <div
              class="h-7 flex flex-col justify-center justify-items-center"
            >
              <p class="whitespace-nowrap overflow-scroll">
                {{ asset.ticker }}
              </p>
            </div>
          </td>
          <td align="center" class="hidden 3xl:table-cell">
            <div
              class="h-7 flex flex-col justify-center justify-items-center"
            >
              <p
                class="whitespace-nowrap overflow-scroll"
              >
                {{ asset.isin }}
              </p>
            </div>
          </td>
          <td align="center" class="hidden sm:table-cell md:hidden 2xl:table-cell">
            <div
              class="h-7 flex flex-col justify-center justify-items-center w-48"
            >
              <p class="whitespace-nowrap overflow-scroll">
                {{ asset.description }}
              </p>
            </div>
          </td>
          <td align="center">
            <div
              class="h-7 flex flex-col justify-center justify-items-center"
            >
              <p class="whitespace-nowrap overflow-scroll text-center" v-if="asset.variation !== -1">
                {{ asset.variation }}%
              </p>
              <p class="whitespace-nowrap overflow-scroll text-center" v-else>
                {{ '-' }}
              </p>
            </div>
          </td>
          <td align="right">
            <div
              class="h-7 w-20 xl:w-24 flex flex-col justify-center justify-items-center items-end"
            >
              <p class="whitespace-nowrap overflow-scroll text-right">
                {{ format(asset.value) }}
              </p>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="h-16 relative">
          <td class="h-full w-38 p-0 pt-1.5 absolute right-0">
            <div class="text-right py-1 w-full h-full">
              <p class="font-bold">
                {{ sumTotals() }}
              </p>
              <p>
                {{ calculateRotation() }}
              </p>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import {
  roundToTwoDecimals,
  format,
} from '@/domain/services/numberHelper';

export default defineComponent({
  props: {
    data: Object,
    totalValue: Number,
  },
  setup(props) {
    const sum = ref();
    const sumTotals = () => {
      let acc = 0;
      if (props.data) {
        props.data.forEach((element) => {
          acc += element.value;
        });

        sum.value = acc;
        return format(acc);
      }
    };

    const calculateRotation = () => {
      return `${roundToTwoDecimals(
        Math.abs((sum.value / props.totalValue) * 100)
      )}% rotation`;
    };

    return {
      sumTotals,
      calculateRotation,
      format,
    };
  },
});
</script>

<style lang="scss" scoped>
tr {
  &.table-header {
    td {
      padding: 6px;
      padding-top: 3px;
    }
  }
  td {
    padding: 6px;
    padding-bottom: 3px;
  }

  td:first-child {
    padding-left: 0;
  }
  td:last-child {
    padding-right: 0;
  }
}

.value {
  width: 90px;
}

p, div {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

p::-webkit-scrollbar, div::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
</style>
