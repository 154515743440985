<template>
  <div class="data">
    <table>
      <tr class="data__title border-b-2">
        <th v-for="label in topLabels" :key="label">{{ label }}</th>
      </tr>
      <tr v-for="line in data" :key="line" class="data__content">
        <td v-for="key in keys" :key="key" :class="'col-' + key">
          {{ line[key] }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    data: Object,
    topLabels: Array,
    keys: Array
  },
  setup() {
    return {
    }
  },
})
</script>

<style lang="scss" scoped>
  .data {
    th {
      @apply text-left py-2
    }
    td {
      @apply py-2 text-right pl-6
    }
    td.col-name {
      @apply text-left
    }
    td {
      @apply border-b
    }
  }
</style>