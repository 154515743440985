export function updateNotificationPreference(
  type,
  store,
  selectedData,
  isNotificationOn
) {
  
  const DAILY = 'daily';

  const data = {
    username: selectedData.username,
    key: `${type}/${selectedData.owner}/${selectedData.name}`,
  };

  if (!isNotificationOn) {
    data.value = DAILY;
    store.dispatch('setNotificationPreference', data);
    return true;
  }

  store.dispatch('deleteNotificationPreference', data);
  return false;
}
