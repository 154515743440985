export default class PortfolioKey {
  constructor (data) {
    this.name = data.name
    this.owner = data.owner
    this.acl = data.acl
  }

  get getName () {
    return this.name
  }

  get getOwner () {
    return this.owner
  }

  get getACL () {
    return this.acl
  }
}
