<template>
  <div class="table-wrapper flex justify-center my-5 border rounded-md">
    <div v-if="!hasData" class="inline-flex items-center">
      <span> Pick a portfolio to simulate trading</span>
    </div>

    <div v-else class="w-full h-full p-4 overflow-x-hidden">
      <div class="w-full h-full">
        <table class="w-full">
          <thead>
            <tr class="table-header">
              <td align="left" class="whitespace-nowrap">Ticker</td>
              <td align="center" class="whitespace-nowrap hidden 3xl:table-cell">
                ISIN
              </td>
              <td align="center" class="whitespace-nowrap hidden sm:table-cell md:hidden 2xl:table-cell">
                Name
              </td>
              <td align="center" class="whitespace-nowrap">
                Weight
              </td>
              <td align="right" class="whitespace-nowrap">Value</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(portfolio, index) in portfolios"
              :key="index"
              class="border-b border-gray-300 pb-2"
            >
              <td align="left">
                <div
                  class="flex flex-col justify-center justify-items-center"
                >
                  <p class="whitespace-nowrap overflow-scroll">
                    {{ portfolio.ticker }}
                  </p>
                </div>
              </td>
              <td align="center" class="hidden 3xl:table-cell">
                <div
                  class="h-7 flex flex-col justify-center justify-items-center"
                >
                  <p class="whitespace-nowrap overflow-scroll">
                    {{ portfolio.isin }}
                  </p>
                </div>
              </td>
              <td align="center" class="hidden sm:table-cell md:hidden 2xl:table-cell">
                <div
                  class="h-7 flex flex-col justify-center justify-items-center w-48"
                >
                  <p class="whitespace-nowrap overflow-scroll">
                    {{ portfolio.description }}
                  </p>
                </div>
              </td>
              <td align="center">
                <div
                  class="h-7 flex flex-col justify-center justify-items-center"
                >
                  <p class="whitespace-nowrap overflow-scroll text-center">
                    {{ portfolio.weight }}%
                  </p>
                </div>
              </td>
              <td align="right">
                <div class="w-20 xl:w-24">
                  <input
                    type="text"
                    class="h-7 w-full px-1 border border-gray-300 rounded-md text-right"
                    @change="
                      updateTickerValue(portfolio.ticker, $event.target.value)
                    "
                    :value="portfolio.value"
                    @input="formatValue(index, $event.target.value)"
                    :disabled="isDisabled"
                  />
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="total-value-wrapper relative">
              <td class="h-full total-value absolute right-0">
                <div class="font-bold text-right px-2 py-1 w-full h-full">
                  {{ totalAmount }}
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { format } from '@/domain/services/numberHelper';

export default defineComponent({
  props: {
    portfolios: Array,
    portfolioInfo: Object,
    enableInput: Boolean,
    totalValue: Number,
  },
  setup(props, { emit }) {
    const hasData = computed(() => {
      return typeof props.portfolios !== 'undefined';
    });

    const isDisabled = computed(() => {
      return !props.enableInput;
    });

    const updateTickerValue = (ticker, value) => {
      emit('updateTickerValue', ticker, value);
    };

    const formatValue = (index, value) => {
      const newPortfolios = JSON.parse(JSON.stringify(props.portfolios));

      newPortfolios[index].value = format(
        value.replace(/[^0-9]/g, '')
      );

      emit('updatePortfolios', newPortfolios);
    };

    const totalAmount = computed(() => {
      return format(props.totalValue);
    });

    return {
      hasData,
      isDisabled,
      totalAmount,

      updateTickerValue,
      formatValue,
    };
  },
});
</script>

<style lang="scss" scoped>
.table-wrapper {
  min-height: 252px;
}

tr {
  &.table-header {
    td {
      padding: 6px;
      padding-top: 3px;
    }
  }

  td {
    padding: 6px;
    padding-bottom: 3px;
  }

  td:first-child {
    padding-left: 0;
  }
  td:last-child {
    padding-right: 0;
  }
}

.total-value-wrapper {
  height: 36px;
}

.total-value {
  width: 120px;
  padding: 0;
  padding-top: 6px;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

p, div {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

p::-webkit-scrollbar, div::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
</style>
