<template>
  <div>
    <div class="text-xl pb-5 font-bold">
      Portfolio Composition
    </div>
    <div class="w-max bg-gray-300 p-4 rounded-md border-gray-400 border-2">
      <table>
        <thead>
          <tr>
            <th class="pr-3 pb-4">Positions</th>
            <th class="pb-4">Weight</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="component in data" :key="component">
            <td class="pr-3 pb-3 cursor-pointer" :class="{'cursor-auto': isCashTicker}">
              <Tippy
                :id="component.ticker"
                tag="span"
                :options="{
                  allowHTML: true,
                  hideOnClick: true,
                  interactive: true,
                  trigger: 'click',
                  onCreate(instance) {
                    instance._isLoading = false
                    instance._error =  null
                  },
                  onShow(instance) {
                    if (instance._isLoading || instance._error) {
                      return
                    }
                    instance._isLoading = true
                    instance.setProps({ trigger: 'click' });
                    onPortfolioPopupShow(component.ticker)
                      .then((result) => {
                        instance.setContent(result)
                        instance._isLoading = false
                      })
                  },
                  onHide(instance) {
                    instance.setProps({ trigger: 'click' });
                  },
                  onShown(instance) {
                    onShownHandler(instance);
                  },
                }"
                content="Loading..."
              >
                {{ component.description }}
              </Tippy>
            </td>
            <td align="center" class="pb-3">{{ component.weight }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { addHtmlText } from '@/domain/services/TooltipHelper';
import { cashTicker } from '@/domain/services/componentHelper' 

export default defineComponent({
  props: {
    data: Object,
  },
  setup() {
    const store = useStore();
    const ticker = ref();
    const router = useRouter();
    const tippyContent = ref();
    const isCashTicker = ref(false);

    const onPortfolioPopupShow = async (ticker) => {
      const tickerHelp = await store.dispatch('getHelpTicker', ticker);

      if (ticker !== cashTicker) {
        isCashTicker.value = false;
        return addHtmlText(tickerHelp.html);
      }

      isCashTicker.value = true;
      return tickerHelp.html;
    }

    const goToTickerData = () => {
      router.push({ name: 'data', query: { ticker: ticker.value } });
    };

    const onShownHandler = (e) => {
      ticker.value = e.reference.id;
      if (e.reference.id !== cashTicker) {
        tippyContent.value = document.querySelector('.tippy-content');
        tippyContent.value.addEventListener('click', goToTickerData, true);
      }
    };

    onBeforeUnmount(() => {
      if (tippyContent.value) {
        tippyContent.value.removeEventListener('click', goToTickerData, false);
      }
    });

    return {
      isCashTicker,

      onPortfolioPopupShow,
      onShownHandler,
    };
  },
});
</script>

<style lang="scss" scoped></style>
