export default class WealthSimulator {
  constructor (data) {
    this.returnOnInvestment = data.returnOnInvestment
    this.totalNetWorth = data.totalNetWorth
    this.initialContribution = data.initialContribution
    this.totalPeriodicContribution = data.totalPeriodicContribution
    this.totalExpenses = data.totalExpenses
    this.strategy = data.strategy,
    this.data = data.data
  }

  get getReturnOnInvestment () {
    return this.returnOnInvestment
  }

  get getTotalNetWorth () {
    return this.totalNetWorth
  }

  get getInitialContribution () {
    return this.initialContribution
  }

  get getTotalPeriodicContribution () {
    return this.totalPeriodicContribution
  }

  get getTotalExpenses () {
    return this.totalExpenses
  }

  get getStrategy () {
    return this.strategy
  }

  get getData () {
    return this.data
  }
}
