<template>
  <div class="table-wrapper flex justify-center my-5 border rounded-md">
    <div v-if="!hasData" class="inline-flex items-center">
      <span> Pick a portfolio to simulate trading</span>
    </div>

    <div v-else class="w-full h-full p-4 overflow-x-hidden">
      <div class="w-full h-full overflow-x-scroll">
        <table class="w-full">
          <thead>
            <tr class="table-header">
              <td align="left" class="whitespace-nowrap">Ticker</td>
              <td align="center" class="whitespace-nowrap hidden 3xl:table-cell">
                ISIN
              </td>
              <td align="center" class="whitespace-nowrap hidden sm:table-cell md:hidden 2xl:table-cell">
                Name
              </td>
              <td align="center" class="whitespace-nowrap">
                Weight
              </td>
              <td align="right" class="whitespace-nowrap">Value</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="asset in portfolios"
              :key="asset.ticker"
              class="border-b border-gray-300 pb-2"
            >
              <td align="left">
                <div
                  class="h-7 flex flex-col justify-center justify-items-center"
                >
                  <p class="whitespace-nowrap overflow-scroll">
                    {{ asset.ticker }}
                  </p>
                </div>
              </td>
              <td align="center" class="hidden 3xl:table-cell">
                <div
                  class="h-7 flex flex-col justify-center justify-items-center"
                >
                  <p class="whitespace-nowrap overflow-scroll">
                    {{ asset.isin }}
                  </p>
                </div>
              </td>
              <td align="center" class="hidden sm:table-cell md:hidden 2xl:table-cell">
                <div
                  class="h-7 flex flex-col justify-center justify-items-center w-48"
                >
                  <p class="whitespace-nowrap overflow-scroll">
                    {{ asset.description }}
                  </p>
                </div>
              </td>
              <td align="center">
                <div
                  class="h-7 flex flex-col justify-center justify-items-center"
                >
                  <p
                    class="whitespace-nowrap overflow-scroll text-center"
                  >
                    {{ asset.variation }}%
                  </p>
                </div>
              </td>
              <td align="right">
                <div
                  class="h-7 w-20 xl:w-24 flex flex-col justify-center justify-items-center items-end"
                >
                  <p class="whitespace-nowrap overflow-scroll text-right">
                    {{ format(asset.value) }}
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="h-9 relative">
              <td class="h-full w-38 p-0 pt-1.5 absolute right-0">
                <div class="font-bold text-right py-1 w-full h-full">
                  {{ totalAmount }}
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { format } from '@/domain/services/numberHelper';

export default defineComponent({
  props: {
    portfolios: Array,
    portfolioInfo: Object,
    totalValue: Number,
  },
  setup(props) {
    const hasData = computed(() => {
      return typeof props.portfolios !== 'undefined';
    });

    const totalAmount = computed(() => {
      return format(props.totalValue);
    });

    return {
      hasData,
      totalAmount,

      format,
    };
  },
});
</script>

<style lang="scss" scoped>
.table-wrapper {
  min-height: 252px;
}

tr {
  &.table-header {
    td {
      padding: 6px;
      padding-top: 3px;
    }
  }

  td {
    padding: 6px;
    padding-bottom: 3px;
  }

  td:first-child {
    padding-left: 0;
  }
  td:last-child {
    padding-right: 0;
  }
}

.total-value {
  width: 120px;
  padding: 0;
  padding-top: 6px;
}

p, div {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

p::-webkit-scrollbar, div::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
</style>
