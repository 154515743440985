import DataList from "@/domain/models/DataList"
import DataListItemFactory from "./DataListItemFactory"

class DataListFactory {
  get (data) {
    const dataList = {
      page: data.page,
      pageSize: data.page_size,
      totalRows: data.total_rows,
      data: data.data.map(res => DataListItemFactory.get(res))
    }

    return new DataList(dataList)
  }
}

export default new DataListFactory()
