export default class InitialContribution {
  constructor (data) {
    this.id = data.id
    this.quantity = data.quantity
    this.yearsOfInvestment = data.yearsOfInvestment
  }

  get getId () {
    return this.id
  }

  get getQuantity () {
    return this.quantity
  }

  get getYearsOfInvestment () {
    return this.yearsOfInvestment
  }
}
