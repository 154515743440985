<template>
  <Tippy tag="a" content="Trading">
    <router-link
      :to="{
        name: name,
        query: { name: strategyName, owner: strategyOwner },
      }"
      class="btn border-theme-36 p-2 mb-5 sm:mb-0 opacity-50"
    >
      <ShuffleIcon size="16" class="custom-icon" fill="white" />
    </router-link>
  </Tippy>
</template>
<script>
import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    name: String,
    strategyName: String,
    strategyOwner: String,
  },
  setup(props) {
    const strategyInfo = computed(() => {
      if (typeof props.strategyName !== 'undefined') {
        return props.strategy;
      }
      return null;
    });

    return { strategyInfo };
  },
});
</script>

<style lang="scss" scoped>
.custom-icon {
  stroke: 'black';
  stroke-width: 3px;
}
</style>
