export function zeroWeightPositions (portfolioFormComposition)
{
    return portfolioFormComposition
        .map((position, index) => ({ index, weight: position.weight }))
        .filter(position => position.weight <= 0)
}

export function notTotalWeightOneHundred (portfolioFormComposition)
{
    const totalWeight = portfolioFormComposition
        .map(position => Number(position.weight))
        .reduce((prev, current) => prev + current)

    return totalWeight !== 100
}