export default class RiskIndicatorItem {
  constructor (data) {
    this.ticker = data.ticker,
    this.name = data.name,
    this.available = data.available
  }

  get getTicker () {
    return this.ticker
  }

  get getName () {
    return this.name
  }

  get getAvailable () {
    return this.available
  }
}
