import { getDefaultState } from '@/store/state'

export default {
  setAuth(state, auth) {
    const accessToken = auth.token
    const user = auth.user
    state.auth = auth

    localStorage.setItem(process.env.VUE_APP_ACCESS_TOKEN, accessToken)
    localStorage.setItem(process.env.VUE_APP_ACCESS_USER, user)
  },
  logout(state) {
    localStorage.removeItem(process.env.VUE_APP_ACCESS_TOKEN)
    localStorage.removeItem(process.env.VUE_APP_ACCESS_USER)
    Object.assign(state, getDefaultState())
  },

  setUser(state, user) {
    state.loggedUser = user
  },

  setLogo(state, logo) {
    state.logo = logo
  },

  setErrors(state, error) {
    state.errors = error
  }

}
