class PortfolioCreateTransformer {
  get (data, owner) {
    let transformed = {
      name: data.name,
      owner: owner,
      description: data.description,
      benchmark: data.benchmark,
      acl: data.acl,
      assets: data.composition.map(position => {
        return {
          ticker: position.ticker,
          weight: parseInt(position.weight)
        }
      })
    }

    if (data.oldName) {
      transformed.old_name = data.oldName
    }

    return transformed
  }
}

export default new PortfolioCreateTransformer()
