<template>
  <button
    class="btn btn-sm"
    :class="extraClass"
    @click="$emit('search', period, id)"
    type="button"><slot /></button>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  emits: [ 'search' ],
  props: {
    id: String,
    period: String,
    extraClass: String,
  },
  setup() {

  },
})
</script>
