<template>
  <div class="bg-white my-4 rounded-lg">
    <div class="text-lg font-bold border-b py-4 px-6">
      {{ indicatorName }}
    </div>
    <div class="p-4 flex flex-col w-full sm:flex-row items-center">
      <div class="pb-7 sm:border-r sm:pr-5 sm:border-b-0 sm:w-56 flex-grow-0 flex-shrink-0">
        <div class="flex justify-between sm:flex-col">
          <div>
            <BarChart2Icon class="opacity-40 w-12 h-12" />
            <div class="px-4 py-3">
              <div class="text-3xl font-medium">
                {{ format(ind, 3) }}
              </div>
              <div
                  v-if="diff > 0"
                  class="bg-theme-10 rounded-full px-2 py-0.5 text-xs inline-block mt-4 text-white font-medium"
              >
                {{ format(diff) }} <ChevronUpIcon class="w-4 h-4 ml-0.5" />
              </div>
              <div
                  v-else
                  class="bg-theme-24 rounded-full px-2 py-0.5 text-xs inline-block mt-4 text-white font-medium"
              >
                {{ format(diff) }} <ChevronDownIcon class="w-4 h-4 ml-0.5" />
              </div>
              <div class="text-sm mt-4 text-gray-600">@{{ date }}</div>
              <div class="text-sm mt-4 text-gray-600 hidden sm:flex gap-1 items-center cursor-pointer">
                <span>{{ message }}</span>
                <Tippy
                  tag="a"
                  data-set="false"
                  href="javascript:;"
                  content="Loading..."
                  class="tooltip"
                  :options="{ onShow: toggleTooltip }"
                >
                  <AlertCircleIcon />
                </Tippy>
              </div>
            </div>
          </div>
          <div class="mt-10 text-gray-600" v-if="subindicators || subindicators.length > 0">
            <div>
              <div class="mt-1 flex gap-x-3 items-stretch justify-between">
                <div class="font-bold">Subindicators</div>
                <div class="flex w-16 justify-between">
                  <div>-1</div>
                  <div>1</div>
                </div>
              </div>
              <div v-for="(value, name) in subindicators" :key="name">
                <IndicatorLine :name="name" :ind="value" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="border-b border-dashed border-gray-400 pb-7 text-sm mt-4 text-gray-600 flex sm:hidden gap-1 items-center cursor-pointer">
        <span>{{ message }}</span>
        <Tippy tag="a" data-set="false" href="javascript:;" content="Loading..." class="tooltip" :options="{ onShow: toggleTooltip }"><AlertCircleIcon /></Tippy>
      </div>
      <div class="flex-shrink-0 flex-grow relative w-full md:w-1/2 sm:w-3/4">
        <div>
          <div class="text-lg font-bold pl-5">{{ indicatorName }}</div>
          <VueEcharts
            @datazoom="sync($event)"
            @restore="restore()"
            :option="{
              grid: {
                top: 20,
                bottom: 20
              },
              xAxis: {
                type: 'time',
                boundaryGap: false,
                splitLine: {
                  show: true
                }
              },
              yAxis: {
                type: 'value',
                boundaryGap: [0, 0],
                min: -1,
                splitNumber: 10
              },
              toolbox: {
                show: true,
                orient: 'vertical',
                feature: {
                  dataZoom: {
                    yAxisIndex: 'none',
                    icon: {
                      zoom: 'path://',
                      back: 'path://'
                    }
                  },
                  restore: { }
                }
              },
              series: [{
                name: indicatorName,
                type: 'line',
                showSymbol: false,
                lineStyle: {
                  color: '#b7dffb00'
                },
                itemStyle: {
                  color: '#60CFE2'
                },
                zlevel: 1,
                z: 1,
                areaStyle: {
                  color: {
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#60CFE2'
                    }, {
                        offset: 1, color: '#182a5c'
                    }]
                  }
                },
                data: riskData
              }],
              tooltip: {
                trigger: 'axis',
                formatter: function(params) {
                  var lpad = (value) => {
                    value = value.toString();
                    if (value.length > 1) {
                      return value;
                    }
                    return '0' + value;
                  }
                  var d = new Date(params[0].data[0])
                  var ret = d.getFullYear() + '-' + lpad(1+d.getMonth()) + '-' + lpad(d.getDate())
                  ret += '<br /><table cellpadding=3>{data}</table>'
                  var cont = params.map(param => {
                    var ret = '<tr>'
                    ret += `<td><div style='border-radius: 50%; background-color: ${param.color}; width: 10px; height: 10px'></div></td>`
                    ret += `<td>${param.seriesName}</td>`
                    ret += '<th align=right>' + format(param.data[1]) + '</th>'
                    ret += '<tr>'
                    return ret
                  }).join('')
                  return ret.replace('{data}', cont)
                }
              }
            }" style="width: 100%; height: 350px" ref="chart" />
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white my-4 rounded-lg">
    <div class="p-4 flex flex-col w-full sm:flex-row">
      <div class="w-56 sm:pr-5 flex-grow-0 flex-shrink-0"></div>
      <div class="flex-shrink-0 flex-grow relative w-full md:w-1/2 sm:w-3/4">
        <div>
          <div class="text-lg font-bold pl-5">{{ indexName }}</div>
          <VueEcharts
            @datazoom="sync($event)"
            @restore="restore()"
            :option="{
              grid: {
                top: 20,
                bottom: 20
              },
              xAxis: {
                type: 'time',
                boundaryGap: false,
                splitLine: {
                  show: true
                }
              },
              yAxis: {
                type: 'value',
                boundaryGap: [0, '10%'],
                axisLabel: {
                  hideOverlap: true
                },
                min: function (value) {
                  return Math.floor(value.min * 0.98)
                },
              },
              toolbox: {
                feature: {
                  dataZoom: {
                    yAxisIndex: 'none',
                    icon: {
                      zoom: 'path://',
                      back: 'path://'
                    }
                  },
                  restore: {}
                }
              },
              series: [{
                name: indexName,
                type: 'line',
                showSymbol: false,
                zlevel: 1,
                z: 1,
                itemStyle: {
                  color: '#60CFE2'
                },
                lineStyle: {
                  color: '#b7dffb00'
                },
                areaStyle: {
                  color: {
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#60CFE2'
                    }, {
                        offset: 1, color: '#182a5c'
                    }]
                  }
                },
                data: xData
              }],
              tooltip: {
                trigger: 'axis',
                formatter: function(params) {
                  var lpad = (value) => {
                    value = value.toString();
                    if (value.length > 1) {
                      return value;
                    }
                    return '0' + value;
                  }
                  var d = new Date(params[0].data[0])
                  var ret = d.getFullYear() + '-' + lpad(1+d.getMonth()) + '-' + lpad(d.getDate())
                  ret += '<br /><table cellpadding=3>{data}</table>'
                  var cont = params.map(param => {
                    var ret = '<tr>'
                    ret += `<td><div style='border-radius: 50%; background-color: ${param.color}; width: 10px; height: 10px'></div></td>`
                    ret += `<td>${param.seriesName}</td>`
                    ret += '<th align=right>' + format(param.data[1]) + '</th>'
                    ret += '<tr>'
                    return ret
                  }).join('')
                  return ret.replace('{data}', cont)
                }
              }
            }" style="width: 100%; height: 350px" ref="bottom_chart" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import IndicatorLine from '@/components/indicator-line/Main'
import { VueEcharts } from 'vue3-echarts'
import { format } from '@/domain/services/numberHelper'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    indicatorName: String,
    indexName: String,
    name: String,
    ind: Number,
    diff: Number,
    message: String,
    date: String,
    subindicators: Object,
    graphicData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: { IndicatorLine, VueEcharts },
  setup(props) {
    const store = useStore()
    const bottom_chart = ref()
    const chart = ref()
    const yData = Object.keys(props.graphicData).map(data => parseInt(data))
    const xData = Object.values(props.graphicData).map((data, index) => [yData[index], data.index])
    const riskData = Object.values(props.graphicData).map((data, index) => [yData[index], data.risk])
    const lock = ref(false)

    onMounted(() => {
      chart.value.chart.on('finished', function () {
        applyAutoZoom()
        chart.value.chart.off('finished');
      })
      bottom_chart.value.chart.on('finished', function () {
        applyAutoZoom()
        bottom_chart.value.chart.off('finished');
      })
    })

    const applyAutoZoom = () => {
      chart.value.dispatchAction({
        type: 'takeGlobalCursor',
        key: 'dataZoomSelect',
        'dataZoomSelectActive': true
      })
      bottom_chart.value.dispatchAction({
        type: 'takeGlobalCursor',
        key: 'dataZoomSelect',
        'dataZoomSelectActive': true
      })
    };

    const restore = () => {
      if (lock.value) return
      lock.value = true
      chart.value.dispatchAction({
        type: 'restore'
      })
      bottom_chart.value.dispatchAction({
        type: 'restore'
      })
      lock.value = false
      applyAutoZoom()
    }

    const sync = (data) => {
      if (lock.value) return
      lock.value = true
      if (data.batch && data.batch.length > 0) {
        const batch = data.batch

        bottom_chart.value.dispatchAction({
          type: 'dataZoom',
          dataZoomIndex: 0,
          start: batch[0].start,
          end: batch[0].end,
          startValue: batch[0].startValue,
          endValue: batch[0].endValue
        })

        chart.value.dispatchAction({
          type: 'dataZoom',
          dataZoomIndex: 0,
          start: batch[0].start,
          end: batch[0].end,
          startValue: batch[0].startValue,
          endValue: batch[0].endValue
        })
      }
      lock.value = false
    }

    const toggleTooltip = (tippy) => {
      if (tippy.reference.getAttribute('data-set') === 'false') {
        store.dispatch('getHelpRiskIndicators')
          .then((res) => {
            tippy.setProps({ 'content': res.html, allowHTML: true })
            tippy.reference.setAttribute('data-set', 'true')
          })
      }
    }

    return {
      chart,
      bottom_chart,
      yData,
      xData,
      lock,
      riskData,

      format,
      sync,
      restore,
      applyAutoZoom,
      toggleTooltip
    }
  }
})
</script>
