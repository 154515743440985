import { delimiterChar, getNameInData } from './stringHelper';

export const INITIAL = 'initial';
export const TARGET = 'target';
export const BUY = 'buy';
export const SELL = 'sell';
export const noDataLiteral = '-';
export const cashTicker = '$$$';
export const thresholdErrorMessage =
  'Threshold values must be different for each asset. Click on threshold bar to adjust value.';

export function wasPortfolioPreviouslySelected(composition) {
  return (
    composition.length > 0 &&
    composition.find((portfolio) => portfolio.ticker !== '')
  );
}

export function compositionHasDuplicates(portfolioFormComposition) {
  const positionNames = portfolioFormComposition.map((item) => item.ticker);

  return new Set(positionNames).size !== portfolioFormComposition.length;
}

export function checkForDuplicates(portfolioFormComposition) {
  return portfolioFormComposition.map((portfolio, index, self) => {
    return (
      index ===
      self.findIndex((currentPortfolio) => {
        return currentPortfolio.ticker === portfolio.ticker;
      })
    );
  });
}

export function filterOutPortfolio(options, portfolioToBeModified) {
  const filteredPortfolios = [...options];
  const index = filteredPortfolios.findIndex(
    (option) => option.name === portfolioToBeModified.name
  );

  if (index !== -1) {
    filteredPortfolios.splice(index, 1);
    return filteredPortfolios;
  }
}

export function checkIfPortfolioIsFromStrategy(options, portfolio, owner) {
  let name = portfolio;

  if (portfolio.includes(delimiterChar)) {
    name = getNameInData(portfolio);
  }

  return options.find(
    (option) => option.name === name && option.owner === owner
  );
}
