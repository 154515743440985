<template>
  <div class="portfolio-list">
    <ag-grid-vue
      ref="agGrid"
      class="ag-theme-alpine"
      style="height: 200px"
      :rowData="portfolioRowData"
      rowDragManaged
      @rowDragEnter="getInitialPortfolio($event)"
      overlayNoRowsTemplate="No portfolios to show"
      :columnDefs="[
        { field: 'name', rowDrag: true, cellStyle: { border: 'none' } },
        { field: 'description', cellStyle: { border: 'none' }},
        { field: 'threshold', maxWidth: 300, minWidth: 300, cellStyle: { border: 'none' }},
        {
          field: 'controls',
          cellStyle: { border: 'none' },
          cellRenderer: 'EditDeleteControls',
          cellRendererParams: {
            edited(index) {
              $emit('editPortfolio', index);
            },
            deleted(index) {
              onDeletePortfolio(index, this);
            },
          },
        },
      ]"
      :headerHeight="0"
      @rowDragEnd="reorderThresholds"
      @gridReady="onGridReady"
    />
    <div
      class="modal"
      id="delete-portfolio-confirm-dialog"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-10 text-center">
            <div class="m-4 text-theme-24 text-xl">Attention!</div>
            <div class="m-4">
              Are you sure you want to delete this portfolio out of this
              strategy?
            </div>
            <div class="flex justify-center gap-2">
              <button
                type="button"
                class="btn"
                @click="onCloseDeleteConfirmDialog"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger"
                @click="onRealDelete"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/runtime-core';
import EditDeleteControls from './EditDeleteControls.vue';
import { AgGridVue } from 'ag-grid-vue3';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export default defineComponent({
  props: {
    portfolio: Object,
    thresholdRange: Array,
  },
  emits: [
    'positionCreated',
    'positionReordered',
    'positionDeleted',
    'editPortfolio',
  ],
  /* eslint-disable vue/no-unused-components */
  components: { AgGridVue, EditDeleteControls },
  setup(props, { emit }) {
    const agGrid = ref();
    const portfolioIndexToDelete = ref();
    const initialIndex = ref();
    const updatedPortfolios = ref([]);
    const dragged = ref(false);

    const portfolioRowData = computed(() => {
      const extendedThresholdRange = [-1, ...props.thresholdRange, 1];
      let portfolios = props.portfolio;

      if (dragged.value) {
        portfolios = updatedPortfolios.value;
      }

      return portfolios.map((portfolio, index) => {
        return {
          name: portfolio.getName,
          description: portfolio.getDescription,
          threshold:
            'Indicator from ' +
            extendedThresholdRange[index] +
            ' to ' +
            extendedThresholdRange[index + 1],
        };
      });
    });

    const getInitialPortfolio = (data) => {
      initialIndex.value = data.overIndex;
    };

    const reorderThresholds = () => {
      const reorderedPortfolios = [];
      dragged.value = true;

      agGrid.value.gridOptions.api.forEachNode((node) => {
        const portfolioName = node.data.name;
        const selectedPortfolio = props.portfolio.find(
          (portfolio) => portfolio.name === portfolioName
        );
        reorderedPortfolios.push(selectedPortfolio);
      });

      updatedPortfolios.value = reorderedPortfolios;
      emit('positionReordered', reorderedPortfolios);
    };

    const onDeletePortfolio = (index) => {
      portfolioIndexToDelete.value = index;
      cash('#delete-portfolio-confirm-dialog').modal('show');
    };

    const onRealDelete = () => {
      const currentPortfolios = [];
      agGrid.value.gridOptions.api.forEachNode((_, index) => {
        if (index != portfolioIndexToDelete.value) {
          currentPortfolios.push(props.portfolio[index]);
        }
      });
      updatedPortfolios.value = currentPortfolios;

      emit('positionDeleted', {
        portfolio: currentPortfolios,
        index: portfolioIndexToDelete.value,
      });
      onCloseDeleteConfirmDialog();

      portfolioIndexToDelete.value = null;
    };

    const onCloseDeleteConfirmDialog = () => {
      cash('#delete-portfolio-confirm-dialog').modal('hide');
    };

    const onGridReady = () => {
      agGrid.value.gridOptions.api.sizeColumnsToFit();
    };

    return {
      portfolioRowData,
      agGrid,

      reorderThresholds,
      onDeletePortfolio,
      onRealDelete,
      onCloseDeleteConfirmDialog,
      onGridReady,
      getInitialPortfolio,
    };
  },
});
</script>

<style lang="scss" scoped>
  .ag-theme-alpine {
    --ag-border-color: #8DA9BE;
    :deep(.ag-root-wrapper) {
      @apply rounded-t-md
    }
  }
</style>
