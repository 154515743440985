<template>
  <div>
    <select
      class="form-select"
      :class="{ 'has-error': vStrategy.meta.validated && !vStrategy.meta.valid }"
      :value="modelValue"
      @change="vStrategy.handleChange"
      @blur="vStrategy.handleBlur"
      @input="$emit('update:modelValue', $event.target.value)"
    >
      <option value="">Select</option>
      <option v-for="option in strategies" :key="option.name" :value="option.name">
        {{ `${option.owner } ${ option.name }` }}
      </option>
    </select>
    <ErrorMessage name="strategy" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useField, ErrorMessage } from 'vee-validate'

export default defineComponent({
  props: {
    modelValue: String,
    strategies: Array
  },
  components: { ErrorMessage },
  setup(props) {
    const vStrategy = useField('strategy', {
      required: true
    }, {
      label: 'Strategy',
      initialValue: props.modelValue
    })

    return {
      vStrategy
    }
  },
})
</script>
