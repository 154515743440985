export default class PortfolioAsset {
  constructor (data) {
    this.isin = data.isin
    this.ticker = data.ticker
    this.description = data.description
    this.weight = parseInt(data.weight, 10)
  }

  get getValue () {
    return `${this.getTicker}@${this.getDescription}`
  }

  get getTicker () {
    return this.ticker
  }

  get getIsin () {
    return this.isin
  }

  get getDescription () {
    if (!this.description) {
      return ''
    }

    return this.description
  }

  get getWeight () {
    return this.weight
  }

  setDescription (description) {
    this.description = description
  }
}
