<template>
  <div />
</template>

<script>
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { updateTheme } from '@/domain/services/UpdateTheme';

export default defineComponent({
  setup() {
    const store = useStore()
    const router = useRouter()

    updateTheme("")

    store.dispatch("logout")
      .then(() => {
        router.push({ name: "login" })
      })
  },
})
</script>
