import DataCategoryFactory from '@/domain/services/DataCategoryFactory';
import DataCategoryGraphicFactory from '@/domain/services/DataCategoryGraphicFactory';
import DataListFactory from '@/domain/services/DataListFactory';
import DataListSearchFactory from '@/domain/services/DataListSearchFactory';
import TradingSearchItemFactory from '@/domain/services/TradingSearchItemFactory';
import Metadata from '@/domain/models/Metadata';
import axios from '@/plugins/axios';

const URL_LIST_DATA = '/api/data/list?category={category}&page={page}';
const URL_CATEGORY_DATA = '/api/data/categories';
const URL_METADATA_DATA = '/api/data/metadata?ticker={ticker}';
const URL_GRAPHICS_DATA = '/api/data?ticker={ticker}&from={from}&to={to}';
const URL_SEARCH = '/api/data/search?query={query}&mode=data';
const URL_SEARCH_ASSETS = '/api/data/search?query={query}&mode=assets';
const URL_SEARCH_INDICATORS = '/api/data/search?mode=indicators&query={query}';
const URL_SEARCH_PREFERRED_INDICATORS =
  '/api/data/search?mode=preferred-indicators&query=.';
const URL_SEARCH_BENCHMARKS = '/api/data/search?mode=benchmarks&query={query}';
const URL_SEARCH_PREFERRED_BENCHMARKS =
  '/api/data/search?mode=preferred-benchmarks&query=.';
const URL_SEARCH_EXACT_POSITIONS =
  '/api/data/search?exact=true&query={query}&mode={mode}';

export function getMetadata(ticker) {
  const url = URL_METADATA_DATA.replace('{ticker}', ticker);
  return axios
    .get(url)
    .then((res) => new Metadata(res.data))
    .catch((error) => Promise.reject(error));
}

export function searchPreferredBenchmarks() {
  const url = URL_SEARCH_PREFERRED_BENCHMARKS;
  return axios
    .get(url)
    .then((res) => DataListSearchFactory.get(res.data.data))
    .catch((error) => Promise.reject(error));
}

export function searchBenchmarks(query) {
  const url = URL_SEARCH_BENCHMARKS.replace(
    '{query}',
    encodeURIComponent(query)
  );
  return axios
    .get(url)
    .then((res) => DataListSearchFactory.get(res.data.data))
    .catch((error) => Promise.reject(error));
}

export function searchPreferredIndicators() {
  const url = URL_SEARCH_PREFERRED_INDICATORS;
  return axios
    .get(url)
    .then((res) => DataListSearchFactory.get(res.data.data))
    .catch((error) => Promise.reject(error));
}

export function searchIndicators(query) {
  const url = URL_SEARCH_INDICATORS.replace(
    '{query}',
    encodeURIComponent(query)
  );
  return axios
    .get(url)
    .then((res) => DataListSearchFactory.get(res.data.data))
    .catch((error) => Promise.reject(error));
}

export function getData(category, page) {
  const url = URL_LIST_DATA.replace('{category}', category).replace(
    '{page}',
    page
  );
  return axios
    .get(url)
    .then((res) => DataListFactory.get(res.data))
    .catch((error) => Promise.reject(error));
}

export function searchData(search) {
  const url = URL_SEARCH.replace('{query}', encodeURIComponent(search));
  return axios
    .get(url)
    .then((res) => DataListSearchFactory.get(res.data.data))
    .catch((error) => Promise.reject(error));
}

export function searchAssets(search) {
  const url = URL_SEARCH_ASSETS.replace('{query}', encodeURIComponent(search));
  return axios
    .get(url)
    .then((res) => DataListSearchFactory.get(res.data.data))
    .catch((error) => Promise.reject(error));
}

export function searchAssetData(search) {
  const url = URL_SEARCH.replace('{query}', encodeURIComponent(search));
  return axios
    .get(url)
    .then((res) => {
      if (res.data.data && res.data.data.length > 0) {
        const assets = res.data.data.find((asset) => {
          return search === asset.ticker;
        });

        return TradingSearchItemFactory.get(assets);
      }

      return null;
    })
    .catch((error) => Promise.reject(error));
}

export function getKeys() {
  return axios
    .get(URL_CATEGORY_DATA)
    .then((collection) =>
      collection.data.map((res) => DataCategoryFactory.get(res))
    )
    .catch((error) => Promise.reject(error));
}

export function getGraphicData(ticker, from, to) {
  const url = URL_GRAPHICS_DATA.replace('{ticker}', ticker)
    .replace('{from}', from)
    .replace('{to}', to);
  return axios
    .get(url)
    .then((res) => DataCategoryGraphicFactory.get(res.data))
    .catch((error) => Promise.reject(error));
}

export function searchExactData(search, mode) {
  const url = URL_SEARCH_EXACT_POSITIONS.replace(
    '{query}',
    encodeURIComponent(search)
  ).replace('{mode}', mode);

  return axios
    .get(url)
    .then((res) => res.data.data)
    .catch((error) => Promise.reject(error));
}
