import axios from '@/plugins/axios'

const URL_LOGIN = '/api/login'
const URL_RESET_PASSWORD = '/api/reset-password'
const URL_SET_PASSWORD = '/api/set-password'
const URL_LOGOUT = '/api/logout'

export function login(formData) {
  return axios
    .post(URL_LOGIN, {
      user: formData.user,
      password: formData.password
    })
    .then(res => {
      return res
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function resetPassword(email) {
  return axios
    .post(URL_RESET_PASSWORD, {
      user: email
    })
    .then()
    .catch(error => {
      return Promise.reject(error)
    })
}

export function setPassword(token, password) {
  return axios
    .post(URL_SET_PASSWORD, {
      token: token,
      password: password
    })
    .then()
    .catch(error => {
      return Promise.reject(error)
    })
}

export function logout() {
  return axios
    .post(URL_LOGOUT)
    .then()
}
