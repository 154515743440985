<template>
  <TomSelect
    id="select-portfolio"
    @change="change($event)"
    class="form-select form-select-md w-full sm:mr-2 sm:w-auto"
    :modelValue="currentValue"
    :allowEmptyOption="false"
    :options="{
      create: true,
      createFilter: function(input) {
        return checkDuplicates(input);
      },
      searchField: ['value'],
      maxItems: 1,
      placeholder: placeholder,
      render: {
        item: function(data) {
          if (data.value.includes(delimiterChar)) {
            return getSelectorItems(data);
          }

          return (
            '<div>' + username + '<strong> ' + data.text + '</strong></div>'
          );
        },
        option: function(data) {
          if (data.value.includes(delimiterChar)) {
            return getSelectorItems(data);
          }
          return (
            '<div>' + username + '<strong> ' + data.text + '</strong></div>'
          );
        },
      },
    }"
  >
    <option key="" value=""></option>
    <option
      v-for="(option, index) in options"
      :key="index"
      :value="mergeString(option.owner, option.name)"
    >
      {{ option.owner }}
    </option>
  </TomSelect>
</template>

<script>
import { defineComponent, unref } from 'vue';
import {
  mergeString,
  getNameInData,
  getOwnerInData,
  delimiterChar,
} from '@/domain/services/stringHelper';
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    options: Array,
    placeholder: String,
    class: String,
    value: String,
  },
  emits: ['change', 'updateName', 'update:name'],
  setup(props, { emit }) {
    const store = useStore();
    const username = store.getters.getLoggedUser;
    const currentValue = unref(props.value);

    const checkDuplicates = (inputValue) => {
      return !props.options.find((option) => {
        return (
          option.name === inputValue &&
          option.owner === username
        );
      });
    };

    const change = (value) => {
      const isNewPortfolio = value.target.value.includes(delimiterChar);
      if (value && isNewPortfolio) {
        emit('change', value.target.value);
        emit('update:name', value.target.value);
        return;
      }
      emit('updateName', mergeString(username, value.target.value));
      return;
    };

    const getSelectorItems = (data) => {
      const name = getNameInData(data.value);
      const owner = getOwnerInData(data.value);

      return styleItemSelector(owner, name);
    };

    const styleItemSelector = (owner, name) => {
      return (
        '<div style="display: flex; flex-direction: row"><div style="font-size:80%; color: grey">' +
        owner +
        ' </div>' +
        '<strong style="margin-left: 5px; padding-right: 0.1rem">' +
        name +
        '</strong></div>'
      );
    };

    return {
      username,
      delimiterChar,
      currentValue,

      change,
      mergeString,
      getSelectorItems,
      checkDuplicates,
    };
  },
});
</script>

<style scoped></style>
