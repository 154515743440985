<template>
  <form @submit="onPortfolioSubmit">
    <div class="mb-4">
      <div class="font-bold mb-2">
        Name
      </div>
      <Field name="name" label="Portfolio name" rules="required|hyphenName" v-slot="{ field, meta }">
        <input
          type="text"
          :class="{ 'has-error': meta.validated && !meta.valid }"
          class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
          placeholder="Portfolio name"
          v-model="portfolioForm.name"
          :name="field.name"
          @blur="field.onBlur"
          @input="field.onInput"
          @change="field.onChange"
        />
        <ErrorMessage name="name" />
      </Field>
    </div>
    <div class="mb-4">
      <div class="font-bold mb-2">
        Description
      </div>
      <Field name="description" label="Portfolio description" rules="required" v-slot="{ field, meta }">
        <input
          type="text"
          :class="{ 'has-error': meta.validated && !meta.valid }"
          class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
          placeholder="Portfolio description"
          v-model="portfolioForm.description"
          :name="field.name"
          @blur="field.onBlur"
          @input="field.onInput"
          @change="field.onChange"
        />
        <ErrorMessage name="description" />
      </Field>
    </div>
    <div class="mb-4">
      <div class="font-bold mb-2">
        Benchmark
      </div>
      <Field v-model="portfolioForm.benchmark" name="benchmark" label="Benchmark" rules="required" v-slot="{ field, meta }">
        <Benchmark
          v-model="portfolioForm.benchmark"
          v-bind="field"
          :usedClass="{ 'has-error': meta.validated && !meta.valid }"
        />
        <ErrorMessage name="benchmark" />
      </Field>
    </div>
    <div>
      <div class="font-bold mb-2">
        Portfolio composition
      </div>
      <div class="rounded shadow-d315 p-4 pb-8">
        <div
          class="mb-4 flex items-start gap-2"
          v-for="(position, index) in portfolioForm.composition"
          :key="position.id"
        >
          <div class="flex-1">
            <div class="font-bold mb-2">
              Position
            </div>
            <Field
              :name="'position' + index"
              label="Position"
              rules="required"
              v-slot="{ field, meta }"
              v-model="position.ticker"
            >
              <TomSelect
                v-model="position.ticker"
                :name="field.name"
                :class="{ 'has-error': meta.validated && !meta.valid }"
                :options="{
                  maxItems: 1,
                  loadThrottle: 250,
                  placeholder: 'Type to get a list of positions',
                  valueField: 'ticker',
                  labelField: 'description',
                  searchField: ['description', 'ticker'],
                  load: (str, callback) => {
                    onSearchPosition(str)
                      .then(res => {
                        callback(res)
                      })
                      .catch(() => callback())
                  },
                  shouldLoad: (str) => str.length > 0,
                  render: {
                    item: function (data, escape) {
                      return '<div>' +
                        '<strong>' + PortfolioFormTransformer.parseGetTicker(data.ticker) + '</strong> ' +
                        data.description +
                        '</div>';
                    },
                    option: function (data, escape) {
                      return '<div>' +
                        '<strong>' +PortfolioFormTransformer.parseGetTicker(data.ticker) + '</strong> ' +
                        data.description +
                        '</div>';
                    }
                  }
                }"
              >
                <option
                  v-if="selectedOptions[index]"
                  :value="selectedOptions[index].ticker"
                >
                  {{ selectedOptions[index].description }}
                </option>
              </TomSelect>
              <ErrorMessage :name="'position' + index" />
            </Field>
          </div>
          <div class="flex-0 w-16 sm:20 md:w-40">
            <div class="font-bold mb-2 whitespace-nowrap">
              Weight %
            </div>
            <Field :name="'weight' + index" label="Weight"
              rules="required|min_value:0|max_value:100"
              v-slot="{ field, meta }"
            >
              <div class="input-group">
                <input
                  type="text"
                  :class="{ 'has-error': meta.validated && !meta.valid }"
                  class="form-control py-2 px-1 border-gray-300 block sm:px-4"
                  placeholder="weight"
                  v-model="position.weight"
                  :name="field.name"
                  @blur="field.onBlur"
                  @input="field.onInput"
                  @change="field.onChange"
                />
              </div>
              <ErrorMessage :name="'weight' + index" />
            </Field>
          </div>
          <button
            type="button"
            class="btn btn-outline-dark mt-7 flex-shrink-0"
            :disabled="portfolioForm.composition.length < 2"
            @click="onDeletePosition(index)"
          >
            <Trash2Icon class="w-5 h-5 text-theme-24" />
          </button>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-outline-dark mt-5"
            @click="onAddPosition()"
          >
            <PlusIcon class="w-5 h-5" /> Add Position
          </button>
        </div>
      </div>
    </div>

    <div class="flex justify-between mt-9">
      <div>
        <button v-if="canDelete" class="btn btn-danger" type="button" @click="$emit('delete')">Delete</button>
      </div>
      <div class="flex justify-end gap-4">
        <button type="button" @click="onPortfolioClose" class="btn btn-outline-dark">Cancel</button>
        <button class="btn btn-primary">Save</button>
      </div>
    </div>
  </form>
</template>

<script>
import { defineComponent, reactive, ref, unref } from 'vue'
import { useForm, Field, ErrorMessage, defineRule } from "vee-validate"
import { useStore } from 'vuex'
import { required, min_value, max_value } from "@vee-validate/rules"
import { createId } from "@/domain/services/stringHelper"
import PortfolioFormTransformer from '@/domain/services/PortfolioFormTransformer'
import Benchmark from '@/components/benchmark/Main'
import { notTotalWeightOneHundred, zeroWeightPositions } from "@/domain/services/weightHelper";
import { compositionHasDuplicates, checkForDuplicates } from "@/domain/services/componentHelper";

export default defineComponent({
  emits: ['created', 'closed', 'delete'],
  components: { Field, ErrorMessage, Benchmark },
  props: {
    portfolio: Object,
    canDelete: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const store = useStore()
    const positions = ref([])
    const portfolioForm = reactive({
      name: '',
      description: '',
      benchmark: '',
      benchmarkDescription: '',
      composition: [],
      acl: []
    })
    const selectedOptions = ref([])

    const { handleSubmit, resetForm, setValues } = useForm()

    const setValuesInForm = () => {
      const values = {}
      values.name = portfolioForm.name
      values.description = portfolioForm.description
      values.benchmark = portfolioForm.benchmark
      portfolioForm.composition.forEach((elem, index) => {
        values['position' + index] = elem.ticker
        values['weight' + index] = elem.weight
      })
      values.acl = portfolioForm.acl
      setValues(values)
    }

    const onPortfolioClose = () => {
      emit('closed')
    }

    const onPortfolioSubmit = handleSubmit((_, actions) => {
      if (notTotalWeightOneHundred(portfolioForm.composition)) {
        actions.setFieldError('weight0', 'The weights don\'t sum up 100%')
        return
      }

      const zeroWeightValues = zeroWeightPositions(portfolioForm.composition)
      if (zeroWeightValues.length !== 0) {
        zeroWeightValues.forEach((position) => {
          actions.setFieldError('weight' + position.index, 'The weight can not be 0')
        })
        return
      }
      if (compositionHasDuplicates(portfolioForm.composition)) {
        const duplicateIndexes = [];
        const portfolioChecklist = checkForDuplicates(
          portfolioForm.composition
        );

        portfolioChecklist.filter((portfolio, index) => {
          if (portfolio === false) {
            duplicateIndexes.push(index);
          }
        });

        duplicateIndexes.forEach((el) => {
          actions.setFieldError(
            'position' + el,
            'Not duplicated positions allowed'
          );
        });

        return;
      }

      emit('created', portfolioForm)
    })

    const onAddPosition = () => {
      portfolioForm.composition.push({
        description: '',
        ticker: '',
        weight: null,
        id: createId()
      })
      resetForm()
      setValuesInForm()
    }

    const onDeletePosition = (index) => {
      portfolioForm.composition.splice(index, 1)
      selectedOptions.value.splice(index, 1)
      resetForm()
      setValuesInForm()
    }

    const onSearchPosition = (search) => {
      return store.dispatch('searchAssets', search)
        .then(searchResult => searchResult.getData)
    }

    defineRule("required", required)
    defineRule("min_value", min_value)
    defineRule("max_value", max_value)
    defineRule("hyphenName", (value, _, {field}) => {
      if (value.includes(' - ')) {
        return `Invalid ${field}, don't use hyphen before space`
      }
      return true
    })

    if (props.portfolio) {
      portfolioForm.name = unref(props.portfolio.name)
      portfolioForm.description = unref(props.portfolio.description)
      portfolioForm.benchmark = unref(props.portfolio.benchmark)
      portfolioForm.acl = unref(props.portfolio.acl)

      props.portfolio.getAssets.forEach(elem => {
        const e = { ...elem, id: createId() }
        selectedOptions.value.push({ ticker: elem.ticker, description: elem.description})
        portfolioForm.composition.push(e)
      })
      setValuesInForm()
    } else {
      onAddPosition()
    }

    return {
      portfolioForm,
      positions,
      selectedOptions,
      PortfolioFormTransformer,
      store,

      onPortfolioSubmit,
      onPortfolioClose,
      onAddPosition,
      onDeletePosition,
      onSearchPosition
    }
  }
})
</script>
