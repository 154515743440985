import StrategyKeyFactory from '@/domain/services/StrategyKeyFactory';
import PortfolioKeyFactory from '@/domain/services/PortfolioKeyFactory';

export const delimiterChar = '!@!';

export function createId(number) {
  if (typeof number === 'undefined') {
    number = 2;
  }
  return (Math.random() + 1).toString(36).substring(number);
}

export function mergeString(
  firstString,
  secondString,
  delimiter = delimiterChar
) {
  return firstString + delimiter + secondString;
}

export function transformToStrategyKey(
  strategyString,
  delimiter = delimiterChar
) {
  const name = getNameInData(strategyString);
  const data = strategyString.split(delimiter);
  const strategyKeyData = { owner: data[0], name: name };

  return StrategyKeyFactory.get(strategyKeyData);
}

export function transformToPortfolioKey(
  portfolioString,
  delimiter = delimiterChar
) {
  const name = getNameInData(portfolioString);
  const data = portfolioString.split(delimiter);
  const portfolioKeyData = { owner: data[0], name: name };

  return PortfolioKeyFactory.get(portfolioKeyData);
}

export function capitalizeFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function mergeNameOwner(data) {
  return mergeString(data.getOwner, data.getName);
}

export function getNameInData(data, char = delimiterChar) {
  const name = data.split(char);

  if (name.length > 2) {
    return name.slice(1).join(char);
  }
  /* If the data is not owner-delimiter-name*/
  if (name.length < 2) {
    return name;
  }
  /* When the name ends with the delimiter */
  if (name[1] === '') {
    return data;
  }
  return name[1];
}

export function getOwnerInData(data, char = delimiterChar) {
  const name = data.split(char);

  return name[0];
}

export function removeComma(value) {
  if (typeof value !== 'number') {
    return value.replaceAll(',', '');
  }
  return value;
}
