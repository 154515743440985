export default class PeriodicTransfer {
  constructor (data) {
    this.id = data.id
    this.quantity = data.quantity
    this.currency = data.currency
    this.date = data.date
    this.frequency = data.frequency
  }

  get getId () {
    return this.id
  }

  get getQuantity () {
    return this.quantity
  }

  get getCurrency () {
    return this.currency
  }

  get getDate () {
    return this.date
  }

  get getFrequency () {
    return this.frequency
  }

  get isEmpty () {
    return this.getQuantity === "" && this.getCurrency === "" && this.getDate === "" && this.getFrequency === ""
  }

  setData (key, data) {
    this[key] = data

    return this
  }
}
