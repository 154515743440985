<template>
  <div>
    <div class="intro-y text-3xl font-bold mt-6 mb-5">Trading</div>
    <div class="text-xl">
      Obtain the movements needed to change between two portfolios
    </div>
    <div class="tables-container mt-4 px-4 py-6 bg-white rounded-md">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-4">
        <div class="h-full">
          <p class="text-base font-bold mb-2">Initial portfolio</p>
          <NameOwnerSelector
            v-if="isInitialPortfolioSelected"
            :key="mergeNameOwner(initialPortfolio)"
            :options="options"
            :selected="mergeNameOwner(initialPortfolio)"
            placeholder="Select initial portfolio"
            @change="changeInitialPortfolio"
          />
          <InitialPortfolioTable
            :portfolios="initialPortfolioAssets"
            :portfolioInfo="initialPortfolio"
            :totalValue="totalValue"
            :enableInput="portfoliosSelected"
            @updateTickerValue="updateTickerValue"
            @updatePortfolios="updatePortfolios"
          />
        </div>
        <div class="h-full">
          <p class="text-base font-bold mb-2">Target portfolio</p>
          <NameOwnerSelector
            v-if="isTargetPortfolioSelected"
            :key="mergeNameOwner(targetPortfolio)"
            :options="options"
            :selected="mergeNameOwner(targetPortfolio)"
            placeholder="Select initial portfolio"
            @change="changeTargetPortfolio"
          />
          <TargetPortfolioTable
            :portfolios="targetPortfolioAssets"
            :portfolioInfo="targetPortfolio"
            :totalValue="totalValue"
          />
        </div>
        <div class="h-full">
          <p class="text-base font-bold mb-2">Buy</p>
          <div class="table-wrapper flex justify-center my-5 border rounded-md">
            <div v-if="!showBuySellTables" class="inline-flex items-center">
              <span> Pick a portfolio to simulate trading</span>
            </div>
            <div v-else class="w-full h-full p-4 overflow-x-hidden">
              <BuySellTable :data="buyTableData" :totalValue="totalValue" />
            </div>
          </div>
        </div>
        <div class="h-full">
          <p class="text-base font-bold mb-2">Sell</p>
          <div class="table-wrapper flex justify-center my-5 border rounded-md">
            <div v-if="!showBuySellTables" class="inline-flex items-center">
              <span> Pick a portfolio to simulate trading</span>
            </div>
            <div v-else class="w-full h-full p-4 overflow-x-hidden">
              <BuySellTable :data="sellTableData" :totalValue="totalValue" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import InitialPortfolioTable from '@/components/trading/InitialPorfolioTable.vue';
import TargetPortfolioTable from '@/components/trading/TargetPorfolioTable.vue';
import BuySellTable from '@/components/trading/BuySellTable.vue';
import PortfolioAsset from '@/domain/models/PortfolioAsset';
import {
  roundToTwoDecimals,
  turnCurrencyStringToNumber,
} from '@/domain/services/numberHelper';
import NameOwnerSelector from '@/components/name-owner-selector/Main.vue';
import {
  mergeNameOwner,
  transformToPortfolioKey,
  removeComma
} from '@/domain/services/stringHelper';
import {
  INITIAL,
  TARGET,
  BUY,
  SELL,
  noDataLiteral,
  cashTicker,
} from '@/domain/services/componentHelper';

export default defineComponent({
  components: {
    InitialPortfolioTable,
    TargetPortfolioTable,
    BuySellTable,
    NameOwnerSelector,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const options = ref([]);
    const initialPortfolio = ref({});
    const targetPortfolio = ref({});
    const initialPortfolioAssets = ref();
    const targetPortfolioAssets = ref();
    const portfoliosSelected = ref(false);
    const showBuySellTables = ref(false);
    const buyTableData = ref();
    const sellTableData = ref();
    const tickersAndValues = ref([]);
    const totalValue = ref(0);
    const buyData = ref([]);
    const sellData = ref([]);
    const selectedPortfolio = ref();

    const isInitialPortfolioSelected = computed(() => {
      return Object.keys(initialPortfolio).length > 0 && options.value.length;
    });
    const isTargetPortfolioSelected = computed(() => {
      return Object.keys(targetPortfolio).length > 0 && options.value.length;
    });

    const cashAsset = new PortfolioAsset({
      isin: `${noDataLiteral}`,
      ticker: cashTicker,
      description: 'Cash ($)',
      weight: 0,
    });

    const updatePortfolios = (newPortfolios) => {
      initialPortfolioAssets.value = newPortfolios;
    };

    const isNotEmpty = (portfolio) => {
      return (
        Object.keys(portfolio).length !== 0 &&
        portfolio.name !== '' &&
        portfolio.owner !== ''
      );
    };

    const checkAllPortfoliosSelected = () => {
      portfoliosSelected.value =
        isNotEmpty(initialPortfolio.value) && isNotEmpty(targetPortfolio.value);
    };

    const sumTotalValues = () => {
      if (tickersAndValues.value) {
        totalValue.value = tickersAndValues.value
          .map((el) => el.value)
          .reduce((prev, next) => prev + next);
      }
    };

    const setTickersDefaultValue = () => {
      tickersAndValues.value = initialPortfolioAssets.value;
      tickersAndValues.value.map((el) => (el.value = 0));
    };

    const checkTickerValue = (ticker, value) => {
      const foundTicker = tickersAndValues.value.find(
        (el) => el.ticker === ticker
      );

      if (foundTicker) {
        foundTicker.value = value;
      }

      sumTotalValues();
    };

    const getTableDataDescription = (dataTable, data, ticker, value) => {
      buyData.value = [];
      sellData.value = [];

      const dataElements = Object.keys(data).map((key) => ({
        ticker: key,
        value: data[key],
      }));

      for (let element of dataElements) {
        store
          .dispatch('searchAssetData', element.ticker)
          .then((res) => {
            const getDescription =
              !res || !res.description ? noDataLiteral : res.description;
            const getIsin = !res || !res.isin ? noDataLiteral : res.isin;

            const tableData = {
              ticker: element.ticker,
              description: getDescription,
              isin: getIsin,
              value: roundToTwoDecimals(element.value),
            };

            const initialPortfolioAsset = initialPortfolioAssets.value.filter(
              (asset) => element.ticker === asset.ticker
            );

            const valueToDivide =
              initialPortfolioAsset.length > 0 &&
              initialPortfolioAsset[0].ticker === element.ticker
                ? parseFloat(removeComma(initialPortfolioAsset[0].value))
                : parseFloat(value);

            if (dataTable === BUY) {
              const isInInitialPortfolio =
                initialPortfolioAssets.value.filter(
                  (asset) =>
                    element.ticker === asset.ticker && parseFloat(removeComma(asset.value)) > 0
                ).length > 0;

              tableData.variation = isInInitialPortfolio
                ? roundToTwoDecimals((element.value / valueToDivide) * 100)
                : -1;

              buyData.value.push(tableData);
              buyTableData.value = buyData.value;
              return;
            }

            const isInTargetPortfolio =
              targetPortfolioAssets.value.filter(
                (asset) => element.ticker === asset.ticker
              ).length > 0;

            tableData.variation = isInTargetPortfolio
              ? roundToTwoDecimals((element.value / valueToDivide) * 100)
              : -100;

            sellData.value.push(tableData);
            sellTableData.value = sellData.value;

            showBuySellTables.value = true;
          })
          .catch((error) => console.log(error));
      }
    };

    const updateTargetPortfolioAssets = () => {
      const rawTargetPortfolioAssets = targetPortfolioAssets.value.map((el) => {
        const getIsin = !el || !el.isin ? noDataLiteral : el.isin;

        const newTargetData = {
          isin: getIsin,
          ticker: el.ticker,
          description: el.description,
          variation: el.variation,
          value: roundToTwoDecimals(totalValue.value * (el.variation / 100)),
        };

        return newTargetData;
      });

      targetPortfolioAssets.value = rawTargetPortfolioAssets;
    };

    const updateValueToNumber = (data) => {
      if (typeof data === 'string') {
        return turnCurrencyStringToNumber(data);
      }
      return data;
    };

    const updateTickerValue = (ticker, value) => {
      if (portfoliosSelected.value) {
        const valueNumber = turnCurrencyStringToNumber(value);
        checkTickerValue(ticker, valueNumber);

        const rawInitialPortfolioAssets = initialPortfolioAssets.value.map(
          (el) => {
            const key = el.ticker;
            const asset = {};
            asset[key] = updateValueToNumber(el.value);

            return asset;
          }
        );

        const assets = Object.assign({}, ...rawInitialPortfolioAssets);

        const data = Object.create(targetPortfolio.value);

        data.assets = assets;

        updateTargetPortfolioAssets();

        data.name = route.query.name;
        data.owner = targetPortfolio.value.owner;
        data.portfolio = targetPortfolio.value.name;

        store
          .dispatch('updateStrategyTrading', data)
          .then((res) => {
            getTableDataDescription(BUY, res.buy, ticker, valueNumber);
            getTableDataDescription(SELL, res.sell, ticker, valueNumber);
          })
          .catch((error) => console.log('error', error));
      }
    };

    const checkInitialPortfolioHasCashAsset = (assets) => {
      if (!assets.some((asset) => asset.ticker === cashAsset.ticker)) {
        initialPortfolioAssets.value.push(cashAsset);
      }
    };

    const getTargetPortfolioData = (data) => {
      const newTargetDataList = data.map((el) => {
        const getIsin = !el || !el.isin ? noDataLiteral : el.isin;

        const newTargetData = {
          isin: getIsin,
          ticker: el.ticker,
          description: el.description,
          variation: el.weight,
          value: 0,
        };
        return newTargetData;
      });
      targetPortfolioAssets.value = newTargetDataList;
    };

    const getStrategyData = () => {
      checkAllPortfoliosSelected();
      setTickersDefaultValue();
    };

    const reset = () => {
      buyTableData.value = null;
      sellTableData.value = null;
      buyData.value = [];
      sellData.value = [];
      showBuySellTables.value = false;
      totalValue.value = 0;
    };

    const getSelectedPortfolioData = (portfolio) => {
      return options.value.find((option) => {
        return option.name === portfolio.name;
      });
    };

    const getData = (type, portfolio) => {
      reset();

      if (type === 'initial') {
        const portfolioData = getSelectedPortfolioData(portfolio);
        initialPortfolio.value = {};
        initialPortfolioAssets.value = [];

        initialPortfolio.value = portfolioData;
        initialPortfolioAssets.value = portfolioData.assets;

        getStrategyData();
        checkInitialPortfolioHasCashAsset(portfolioData.assets);
        return;
      }
      const portfolioData = getSelectedPortfolioData(portfolio);
      targetPortfolio.value = {};
      targetPortfolioAssets.value = [];

      targetPortfolio.value = portfolioData;
      targetPortfolioAssets.value = portfolioData.assets;

      getStrategyData();
      getTargetPortfolioData(targetPortfolioAssets.value);
      return;
    };

    const strategyData = {
      name: route.query.name,
      owner: route.query.owner,
    };

    const changeInitialPortfolio = (portfolio) => {
      initialPortfolio.value = transformToPortfolioKey(portfolio);
      getData(INITIAL, initialPortfolio.value);
    };

    const changeTargetPortfolio = (portfolio) => {
      targetPortfolio.value = transformToPortfolioKey(portfolio);
      getData(TARGET, targetPortfolio.value);
    };

    store
      .dispatch('getStrategy', strategyData)
      .then((specificStrategy) => {
        options.value = specificStrategy.portfolios;
        const noValueStrategy = {
          name: '',
          owner: '',
        };

        options.value.unshift(noValueStrategy);
        initialPortfolio.value = options.value[0];
        targetPortfolio.value = options.value[0];
      })
      .catch((error) => {
        console.log(error);
      });

    return {
      INITIAL,
      TARGET,
      options,
      initialPortfolio,
      targetPortfolio,
      initialPortfolioAssets,
      targetPortfolioAssets,
      showBuySellTables,
      buyTableData,
      sellTableData,
      totalValue,
      portfoliosSelected,
      selectedPortfolio,
      isInitialPortfolioSelected,
      isTargetPortfolioSelected,
      mergeNameOwner,

      getData,
      updateTickerValue,
      updatePortfolios,
      changeInitialPortfolio,
      changeTargetPortfolio,
    };
  },
});
</script>

<style lang="scss" scoped>
.tables-container {
  max-width: 1400px;
}

.table-wrapper {
  min-height: 252px;
  display: flex;
  justify-content: center;
}
</style>
