import TradingSearchItem from '@/domain/models/TradingSearchItem';

class TradingSearchItemFactory {
  get(data) {
    const tradingItem = {
      description: data.description,
      isin: data.isin,
    };

    return new TradingSearchItem(tradingItem);
  }
}

export default new TradingSearchItemFactory();
