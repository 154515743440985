import DataListItem from "@/domain/models/DataListItem"

class DataListSearchItemFactory {
  get (data) {
    const dataListItem = {
      category: data.category,
      description: data.description,
      last_update: data.last_update,
      since: data.since,
      ticker: data.ticker
    }

    return new DataListItem(dataListItem)
  }
}

export default new DataListSearchItemFactory()
