import RiskIndicatorChart from "@/domain/models/RiskIndicatorChart"
import dateHelper from "@/domain/services/dateHelper"

class IndexRiskIndicatorChartFactory {
  get (data) {
    const names = Object.keys(data.series)
    const riskName = names.find(element => {
      return element.indexOf('indicator/') >= 0
    })
    const indexName = names.find(element => {
      return element.indexOf('indicator/') === -1
    })
    const dataRiskIndicator = {
      name: indexName,
      current: null,
      graphic: parseGraphic(data, indexName, riskName),
      from: data.from,
      to: data.to
    }

    return new RiskIndicatorChart(dataRiskIndicator)
  }
}

const parseGraphic = (data, indexName, riskName) => {
  const ret = {}
  data.index.forEach((date, index) => {
    const ts = (new Date(date)).getTime()
    ret[ts] = {
      index: data.series[indexName][index],
      risk: data.series[riskName][index],
    }
  })
  return ret
}

export default new IndexRiskIndicatorChartFactory()
