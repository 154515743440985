<template>
  <div>
    <div class="text-xl pb-5 font-bold">
      Portfolio Composition
    </div>
    <div class="grid xl:grid-cols-3 sm:grid-cols-2 gap-4">
      <div v-for="portfolio in data" :key="portfolio" class="bg-gray-300 p-4 rounded-md border-gray-400 border-2">
        <div class="font-bold text-center mb-4">
          {{ portfolio.name }}
        </div>
        <div class="flex gap-3">
          <span>Equity exposure:</span>
          <span>{{ portfolio.getEquityExposure.getValue }}%</span>
        </div>
        <table class="w-full mt-4">
          <thead>
            <tr>
              <th class="p-1">Positions</th>
              <th>Weight</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="position in portfolio.getAssets" :key="position">
              <td class="pb-2 cursor-pointer" :class="{'cursor-auto': isCashTicker}">
                <Tippy
                  :id="position.getTicker"
                  tag="span"
                  content="Loading..."
                  :options="{
                    allowHTML: true,
                    hideOnClick: true,
                    interactive: true,
                    trigger: 'click',
                    onCreate(instance) {
                      instance._isLoading = false
                      instance._error =  null
                    },
                    onShow(instance) {
                      if (instance._isLoading || instance._error) {
                        return
                      }
                      instance._isLoading = true
                      onPortfolioPopupShow(position.getTicker)
                        .then((result) => {
                          instance.setContent(result)
                          instance._isLoading = false
                        })
                    },
                    onHidden(instance) {
                      instance.setContent('Loading...')
                    },
                    onShown(instance) {
                      onShownHandler(instance);
                    },
                  }"
                  
                >
                  {{ position.getDescription }}
                </Tippy>
              </td>
              <td align="center" class="pb-2">
                {{ position.weight }}%
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="text-xl p-5 pt-14">
      Implementation
    </div>
    <div class="grid xl:grid-cols-3 sm:grid-cols-2 gap-4">
      <div class="graphic rounded-md border-gray-400 border-2">
        <VueEcharts :option="{
          grid: {
            left: '15%'
          },
          xAxis: {
            type: 'category',
            data: yData
          },
          yAxis: {
            type: 'value',
            splitNumber: 10,
            axisLabel: {
              formatter: '{value} %'
            }
          },
          series: {
            name: 'Equity',
            formatter: '{value} %',
            type: 'bar',
            showSymbol: false,
            sampling: 'lttb',
            zlevel: 0,
            z: 0,
            itemStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: 'rgba(96, 207, 226, 0.8)'
                }, {
                  offset: 1, color: '#182a5c'
                }],
                global: false // default is false
              }
            },
            data: xData
          },
          tooltip: {
            trigger: 'axis',
            formatter: '{b}<br>{a} <b>{c}%</b>'
          }
        }" style="width: 100%; height: 400px" ref="bottom_bottom_chart" />
      </div>
      <div class="p-4 rounded-md border-gray-400 border-2 detail-container">
        <div class="font-bold mb-4 detail-container-title">
          Details
        </div>
        <div v-html="message" class="detail-container-contents" />
      </div>
      <div class="bg-gray-300 p-4 rounded-md border-gray-400 border-2">
        <div class="font-bold mb-4">
          Current simulated composition
        </div>
        <table class="w-full mt-4">
          <thead>
            <tr><th class="p-1">Positions</th><th>Weight</th></tr>
          </thead>
          <tbody>
            <tr v-for="simulation in data[currentPorfolioIndex].getAssets" :key="simulation">
              <td class="pb-2 cursor-pointer" :class="{'cursor-auto': isCashTicker}">
                <Tippy
                  :id="simulation.getTicker"
                  tag="span"
                  content="Loading..."
                  :options="{
                    allowHTML: true,
                    hideOnClick: true,
                    interactive: true,
                    trigger: 'click',
                    onCreate(instance) {
                      instance._isLoading = false
                      instance._error =  null
                    },
                    onShow(instance) {
                      if (instance._isLoading || instance._error) {
                        return
                      }
                      instance._isLoading = true
                      onPortfolioPopupShow(simulation.getTicker)
                        .then((result) => {
                          instance.setContent(result)
                          instance._isLoading = false
                        })
                    },
                    onHidden(instance) {
                      instance.setContent('Loading...')
                    },
                    onShown(instance) {
                      onShownHandler(instance);
                    },
                  }"
                >
                  {{ simulation.getDescription }}
                </Tippy>
              </td>
              <td align="center">{{ simulation.weight }}%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onBeforeUnmount } from 'vue'
import { VueEcharts } from 'vue3-echarts'
import { percent } from '@/domain/services/numberHelper'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { addHtmlText } from '@/domain/services/TooltipHelper'
import { cashTicker } from '@/domain/services/componentHelper' 

export default defineComponent({
  components: {
    VueEcharts
  },
  props: {
    data: Object,
    currentPorfolioIndex: Number,
    message: String
  },
  setup(props) {
    const store = useStore()
    const ticker = ref()
    const router = useRouter()
    const tippyContent = ref()
    const isCashTicker = ref(false)

    const xData = props.data.map(portfolio => {
      return Math.round(portfolio.getEquityExposure.getValue)
    })
    const yData = props.data.map(portfolio => portfolio.getName)

    const onPortfolioPopupShow = async (ticker) => {
      const tickerHelp = await store.dispatch('getHelpTicker', ticker);

      if (ticker !== cashTicker) {
        isCashTicker.value = false;
        return addHtmlText(tickerHelp.html);
      }
      
      isCashTicker.value = true;
      return tickerHelp.html;
    }

    const goToTickerData = () => {
      router.push({ name: 'data', query: { ticker: ticker.value } });
    }

    onBeforeUnmount(() => {
      if (tippyContent.value) {
        tippyContent.value.removeEventListener('click', goToTickerData, false);
      }
    })

    const onShownHandler = (e) => {
      ticker.value = e.reference.id;
      if (e.reference.id !== cashTicker) {
        tippyContent.value = document.querySelector('.tippy-content');
        tippyContent.value.addEventListener('click', goToTickerData, true);
      }
    };

    return {
      xData,
      yData,
      isCashTicker,

      percent,
      onPortfolioPopupShow,
      onShownHandler
    }
  },
})
</script>

<style lang="scss" scoped>
  .detail-container-contents {
    :deep(table) {
      margin: 0 auto;
    }
  }
</style>
