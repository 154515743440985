<template>
  <div class="date-range">
    <div class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4">
      <CalendarIcon class="w-4 h-4" />
    </div>
    <DateRangePicker
      controlContainerClass="form-control reportrange-text date-range__picker"
      autoApply
      v-model="dates"
      @update="onSelect"
      @startSelection="onStartSelection"
      @finishSelection="onFinishSelection"
      showDropdowns
      :linkedCalendars="false"
      :opens="openPosition"
    >
      <template #input>
        <div class="ml-10">
          {{ dayjs(dates.startDate).format("D MMM, YYYY") }} - {{ dayjs(dates.endDate).format("D MMM, YYYY") }}
        </div>
      </template>
    </DateRangePicker>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import DateRangePicker from 'daterange-picker-vue3'
import 'daterange-picker-vue3/dist/daterange-picker-vue3.css'
import dayjs from "dayjs";

export default defineComponent({
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    openPosition: String
  },
  emits: ["update:modelValue", "selected"],
  components: { DateRangePicker },
  setup(props, { emit }) {
    const dates = ref({
      startDate: props.modelValue[0],
      endDate: props.modelValue[1],
    });

    const onSelect = (selectedDates) => {
      emit("update:modelValue", [selectedDates.startDate, selectedDates.endDate])
      emit("selected", [selectedDates.startDate, selectedDates.endDate])
    }

    const onStartSelection = (date) => {
      dates.value.startDate = date
    }

    const onFinishSelection = (date) => {
      dates.value.endDate = date
    }

    return {
      dates,
      dayjs,

      onSelect,
      onStartSelection,
      onFinishSelection,
    }
  },
})
</script>

<style lang="scss" scoped>
  .date-range {
    :deep(.date-range__picker) {
      overflow: unset;
      background: unset;
    }
  }
</style>
