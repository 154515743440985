import axios from '@/plugins/axios';

const URL_GET_RISK_INDICATOR_HELP = '/api/help?topic=risk-indicator'
const URL_GET_TICKER_HELP = '/api/help?ticker={ticker}'

export function getHelpRiskIndicator() {
  const url = URL_GET_RISK_INDICATOR_HELP

  return axios.get(url)
    .then(res => res.data)
    .catch(error => Promise.reject(error));
}

export function getHelpTicker(ticker) {
  const url = URL_GET_TICKER_HELP
    .replace('{ticker}', encodeURIComponent(ticker))

  return axios.get(url)
    .then(res => res.data)
    .catch(error => Promise.reject(error));
}
