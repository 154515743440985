export default class RiskIndicatorChart {
  constructor (data) {
    this.name = data.name
    this.current = null
    this.graphic = data.graphic
    this.from = data.from
    this.to = data.to
  }

  get getName () {
    return this.name
  }

  get getCurrent () {
    return this.current
  }

  setCurrent (current) {
    this.current = current
  }

  get getGraphic () {
    return this.graphic
  }

  get getFrom () {
    return this.from
  }

  get getTo() {
    return this.to
  }
}
