<template>
  <div class="switch">
    <div class="switch__icon-group" @click="onChangeSwitch">
      <ToggleLeftIcon class="disabled" v-if="!modelValue" />
      <ToggleRightIcon class="enabled" v-else />
    </div>
    <label><slot /></label>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: "Switch",
  emits: ["changeSwitch"],
  props: {
    modelValue: Boolean,
  },
  setup(props, { emit }) {
    const onChangeSwitch = () => {
      emit('update:modelValue', !props.modelValue)
      emit('changeSwitch')
    }

    return {

      onChangeSwitch
    }
  },
})
</script>

<style lang="scss" scoped>
  .switch {
    @apply flex gap-2 items-center;
    .disabled {
      @apply text-theme-4;
    }
    .enabled {
      @apply text-theme-11;
    }
  }
</style>
