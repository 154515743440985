<template>
  <div class="py-6">
    <div v-if="store.getters.hasErrors">
      <Error
        :errors="store.getters.getErrors"
        @dismiss="creationError = null"
      />
    </div>
    <div
      class="flex flex-col lg:flex-row justify-between"
    >
      <div
        class="flex flex-col items-stretch gap-4 lg:flex-row lg:items-center"
      >
        <div class="flex items-center justify-between">
          <span class="text-3xl font-bold truncate">
            Strategies
          </span>
          <div class="flex gap-2 lg:hidden whitespace-nowrap">
            <button
              v-if="selectedStrategy && strategyBelongsToUser"
              @click="goToModifyStrategy"
              class="btn btn-secondary"
            >
              Modify
            </button>
            <button @click="goToCreateStrategy" class="btn btn-primary">
              Create Strategy
            </button>
          </div>
        </div>
        <div v-if="hasStrategies" class="select-group flex-grow w-full md:w-96">
          <NameOwnerSelector
            :key="getNameOwner(selectedStrategy)"
            :options="options"
            :selected="getNameOwner(selectedStrategy)"
            placeholder="Type to get a list of strategies"
            @change="changeStrategy"
          />
        </div>
      </div>
      <div class="hidden items-center gap-2 lg:flex">
        <button
          v-if="strategyBelongsToUser"
          @click="goToModifyStrategy"
          class="btn btn-secondary"
        >
          Modify
        </button>
        <button @click="goToCreateStrategy" class="btn btn-primary">
          Create Strategy
        </button>
      </div>
    </div>
    <div v-if="!hasStrategies" class="bg-white my-4 rounded-lg">
      <div class="text-center p-10">
        There are no strategies created
      </div>
    </div>
    <div v-else class="bg-white my-4 rounded-lg">
      <div
        class="flex flex-col gap-y-5 gap-x-5 items-start lg:flex-row lg:justify-between border-b border-gray-400 px-8 py-4"
      >
        <div class="order-2 flex flex-row gap-x-2 br-5">
          <TradingButton
            name="strategy-trading"
            :strategyName="selectedStrategy.getName"
            :strategyOwner="selectedStrategy.getOwner"
          />
          <SharingButton
            :key="selectedStrategy.getName"
            v-if="strategyBelongsToUser"
            :acl="selectedStrategy.getACL"
            @updateACL="updateACL"
          />
          <MultiExport :exportTypes="types" @export="exportType" />
          <NotificationButton
            :isNotificationOn="isNotificationOn"
            @change="updateNotification"
          />
        </div>
        <div class="order-1 flex flex-col gap-y-5 lg:flex-row lg:gap-x-6">
          <DateToolbar
            :key="availableDate"
            :id="selectedStrategy.getName"
            :isMaxSelected="isMaxSelected"
            :minDate="availableDate"
            :selected="selectedDateRange"
            @search="doSearchSpecific"
          />
          <GraphicDropdown v-model:serie="serie" @update:serie="getData" />
        </div>
      </div>
      <div class="py-4 px-8">
        <div
          v-if="!stats.portfolioStats || !stats.performanceStats"
          class="flex flex-col items-center"
        >
          <div class="w-8 h-8 inline-block">
            <LoadingIcon icon="puff" class="w-8 h-8" />
          </div>
          <div class="text-center text-xs">Loading...</div>
        </div>
        <div v-else>
          <div>
            <span class="text-xl font-bold">
              Performance
            </span>
            <div class="mt-4">
              <StrategyTopSection
                class="top"
                :data="stats.performanceStats"
                :graphicData="stats.strategyData"
                :strategyName="selectedStrategy.getName"
                v-model:serie="serie"
                @update:serie="getData"
              />
            </div>
          </div>
          <div
            v-if="selectedStrategy"
            class="border-b-2 border-dashed pt-5 pl-5 pb-10"
          >
            <div class="mb-4">
              <p class="text-xl">
                Risk Indicator
              </p>
              <p>
                {{ indicator }}
              </p>
            </div>
            <div>
              <p class="text-xl">
                Description
              </p>
              <p>
                {{ description }}
              </p>
            </div>
          </div>
          <div>
            <StrategyBottomSection
              class="bottom mt-8"
              :data="stats.portfolioStats"
              :currentPorfolioIndex="stats.currentPorfolioIndex"
              :message="message"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'

import DateToolbar from '@/components/date-toolbar/Main.vue'
import MultiExport from '@/components/multi-export/Main.vue'
import StrategyBottomSection from '@/components/strategy-bottom-section/Main.vue'
import StrategyTopSection from '@/components/strategy-top-section/Main.vue'
import dateRange from '@/domain/services/dateHelper'
import TradingButton from '@/components/trading/Trading-button.vue'
import NameOwnerSelector from '@/components/name-owner-selector/Main'
import { mergeString, transformToStrategyKey } from "@/domain/services/stringHelper";
import GraphicDropdown from '@/components/graphic-dropdown/Main.vue'
import SharingButton from "@/components/acl/SharingButton";
import ACLTransformer from "@/domain/services/ACLTransformer";
import NotificationButton from '@/components/notification-button/Main.vue'
import { updateNotificationPreference } from '@/domain/services/NotificationHelper'
import Error from '@/components/error/Main'


export default defineComponent({
  components: {
    SharingButton,
    DateToolbar,
    MultiExport,
    StrategyTopSection,
    StrategyBottomSection,
    GraphicDropdown,
    NotificationButton,
    TradingButton,
    NameOwnerSelector,
    Error
  },
  setup() {
    const store = useStore()
    const options = ref([])
    const selectedStrategy = ref()
    const description = ref()
    const indicator = ref()
    const portfolios = ref([])
    let globalRange = dateRange.month()
    const stats = reactive({
      performanceStats: null,
      strategyData: null,
      portfolioStats: null,
      currentPorfolioIndex: null
    })
    const hasStrategies = ref(false)
    const message = ref()
    const route = useRoute()
    const router = useRouter()
    const strategyBelongsToUser = ref(false)
    const creationError = ref()
    const serie = ref('return')
    const availableDate = ref()
    const isNotificationOn = ref()
    const username = store.getters.getLoggedUser;
    const isMaxSelected = ref(false)
    const selectedData = ref({})
    const selectedDateRange = ref('year')
    const dateFrom = ref()
    const dateTo = ref()
    const MAX_TYPE = 'max'
    const TYPE = 'strategy'

    store.dispatch("getStrategiesKeys")
      .then(strategyKeyList => {
        options.value = strategyKeyList
        hasStrategies.value = strategyKeyList.length > 0
        if (strategyKeyList.length > 0) {
          let initiallySelectedIndex = strategyKeyList.findIndex(strategy => strategy.getName === route.query.name)
          if (initiallySelectedIndex < 0) {
            initiallySelectedIndex = 0
          }

          selectedStrategy.value = strategyKeyList[initiallySelectedIndex]
          description.value = strategyKeyList[initiallySelectedIndex].getDescription
          doSearch(dateRange.year())
        }
      })
      .catch(error => {
        store.commit('setErrors', error.response.data)
        hasStrategies.value = false
      })

    const goToModifyStrategy = () => {
      router.push({
        name: 'modify-strategy',
        query: {
          name: selectedStrategy.value.getName,
        },
      })
    }

    const goToCreateStrategy = () => {
      router.push({
        name: 'new-strategy',
        query: {
        },
      })
    }

    const doSearch = (range) => {
      store.commit('setErrors', null)
      globalRange = range
      stats.performanceStats = null
      stats.strategyData = null
      stats.portfolioStats = null
      stats.currentPorfolioIndex = null
      const strategyData = {
        name: selectedStrategy.value.getName,
        owner: selectedStrategy.value.getOwner
      }

      if (isMaxSelected.value) {
        store.dispatch('getStrategyMinDate', strategyData)
          .then(strategyInformation => {
            availableDate.value = strategyInformation.available

            selectedData.value = {
              from: new Date(Date.parse(availableDate.value)),
              to: dateRange.lastValidDay(),
              name: selectedStrategy.value.getName,
              owner: selectedStrategy.value.getOwner
            }

            dateFrom.value = new Date(Date.parse(availableDate.value)),
            dateTo.value = dateRange.lastValidDay()
          })
      }

      store.dispatch("getStrategy", strategyData)
        .then(specificStrategy => {
          selectedStrategy.value.acl = specificStrategy.acl

          store.dispatch("getHelpRiskIndicators")
            .then((help) => {
              message.value = help.html
            })

          description.value = specificStrategy.getDescription
          portfolios.value = [...specificStrategy.getPortfolios]

          if (!isMaxSelected.value) {
            selectedData.value = {
              from: range[0],
              to: range[1],
              name: selectedStrategy.value.getName,
              owner: selectedStrategy.value.getOwner
            }

            dateFrom.value = range[0]
            dateTo.value = range[1]
          }

          strategyBelongsToUser.value = selectedStrategy.value.getOwner === store.getters.getLoggedUser

          store.dispatch("getStrategyPerformanceStats", selectedData.value)
            .then(performanceStats => {
              const data = {
                name: performanceStats.getName,
                owner: performanceStats.getOwner,
                from: dateFrom.value,
                to: dateTo.value,
                series: serie.value
              }

              store.dispatch('getStrategyData', data)
                .then(strategyData => {
                  stats.strategyData = strategyData
                  stats.performanceStats = performanceStats

                  const strategyIndicator = strategyData.strategyIndicator

                  store.dispatch('searchIndicators', strategyIndicator)
                    .then(searchResult => {
                      indicator.value = `${strategyIndicator} - ${searchResult.data[0].description}`
                    })

                  updateSection(specificStrategy, strategyData.getLastItemInPortfolio)
                  if (!isMaxSelected.value) {
                    availableDate.value = stats.strategyData.available
                  }
                })
              getNotificationPreference()
            })
              .catch((error) => {
                store.commit('setErrors', error.response.data)
              })
        })
        .catch(error => {
          store.commit('setErrors', error.response)
        })
    }

    const getNameOwner = (strategy) => {
      return mergeString(strategy.getOwner, strategy.getName)
    }

    const updateSection = (strategy, currentPorfolioIndex) => {
      const portfolios = strategy.getPortfolios.map(portfolio => {
        const equityExposure = strategy.getEquityExposure.find(eq => eq.getName === portfolio.getName)
        portfolio.setEquityExposure(equityExposure)
        return portfolio
      })
      stats.portfolioStats = strategy.getPortfolios
      stats.currentPorfolioIndex = currentPorfolioIndex
    }

    const doSearchSpecific = ({ _, range }, type) => {
      isMaxSelected.value = type === MAX_TYPE
      selectedDateRange.value = type

      doSearch(range)
    }

    const changeStrategy = (strategy) => {
      selectedStrategy.value = transformToStrategyKey(strategy)
      doSearch(globalRange)
    }

    const getData = () => {
      availableDate.value = null
      doSearch(globalRange)
    }

    const exportType = (key) => {
      const data = {
        name: selectedStrategy.value.getName,
        owner: selectedStrategy.value.getOwner,
        format: key,
        from: dateRange.format(globalRange[0]),
        to: dateRange.format(globalRange[1])
      }
      store.dispatch('strategiesExportDoc', data)
        .then(contents => {
          const url = window.URL.createObjectURL(contents.data)
          const a = document.createElement('a')
          a.href = url
          a.download = data.name + '-' + data.from + '--' + data.to + '.xlsx'
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .catch(error => {
          const fr = new FileReader();
          fr.onload = (e) => {
            creationError.value = JSON.parse(e.target.result).error
          };
          store.commit('setErrors', error.response.data)

          fr.readAsText(error.response.data)
        })
    }

    const updateACL = (acl) => {
      store.dispatch('updateACLStrategy', ACLTransformer.get(selectedStrategy.value.getOwner,
          selectedStrategy.value.getName,
          acl))
          .then(() => {
            doSearch(globalRange)
          })
    }

    const updateNotification = () => {
      const selectedData = {
        username: username,
        owner: selectedStrategy.value.getOwner,
        name: selectedStrategy.value.getName
      }

      isNotificationOn.value = updateNotificationPreference(TYPE, store, selectedData, isNotificationOn.value)
    }

    const getNotificationPreference = () => {
      const data = {
        username: username,
        key: `${TYPE}/${selectedStrategy.value.getOwner}/${selectedStrategy.value.getName}`,
      }

      store
        .dispatch('getNotificationPreference', data)
        .then((res) => {
          isNotificationOn.value = !!res.value
        })
    }

    const types = [{
      label: 'Export Excel',
      key: 'xlsx'
    }]

    return {
      options,
      selectedStrategy,
      description,
      indicator,
      portfolios,
      stats,
      types,
      hasStrategies,
      message,
      strategyBelongsToUser,
      creationError,
      serie,
      availableDate,
      isNotificationOn,
      store,
      isMaxSelected,
      selectedDateRange,

      doSearchSpecific,
      exportType,
      updateSection,
      updateACL,
      getData,
      updateNotification,
      changeStrategy,
      getNameOwner,
      goToModifyStrategy,
      goToCreateStrategy
    }
  }
})
</script>

<style lang="scss" scoped>
.data {
  &.left {
    td,
    th {
      @apply text-left pr-4 pl-0;
    }
  }
  &__title {
    th {
      @apply py-2 pl-4 text-right;
    }
  }
  &__content {
    td {
      @apply py-2 text-right border-b;
    }
  }
}
</style>
