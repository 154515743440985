import PortfolioAsset from "@/domain/models/PortfolioAsset"
import Strategy from "../models/Strategy"
import StrategyItem from "../models/StrategyItem"
import EquityExposureFactory from "./EquityExposureFactory"
import PortfolioFactory from "./PortfolioFactory"

class StrategyFactory {
  get (data) {
    const strategy = {
      acl: data.acl,
      benchmark: data.benchmark,
      description: data.description,
      fqn: data.fqn,
      name: data.name,
      owner: data.owner,
      equityExposure: data.equity_exposure.map(equityExposure => EquityExposureFactory.get(equityExposure)),
      portfolios: data.portfolios.map(portfolio => PortfolioFactory.get(portfolio)),
      strategy: new StrategyItem(data.strategy)
    }

    return new Strategy(strategy)
  }
}

export default new StrategyFactory()
