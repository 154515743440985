import InitialContribution from "@/domain/models/InitialContribution"
import { v4 } from "uuid"

class InitialContributionFactory {
  get (data) {
    const initialContribution = {
      id: v4(),
      quantity: data.quantity,
      yearsOfInvestment: data.yearsOfInvestment
    }

    return new InitialContribution(initialContribution)
  }

  getEmpty () {
    return this.get({
        quantity: null,
        yearsOfInvestment: 1
      }
    )
  }
}

export default new InitialContributionFactory()
