<template>
  <div
    class="alert alert-primary alert-dismissible show flex items-center mb-2"
    role="alert"
  >
    <AlertCircleIcon class="w-6 h-6 mr-2" />
    {{ message }}
    <button
      type="button"
      class="btn-close text-white"
      aria-label="Close"
      @click="dismiss"
    >
      <XIcon class="w-4 h-4" />
    </button>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    message: String,
  },
  emits: ['dismiss'],

  setup(props, { emit }) {
    const dismiss = () => {
      emit('dismiss');
    };

    return {
      dismiss,
    };
  },
});
</script>

<style scoped></style>
