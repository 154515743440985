import Portfolio from "@/domain/models/Portfolio"

class PortfolioFormTransformer {
  toPortfolio (data, owner) {
    const portfolio = {
      name: data.name,
      description: data.description,
      owner: owner,
      assets: data.composition.map(elem => {
        return {
          ticker: this.parseGetTicker(elem.ticker),
          description: this.parseGetDescription(elem.ticker),
          weight: parseInt(elem.weight, 10)
        }
      })
    }

    return new Portfolio(portfolio)
  }

  parseGetTicker (ticker) {
    return ticker.split('@')[0]
  }

  parseGetDescription (ticker) {
    return ticker.split('@')[1]
  }

  parseTicker (data) {
    data.ticker = data.ticker + '@' + data.description
    return data
  }
}

export default new PortfolioFormTransformer()
