import WealthSimulatorFactory from '@/domain/services/WealthSimulatorFactory'
import axios from '@/plugins/axios'

const URL_WEALTH_SIMULATOR = '/api/simulation?owner={owner}&name={strategy}&initial={initial}&contribution={contribution}&frequency={frequency}&years={years}'

export function getSimulation(owner, initialContribution, strategy, periodicContribution, frequency, years) {
    const url = URL_WEALTH_SIMULATOR
        .replace("{owner}", owner)
        .replace("{strategy}", strategy)
        .replace("{initial}", initialContribution)
        .replace("{contribution}", periodicContribution)
        .replace("{frequency}", frequency)
        .replace("{years}", years)

    return axios.get(url)
        .then(res => {
            res.data.strategy = strategy
            return WealthSimulatorFactory.get(res.data)
        })
        .catch(error => Promise.reject(error))
}
