<template>
  <div>
    <div
      :id="indicator.getName + 'content'"
      class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed"
    >
      <div
        class="flex flex-col gap-5 items-start lg:flex-row lg:justify-between"
      >
        <DateToolbar
          :id="indicator.getName"
          selected="year"
          class="order-1"
          :minDate="availableDate"
          @search="doSearchSpecific"
          :skipButtons="['day']"
        />
        <div class="order-2 flex flex-row gap-x-2 br-5">
          <MultiExport
            :exportTypes="[{ label: 'Export Excel', key: 'xlsx' }]"
            @export="exportType"
          />
          <NotificationButton
            :isNotificationOn="isNotificationOn"
            @change="updateNotification"
          />
        </div>
      </div>
      <section v-if="children.length == 0" class="text-center my-5">
        <div class="w-8 h-8 inline-block">
          <LoadingIcon icon="puff" class="w-8 h-8" />
        </div>
        <div class="text-center text-xs">Loading...</div>
      </section>
      <section v-else v-for="ind in children" :key="ind.getName">
        <GraphicSection
          :indicator-name="indicator.getName"
          :index-name="indicator.getIndexName"
          :name="ind.name"
          :ind="ind.current.ind"
          :date="ind.current.date"
          :diff="ind.current.diff"
          :message="ind.current.message"
          :subindicators="ind.current.subIndicators"
          :graphicData="ind.graphic"
          :ref="graphicSection"
          @restore="restore"
          @zoom="zoom"
        />
      </section>
    </div>
  </div>
</template>

<script>
import dateRange from '@/domain/services/dateHelper';
import GraphicSection from '@/components/graphic-section/Main';
import DateToolbar from '@/components/date-toolbar/Main';
import MultiExport from '@/components/multi-export/Main';
import { ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import { onBeforeUpdate } from '@vue/runtime-core';
import { appendPrefix } from '@/domain/services/RiskIndicatorTransformer';
import NotificationButton from '@/components/notification-button/Main.vue';
import { updateNotificationPreference } from '@/domain/services/NotificationHelper';

export default {
  components: {
    GraphicSection,
    DateToolbar,
    MultiExport,
    NotificationButton,
  },
  props: {
    indicator: Object,
  },
  setup(props) {
    const store = useStore();
    const children = ref([]);
    const availableDate = ref();
    const lastSearchParams = {
      ticker: null,
      riskIndicator: null,
      from: null,
      to: null,
    };
    let graphicSections = [];
    const isNotificationOn = ref();
    const username = store.getters.getLoggedUser;

    const TYPE = 'risk-indicator';

    const graphicSection = (el) => {
      graphicSections.push(el);
    };

    onBeforeUpdate(() => {
      graphicSections = [];
    });

    const data = {
      username: username,
      key: `${TYPE}/${props.indicator.index_name}/${props.indicator.name}`,
    };

    store.dispatch('getNotificationPreference', data).then((res) => {
      isNotificationOn.value = !!res.value;
    });

    const restore = () => {
      graphicSections.map((graph) => {
        graph.restore();
      });
    };

    const zoom = (data) => {
      graphicSections.map((graph) => {
        graph.zoom(data);
      });
    };

    const doSearchSpecific = ({ id, range }) => {
      store.commit('setErrors', null)
      lastSearchParams.from = dateRange.format(range[0]);
      lastSearchParams.to = dateRange.format(range[1]);
      children.value = [];
      props.indicator.getRiskIndicators.forEach((subIndicator) => {
        const index = {
          ticker: props.indicator.getTicker,
          risk: subIndicator.getTicker,
          from: range[0],
          to: range[1],
        };
        lastSearchParams.ticker = props.indicator.getTicker;
        lastSearchParams.riskIndicator = subIndicator.getTicker;
        store
          .dispatch('getIndexRiskIndicators', index)
          .then((resIndicator) => {
            store
              .dispatch('getRiskIndicator', { ticker: subIndicator.getTicker })
              .then((riskIndicator) => {
                availableDate.value = riskIndicator.getAvailable;
              });
            const data = {
              ticker: subIndicator.getTicker,
              from: resIndicator.from,
              to: range[1],
            };
            store
              .dispatch('getRiskIndicatorsSubIndicators', data)
              .then((resCurrent) => {
                resIndicator.setCurrent(resCurrent);
                children.value.push(resIndicator);
                children.value.sort(function(first, second) {
                  if (first.name > second.name) {
                    return 1;
                  }
                  if (first.name < second.name) {
                    return -1;
                  }
                  return 0;
                });
              });
          })
          .catch((error) => {
            store.commit('setErrors', error.response);
          });
      });
    };

    const exportType = (key) => {
      const data = {
        tickers: appendPrefix(lastSearchParams.riskIndicator),
        from: lastSearchParams.from,
        to: lastSearchParams.to,
        format: key,
      };
      store.dispatch('dataExportDoc', data).then((contents) => {
        const url = window.URL.createObjectURL(contents.data);
        const a = document.createElement('a');
        a.href = url;
        a.download =
          data.tickers + '-' + '-' + data.from + '--' + data.to + '.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
      });
    };

    const updateNotification = () => {
      const selectedData = {
        username: username,
        owner: props.indicator.index_name,
        name: props.indicator.name,
      };

      isNotificationOn.value = updateNotificationPreference(
        TYPE,
        store,
        selectedData,
        isNotificationOn.value
      );
    };

    return {
      children,
      availableDate,
      isNotificationOn,

      graphicSection,
      doSearchSpecific,
      exportType,
      restore,
      zoom,
      updateNotification,
    };
  },
};
</script>
